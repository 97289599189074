.full-width-cta-banner {
  background: linear-gradient(to top,
  var(--white) 0%,
  var(--white) 50%,
  var(--back-blue) 50%,
  var(--back-blue) 100%);
  padding-block: var(--module-spacing);

  @include mq($until: desktop) {
    padding-block-end: rem(80px);
  }

  &[data-background="gray"] {
    margin-block: 0;
    padding-block: var(--module-spacing);

    @include mq($until: desktop) {
      padding-block-end: rem(80px);
    }
  }
}

.full-width-cta-banner__inner {
  --primary-text-color: var(--white);
  align-items: center;
  background-color: var(--arapawa);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: rem(40px);
  padding-inline: rem(30px);
  position: relative;
  text-align: center;

  @include mq(desktop) {
    border-end-start-radius: 3.5rem;
    border-start-end-radius: 3.5rem;
    min-block-size: rem(510px);
    padding: rem(120px);
  }

  @include mq(wide) {
    padding-inline: rem(200px);
  }

  &::after,
  &::before {
    block-size: 5.0625rem;
    content: "";
    inline-size: 5.0625rem;
    position: absolute;
  }

  &::before {
    background-color: var(--crusta);
    border-start-start-radius: 3.5rem;
    inset-block-end: 5.0625rem;
    inset-inline-end: 0;
  }

  &::after {
    background-color: var(--azure);
    inset-block-end: 0;
    inset-inline-end: 0;
  }
}

.full-width-cta-banner__copy {
  max-inline-size: rem(778px);

  a {
    color: var(--crusta);
  }
}

.full-width-cta-banner__buttons {
  inline-size: 100%;
  margin: rem(-10px);
  margin-block-start: rem(10px);

  @include mq(desktop) {
    display: flex;
    justify-content: center;
    margin-block-start: rem(50px);
  }

  .btn {
    margin: rem(10px);
  }
}

.full-width-cta-banner__shapes-container {
  .shape-1 {
    background-color: var(--royal-blue);
    block-size: rem(40px);
    inline-size: rem(40px);
    inset-block-end: rem(-40px);
    inset-inline-end: 0;

    @include mq(desktop) {
      block-size: rem(81px);
      inline-size: rem(81px);
      inset-block-end: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(81px);
      inline-size: rem(81px);
      inset-inline-end: 0;
    }
  }

  .shape-2 {
    background-color: var(--crusta);
    block-size: rem(20px);
    inline-size: rem(20px);
    inset-block-start: 0;
    inset-inline-end: 0;

    @include mq(desktop) {
      block-size: rem(81px);
      border-start-start-radius: 3.5rem;
      inline-size: rem(81px);
      inset-block-start: 21.9rem;
    }

    @include mq(wide) {
      block-size: rem(81px);
      border-start-start-radius: 3.5rem;
      inline-size: rem(81px);
      inset-block-start: 21.85rem;
    }
  }
}
