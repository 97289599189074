.driver-brand-card {
  border: solid rem(1px) var(--alto);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  position: relative;
  text-align: start;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;

  @include active-state {
    .driver-brand-card__content {
      background-color: var(--arapawa);
    }

    .driver-brand-card__heading {
      color: var(--white);
    }
  }

  &.active {
    .driver-brand-card__content {
      background-color: var(--arapawa);
    }

    .driver-brand-card__heading {
      color: var(--white);
    }

    &::before,
    &::after {
      border: 11px solid transparent;
      border-block-end-color: var(--arapawa);
      content: "";
      inset-block-start: calc(100% + #{rem(25px)});
      inset-inline-start: 50%;
      position: absolute;
      transform: translateX(-50%);
    }

    &::after {
      border: rem(11px) solid transparent;
      border-block-end-color: var(--arapawa);
      inset-inline-start: 50%;
    }
  }

  picture,
  img {
    inline-size: 100%;
  }
}

.driver-brand-card__image {
  img {
    aspect-ratio: 1/1;
    block-size: auto;
    inline-size: 100%;
  }
}

.driver-brand-card__content {
  background-color: var(--white);
  border-block-start: solid rem(1px) var(--alto);
  flex-grow: 1;
  inline-size: 100%;
  padding-block: rem(10px);
  transition: background-color 0.2s ease-in-out;
}

.driver-brand-card__heading {
  font-size: rem(18px);
  line-height: 1.33;
  margin: 0;
  text-align: center;
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(18px);
    line-height: 1.56;
  }
}
