.driver-search {
  align-items: center;
  background-color: var(--gallery);
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  padding-block: rem(30px);
  padding-inline: rem(30px);

  @include mq(desktop) {
    border: 0;
    flex-direction: row;
    gap: rem(30px);
    padding-block: rem(55px);
    padding-inline: rem(60px);
  }
}

.driver-search-dropdowns {
  inline-size: 100%;
  margin-block-start: rem(20px);

  @include mq(desktop) {
    flex-basis: 50%;
    margin-block-start: 0;
  }
}

.driver-search-dropdowns__form {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  inline-size: 100%;
  position: relative;

  @include mq(desktop) {
    flex-direction: row;
  }

  .driver-search__dropdown {
    flex-grow: 1;
  }

  .driver-search__dropdown select {
    block-size: rem(50px);
    flex-grow: 1;
  }

  .driver-search__dropdown select:disabled {
    background-image: url("data:image/svg+xml,%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23d3d3d3' d='M8.315 4.45L4.484 0 3.188 1.099 6.546 5 3.188 8.901 4.484 10 8.315 5.55 8.787 5z' transform='translate(-1307 -50) translate(711 42) translate(491) translate(104 8) rotate(90 5.987 5)'/%3E%3C/svg%3E%0A");
    block-size: rem(50px);
    color: var(--alto);
  }
}

.driver-search-bar__form {
  display: block;
  inline-size: 100%;
  position: relative;
}

.driver-search-bar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  inline-size: 100%;
  position: relative;

  @include mq(desktop) {
    flex-basis: 50%;
  }

  label {
    block-size: 1px;
    inline-size: 1px;
    inset-block-start: auto;
    inset-inline-start: -10000px;
    overflow: hidden;
    position: absolute;
  }

  .driver-search-bar__input--clear-enabled {
    padding-inline-end: rem(100px);
    text-overflow: ellipsis;
  }

  input {
    appearance: none;
    background-color: var(--white);
    block-size: rem(50px);
    border: solid rem(1px) var(--alto);
    color: var(--secondary-text-color);
    font-family: var(--primary-font);
    font-size: rem(16px);
    inline-size: 100%;
    line-height: 1.38;
    padding-block: rem(10px);
    padding-inline-end: rem(40px);
    padding-inline-start: rem(20px);
    text-overflow: ellipsis;

    @include mq(desktop) {
      font-size: rem(18px);
      line-height: 1.56;
      padding-block: rem(14px);
      padding-inline-end: rem(35px);
    }
  }
}

.driver-search__center-label {
  color: var(--tundora);
  display: none;

  @include mq(desktop) {
    display: inline;
  }
}

.driver-search-bar__actions {
  align-items: center;
  display: flex;
  gap: rem(10px);
  inset-block-start: 50%;
  inset-inline-end: rem(20px);
  padding: 0;
  position: absolute;
  transform: translateY(-50%);

  @include mq(desktop) {
    gap: rem(20px);
  }
}

.driver-search-bar__clear {
  appearance: none;
  background-color: transparent;
  color: var(--arapawa);
  cursor: pointer;
  font-size: rem(16px);
}

.driver-search-bar__clear--disabled {
  color: var(--alto);
  cursor: not-allowed;
  display: none;
  pointer-events: none;
}

.driver-search-bar__submit {
  appearance: none;
  background-color: transparent;
  block-size: rem(15px);
  border: 0;
  cursor: pointer;
  inline-size: rem(14px);
  padding: 0;

  &:disabled {
    svg {
      fill: var(--alto);
    }
  }

  svg {
    block-size: 100%;
    display: block;
    fill: var(--arapawa);
    inline-size: 100%;
  }
}

.driver-search-bar__submit--disabled {
  color: var(--alto);
  cursor: not-allowed;
}
