.anchor-link-block {
  margin-block-end: rem(20px);

  @include mq(desktop) {
    margin-block-end: rem(60px);
  }
}

.anchor-link-block__inner {
  border: solid rem(1px) var(--alto);
  padding: rem(40px 40px 34px 30px);
  position: relative;

  @include mq(desktop) {
    padding: rem(60px 139px 90px 80px);
  }
}

.anchor-link-block__heading {
  font-size: rem(18px);
  line-height: 1.33;
  margin-block-end: rem(20px);

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.anchor-link-block__links {
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.38;

  @include mq(desktop) {
    font-size: rem(18px);
    line-height: 1.56;
    padding-inline-end: rem(139px);
  }

  li {
    & + li {
      margin-block-start: rem(20px);
    }
  }

  a {
    color: var(--azure);
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }
  }
}
