.filtered-resource-cards {
  overflow: hidden;
  padding-block: var(--module-spacing);

  &[data-background="gray"] {
    .resource-card {
      &:not(.resource-card--view-all) {
        .resource-card__inner {
          background-color: var(--white);
        }
      }
    }
  }

  .grid {
    margin-block-start: rem(30px);
    position: relative;

    @include mq($until: tabletLandscape) {
      row-gap: rem(20px);
    }

    @include mq(wide) {
      margin-block-end: rem(-40px);
    }

    &::after {
      background-color: var(--white);
      block-size: 100%;
      content: "";
      inline-size: 100%;
      inset-block-start: 0;
      inset-inline-start: 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
      visibility: hidden;
      z-index: z("default");

      [data-background="gray"] & {
        background-color: var(--gallery);
      }
    }
  }

  &.filtered-resource-cards--loading {
    .grid {
      &::after {
        opacity: 0.7;
        visibility: visible;
      }
    }
  }

  &.filtered-resource-cards--initialized {
    .resource-card--view-all {
      display: block;
    }
  }

  .resource-card {
    @include mq($until: tablet) {
      &:not(.resource-card--view-all) {
        &:nth-child(n + 5) {
          display: none;
        }
      }
    }
  }

  .resource-card--view-all {
    display: none;
  }
}

.filtered-resource-cards__heading {
  margin-block-end: rem(20px);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(40px);
  }
}

.filtered-resource-cards__tags {
  @extend %scrollbar-hidden;
  display: flex;
  flex-wrap: wrap;
  margin: rem(-5px -25px);
  overflow: auto;
  padding-block-end: rem(10px);
  position: relative;

  @include mq(tabletLandscape) {
    justify-content: center;
  }

  @include mq(desktop) {
    margin: rem(-10px);
  }

  div {
    display: flex;

    @include mq($until: tabletLandscape) {
      padding-inline: var(--container-padding);
    }
  }
}

.filtered-resource-cards__tag {
  span {
    border: solid rem(1px) var(--alto);
    color: color(--secondary-text-color);
    cursor: pointer;
    display: block;
    font-size: rem(16px);
    line-height: 1.38;
    margin: rem(5px);
    padding: rem(7px 20px 6px);
    text-align: center;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      color 0.2s ease-in-out;
    user-select: none;
    white-space: nowrap;

    @include active-state {
      border-color: var(--azure);
      color: var(--azure);
    }

    @include mq(desktop) {
      margin: rem(10px);
    }
  }

  input {
    block-size: 0;
    cursor: pointer;
    inline-size: 0;
    opacity: 0;
    position: absolute;

    &.focus-visible {
      &~span {
        outline: solid 1px var(--azure);
        outline-offset: rem(4px);
      }
    }

    &:checked {
      &~span {
        background-color: var(--azure);
        border-color: var(--azure);
        color: var(--white);
      }
    }
  }
}
