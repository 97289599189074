.product-checkout {
  margin-block: var(--module-spacing);

  @include mq(desktop) {
    display: flex;
    justify-content: space-between;
  }
}

.product-checkout__form {
  @include mq(desktop) {
    flex-shrink: 0;
    inline-size: 55%;
  }

  @include mq(wide) {
    inline-size: 50%;
  }
}

.product-checkout__heading {
  @include mq(tabletLandscape) {
    margin-block-end: rem(40px);
  }
}

.product-checkout__base-price {
  color: var(--primary-text-color);
  font-size: rem(18px);
  font-weight: 500;
  line-height: 1.33;
  margin-block-end: rem(20px);

  @include mq(tablet) {
    display: none;
  }
}

.product-checkout__section {
  & + .product-checkout__section {
    margin-block-start: rem(40px);

    @include mq(desktop) {
      margin-block-start: rem(60px);
    }
  }
}

.product-checkout__subheading {
  font-size: rem(18px);
  line-height: 1.33;
  margin-block-end: rem(20px);

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.product-checkout__modal-cta {
  color: var(--azure);
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.38;
  margin-block-end: rem(20px);
  padding: 0;

  @include active-state {
    text-decoration: underline;
  }
}

.product-checkout__description {
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }

  * {
    display: inline;
  }
}

.product-checkout__options {
  @include mq(tablet) {
    display: flex;
    flex-wrap: wrap;
    gap: rem(24px);
  }
}

.product-checkout__option {
  display: block;

  @include mq(tablet) {
    inline-size: calc(33.33% - #{rem(16px)});
  }

  @include mq(desktop, wide) {
    inline-size: calc(50% - #{rem(12px)});
  }

  & + .product-checkout__option {
    @include mq($until: tablet) {
      margin-block-start: rem(10px);
    }
  }

  input {
    block-size: 0;
    cursor: pointer;
    inline-size: 0;
    opacity: 0;
    position: absolute;

    &:checked {
      ~ .product-checkout__option-box {
        background-color: var(--arapawa);
        border-color: var(--arapawa);

        /* stylelint-disable-next-line selector-max-compound-selectors */
        .product-checkout__option-label {
          color: var(--white);
        }

        /* stylelint-disable-next-line selector-max-compound-selectors */
        .product-checkout__option-price {
          color: var(--white);
        }
      }
    }
  }
}

.product-checkout__option-box {
  align-items: center;
  block-size: rem(60px);
  border: solid rem(1px) var(--alto);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: rem(19px 20px);
  user-select: none;

  @include active-state {
    border-color: var(--arapawa);

    .product-checkout__option-label {
      color: var(--azure);
    }
  }

  &.product-checkout__option-box--centered {
    justify-content: center;
  }
}

.product-checkout__option-label {
  font-size: rem(16px);
  line-height: 1.38;
}

.product-checkout__option-price {
  color: var(--primary-text-color);
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.38;
}

.product-checkout__partner {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+cGVuY2lsPC90aXRsZT4KICAgIDxnIGlkPSJBY3F1aXNpdGlvbi1GbG93IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iTE9GVC1BY3F1aXNpdGlvbi1GbG93LURlc2t0b3AiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02NTIuMDAwMDAwLCAtNzkzLjAwMDAwMCkiIGZpbGw9IiMwMDcyRUIiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYwLjAwMDAwMCwgNzkyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDkyLjAwMDAwMCwgMS4wMDAwMDApIiBpZD0iRGVzaWduL2ljb25zL1VJL2ZpbHRlci1Db3B5Ij4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTAuOTUzMzQxMSwwIEwwLDEwLjk1NDA1NDggTDAuMDQ5MjQ2MTQxNSwxNS45NTE0Njc2IEw1LjA0NjY1ODkzLDE2IEwxNiw1LjA0NjY1ODkzIEwxMC45NTMzNDExLDAgWiBNMTAuNDc1MTUzOSwyLjQ5NjU2NTI2IEwxMC45NTMzNDExLDIuMDE5MDkxOCBMMTMuOTgxNjIxOSw1LjA0NjY1ODkzIEwxMy41MDM0MzQ3LDUuNTI1NTU5ODIgTDEwLjQ3NTE1MzksMi40OTY1NjUyNiBaIE0xLjQ2MjM5NjI5LDE0LjUzODMxNzQgTDEuNDMzMTM0MDksMTEuNTM4NTg1MSBMOS40NjU5NjQ4NSwzLjUwNTc1NDMgTDEwLjQ3NTg2NzYsNC41MTU2NTcwNiBMMi43MzQ5NDUxMywxMi4yNTUxNTIxIEwzLjc0NDEzNDE4LDEzLjI2NTA1NDkgTDExLjQ4NTA1NjcsNS41MjU1NTk4MiBMMTIuNDk0MjQ1Nyw2LjUzNDAzNTE1IEw0LjQ2MDcwMTIyLDE0LjU2Njg2NTkgTDEuNDYyMzk2MjksMTQuNTM4MzE3NCBaIiBpZD0iY29sb3IiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  background-position: top right;
  background-repeat: no-repeat;
  color: var(--azure);
  display: inline-block;
  font-size: rem(16px);
  font-weight: 500;
  padding: rem(0 25px 0 5px);
  position: relative;

  @include active-state {
    text-decoration: underline;
  }

  select {
    block-size: 100% !important;
    cursor: pointer;
    inline-size: 100% !important;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    z-index: 1000;
  }
}

.product-checkout__total-section {
  border-block: solid rem(1px) var(--alto);
  color: var(--primary-text-color);
  font-size: rem(32px);
  font-weight: 500;
  line-height: 1.13;
  margin-block-end: rem(20px);
  margin-block-start: rem(40px);
  padding-block: rem(20px);

  @include mq(tablet) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  @include mq(desktop) {
    font-size: rem(42px);
    line-height: 1.1;
  }

  @include mq(desktop) {
    margin-block-end: rem(28px);
    padding-block: rem(30px);
  }

  @include mq(wide) {
    font-size: rem(45px);
    line-height: 1.09;
  }
}

.product-checkout__submit {
  text-align: end;
}

.product-checkout__sidebar {
  inline-size: 38%;

  @include mq($until: desktop) {
    display: none;
  }

  .icon-card {
    max-inline-size: rem(230px);
  }

  .icon-card__image {
    block-size: rem(60px);
    inline-size: rem(60px);
  }
}

.product-checkout__image {
  display: flex;
  margin-block-end: rem(40px);
  position: relative;
}

.product-checkout__image-shapes-container {
  .shape-1 {
    background-color: var(--azure);
    block-size: rem(80px);
    inline-size: rem(80px);
    inset-block-start: 0;
    inset-inline-start: rem(-80px);

    @include mq(wide) {
      block-size: rem(120px);
      inline-size: rem(120px);
      inset-inline-start: rem(-120px);
    }
  }

  .shape-2 {
    background-color: var(--cyan);
    block-size: rem(40px);
    inline-size: rem(40px);
    inset-block-start: rem(80px);
    inset-inline-start: 0;

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: rem(120px);
    }
  }

  .shape-3 {
    background-color: var(--crusta);
    block-size: rem(60px);
    inline-size: rem(60px);
    inset-block-end: 0;
    inset-inline-end: 0;

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
    }
  }
}
