.image-card-grid {
  margin-block: var(--module-spacing);

  &[data-background="gray"] {
    margin-block: 0;
    padding-block: var(--module-spacing);
  }
}

.image-card-grid__heading {
  margin: rem(0 auto 20px);
  max-inline-size: rem(778px);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(40px);
  }
}

.image-card-grid__copy {
  line-height: 1.38;

  @include mq(desktop) {
    line-height: 1.56;
  }
}
