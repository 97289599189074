.header {
  background-color: var(--white);
  inline-size: 100%;
  position: relative;
  z-index: z("header");

  @include mq(desktop) {
    block-size: auto;
  }

  &.hamburguer-menu--is-open {
    block-size: 100%;
    inset: 0;
    overflow: hidden;
    position: fixed;
  }
}

.header__inner {
  align-items: center;
  background-color: var(--white);
  block-size: 100%;
  border-block-end: rem(1px) solid var(--alto);
  display: flex;
  justify-content: space-between;
  margin-inline: calc(var(--container-padding) * -1);
  min-block-size: rem(60px);
  padding-inline: var(--container-padding);
  position: relative;

  @include mq(desktop) {
    min-block-size: rem(80px);
  }
}

.header__logo {
  display: flex;
  flex-shrink: 0;
  inline-size: rem(260px);
  margin-inline-start: 0;
  object-fit: contain;

  @include mq(desktop) {
    inline-size: rem(260px);
    margin-inline-end: clamp(30px, 4vw, 100px);
  }

  @include mq(wide) {
    inline-size: rem(260px);
    margin-inline-end: clamp(80px, 5.5vw, 150px);
  }

  img {
    inline-size: 100%;
  }
}

.header__cta {
  align-items: center;
  align-self: stretch;
  display: none;

  @include mq(desktop) {
    border-end-start-radius: 1.5rem;
    display: flex;
    margin-inline-start: rem(40px);
    min-inline-size: rem(110px);
  }

  @include mq(wide) {
    margin-inline-start: rem(60px);
    min-inline-size: rem(120px);
  }

  &::after {
    border-end-start-radius: 1.5rem;
  }
}

.header__hamburger-menu-toggle {
  padding: 0;

  @include mq(desktop) {
    display: none;
  }
}

.header__toggles {
  display: flex;
  gap: rem(21px);
}

.header__hamburger-menu-toggle {
  align-items: center;
  appearance: none;
  background-color: transparent;
  display: flex;

  @include mq(desktop) {
    display: none;
  }

  svg {
    color: var(--arapawa);
    fill: var(--arapawa);
  }
}

.header__hamurguer-icon--close {
  block-size: rem(20px);
  display: none;
  inline-size: rem(20px);

  .hamburguer-menu--is-open & {
    display: block;
  }
}

.header__hamurguer-icon--open {
  block-size: rem(15px);
  display: block;
  inline-size: rem(20px);

  .hamburguer-menu--is-open & {
    display: none;
  }
}
