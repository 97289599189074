:root {
  --primary-font: "Roboto", sans-serif;
  --container-max-width: #{rem(1920px)};
  --container-padding: #{rem(20px)};
  --module-spacing: #{rem(40px)};
  --card-spacing: #{rem(20px)};

  @include mq(tabletLandscape) {
    --card-spacing: #{rem(34px)};
  }

  @include mq(desktop) {
    --container-padding: #{rem(80px)};
    --module-spacing: #{rem(80px)};
  }

  @include mq(wide) {
    --container-padding: #{rem(160px)};
    --module-spacing: #{rem(100px)};
    --card-spacing: #{rem(68px)};
  }
}
