.rtf__figure {
  margin-block: rem(20px);

  @include mq(desktop) {
    margin-block: rem(40px);
  }

  img {
    inline-size: 100%;
    margin-block-end: 0;
    max-inline-size: 100%;
    vertical-align: middle;
  }

  figcaption {
    font-size: rem(14px);
    line-height: 1.57;
    margin-block-start: rem(10px);
  }
}
