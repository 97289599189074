.accordion {
  margin-block: var(--module-spacing);

  &[data-background="gray"] {
    margin-block: 0;
    padding-block: var(--module-spacing);
  }
}

.accordion__heading {
  margin-block-end: rem(40px);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(60px);
  }
}

.accordion__item {
  background-color: var(--white);
  border: solid rem(1px) var(--alto);

  & + .accordion__item {
    margin-block-start: rem(20px);
  }
}

.accordion__item-trigger {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  inline-size: 100%;
  justify-content: space-between;
  padding: rem(29px 30px 27px);
  position: relative;
  text-align: start;

  @include mq(tabletLandscape) {
    align-items: center;
  }

  @include mq(desktop) {
    padding: rem(38px 42px 34px 43px);
  }

  @include active-state {
    .accordion__item-title {
      color: var(--azure);
    }

    .accordion__item-icon {
      &::after,
      &::before {
        background-color: var(--azure);
      }
    }
  }

  &[aria-expanded="true"] {
    .accordion__item-icon {
      &::after {
        transform: rotate(90deg);
      }
    }
  }
}

.accordion__item-icon {
  block-size: rem(14px);
  flex-shrink: 0;
  inline-size: rem(14px);
  margin-inline-start: rem(15px);
  position: relative;

  @include mq($until: tabletLandscape) {
    transform: translateY(3px);
  }

  @include mq(tabletLandscape) {
    block-size: rem(16px);
    inline-size: rem(16px);
  }

  &::after {
    background-color: var(--arapawa);
    block-size: 100%;
    content: "";
    inline-size: rem(2px);
    inset-block-start: 0;
    inset-inline-start: calc(50% - #{rem(1px)});
    position: absolute;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &::before {
    background-color: var(--arapawa);
    block-size: rem(2px);
    content: "";
    inline-size: 100%;
    inset-block-start: calc(50% - #{rem(1px)});
    inset-inline-start: 0;
    position: absolute;
    transition: background-color 0.2s ease-in-out;
  }
}

.accordion__item-title {
  font-size: rem(18px);
  font-weight: 500;
  line-height: 1.33;
  margin: 0;
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.accordion__item-panel {
  block-size: 0;
  overflow: hidden;
  visibility: hidden;
}

.accordion__item-content {
  padding: rem(0 30px 27px);

  @include mq(desktop) {
    line-height: 1.56;
    padding: rem(0 42px 34px 43px);
  }

  /* stylelint-disable-next-line plugin/stylelint-bem-namics */
  .EPiServerForms {
    background-color: transparent;
    padding: 0;
  }
}
