.simple-cards-grid {
  &[data-background="white"] {
    margin-block: var(--module-spacing);
  }

  &[data-background="gray"] {
    padding-block: var(--module-spacing);
  }

  .grid {
    margin-block-start: rem(20px);

    @include mq($until: tabletLandscape) {
      row-gap: rem(20px);
    }

    @include mq(desktop) {
      margin-block-start: rem(40px);
    }
  }
}

.simple-cards-grid__heading {
  margin: rem(0 auto 20px);
  max-inline-size: rem(778px);
  text-align: center;
}

.simple-cards-grid__copy {
  line-height: 1.38;
  margin: rem(0 auto 20px);
  max-inline-size: rem(778px);
  text-align: center;

  @include mq(desktop) {
    line-height: 1.56;
    margin-block-end: rem(30px);
  }
}
