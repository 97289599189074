.alert-banner {
  block-size: 0;
  display: none;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: z("alert");

  a {
    text-decoration: underline !important;
  }

  &.alert--info {
    background-color: var(--back-blue);
    color: var(--dark-gray);

    .focus-visible {
      outline: solid 0.0625rem var(--white);
    }

    .rtf {
      a {
        color: var(--azure);
        text-decoration: underline;
      }
    }

    .alert-banner__close {
      svg {
        fill: var(--dark-gray);
        stroke: var(--dark-gray);
      }
    }
  }

  &.alert--info-green {
    background-color: var(--neutral-green);
    color: var(--dark-gray);

    .focus-visible {
      outline: solid 0.0625rem var(--white);
    }

    .rtf {
      a {
        color: var(--crusta);
        text-decoration: underline;
      }
    }

    .alert-banner__close {
      svg {
        fill: var(--dark-gray);
        stroke: var(--dark-gray);
      }
    }
  }

  &.alert--error {
    background-color: var(--error-background-color);
    color: var(--error-color);

    .rtf {
      a {
        color: var(--error);
        text-decoration: underline;
      }
    }

    .alert-banner__heading {
      color: var(--error-color);
    }

    .alert-banner__close {
      svg {
        fill: var(--tundora);
        stroke: var(--tundora);
      }
    }
  }
}

.alert-banner__inner {
  padding-block: rem(20px 22px);
  position: relative;
}

.alert-banner__heading {
  font-size: rem(14px);
  line-height: 1.43;
  margin-block-end: rem(10px);
  padding-inline-end: rem(60px);

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
    padding-inline-end: rem(137px);
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.alert-banner__content {
  font-size: rem(14px);
  line-height: 1.43;
  padding-inline-end: rem(60px);

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
    padding-inline-end: rem(120px);
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
    padding-inline-end: rem(200px);
  }
}

.alert-banner__close {
  display: flex;
  inset-block-start: rem(20px);
  inset-inline-end: var(--container-padding);
  padding: 0;
  position: absolute;

  svg {
    block-size: rem(12px);
    fill: var(--arapawa);
    inline-size: rem(12px);
    stroke: var(--arapawa);
  }
}
