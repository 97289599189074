/* stylelint-disable plugin/stylelint-bem-namics */

.login-modal {
  overflow: auto;
  position: fixed;

  .modal__inner {
    display: flex;
    flex-direction: column;
    max-inline-size: fit-content;
    padding: 38px 30px 30px;
  }

  .modal__logo {
    align-self: center;
    margin-block-end: 18px;
  }

  h6 {
    color: var(--arapawa);
    font-size: 16px;
    margin-block-end: 4px;
    text-align: center;
  }

  .modal__subtile {
    color: var(--arapawa);
    font-size: 16px;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
  }

  .FormSelection {
    select {
      border-radius: 6px;
      line-height: 1;
    }
  }

  .Form__Element,
  button {
    margin-block-start: 11px !important;
  }

  button {
    inline-size: 100%;
  }

  .copy {
    color: #bec3c9;
    font-size: 16px;
    margin-block-start: 15px;

    span {
      color: var(--azure);
    }
  }

  .text-help {
    color: var(--error-color);
    font-size: 15px;
  }
}

.form__checkbox-wrapper {max-inline-size: 500px;}

.form__radio-button-wrapper {
  align-items: center;
  block-size: 40px;
  border: solid 1px var(--alto);
  border-radius: 6px;
  color: var(--secondary-text-color);
  display: flex;
  justify-content: space-between;
  padding-inline: 15px;
}

.radio-button__wrapper {
  display: flex;
  gap: 20px;
}
