.idio-profile-data__wrapper {
  display: none;
  inset: auto 0 0;
  position: fixed;
}

.idio-profile-data__canvas-container {
  background-color: var(--back-blue);
  display: none;
  inline-size: 100%;
  inset-block-end: 0;
  padding: 20px;
  position: absolute;

  &::before {
    background-color: var(--crusta);
    block-size: 12px;
    content: "";
    display: block;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
  }
}

.idio-profile-data__canvas-container--open {
  display: flex;
}

.idio-profile-data__close-btn {
  inset-block-start: -60px;
  inset-inline-end: 20px;
  position: absolute;
}

.keywords__container {
  align-items: center;
  display: flex;
  inline-size: 100%;
  justify-content: center;

  span {
    color: var(--azure);
  }
}

.idio-profile-data__btn {
  &.modal-profiler__button-wrapper {
    .modal-profiler__button-icon {
      block-size: 25px;
      inline-size: 42px;

      svg {
        block-size: 25px;
        inline-size: 42px;
      }
    }
  }
}

/* jqCloud plugin*/

.jqcloud {
  font: 10px Helvetica, Arial, sans-serif;
  inline-size: 100%;
  line-height: normal;
  overflow: hidden;
  position: relative;
}

.jqcloud-word {
  margin: 0;
  padding: 0;
}

.jqcloud-word.w1 {
  font-size: 100%;
}

.jqcloud-word.w2 {
  font-size: 150%;
}

.jqcloud-word.w3 {
  font-size: 200%;
}

.jqcloud-word.w4 {
  font-size: 250%;
}

.jqcloud-word.w5 {
  font-size: 300%;
}

.jqcloud-word.w6 {
  font-size: 350%;
}

.jqcloud-word.w7 {
  font-size: 400%;
}

.jqcloud-word.w8 {
  font-size: 450%;
}

.jqcloud-word.w9 {
  font-size: 500%;
}

.jqcloud-word.w10 {
  font-size: 550%;
}
