.portal-login {
  padding-block-end: rem(40px);
  padding-block-start: rem(30px);
  position: relative;

  @include mq(desktop) {
    padding-block-end: rem(200px);
    padding-block-start: rem(200px);
  }
}

.portal-login__heading {
  margin: rem(0 auto 20px);
  max-inline-size: rem(508px);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(30px);
  }
}

.portal-login__form {
  margin: 0 auto;
  max-inline-size: rem(508px);
}

.portal-login__field {
  label {
    @extend %sr-only;
  }

  input {
    background-color: var(--white);
    block-size: rem(50px);
    border: solid rem(1px) var(--alto);
    color: var(--secondary-text-color);
    font-size: rem(16px);
    inline-size: 100%;
    line-height: 1.5;
    outline-offset: rem(4px);
    padding: rem(9px 50px 7px 15px);

    @include placeholder {
      color: var(--secondary-text-color);
      font-family: var(--primary-font);
    }
  }

  & + .portal-login__field,
  & + .portal-login__checkbox-field {
    margin-block-start: rem(30px);
  }
}

.portal-login__checkbox-field {
  align-items: center;
  display: flex;
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }

  & + .portal-login__submit {
    margin-block-start: rem(30px);
  }

  input {
    block-size: 0;
    cursor: pointer;
    inline-size: 0;
    opacity: 0;
    position: absolute;

    &:checked {
      & + .portal-login__checkbox-check {
        border: solid rem(1px) var(--arapawa);

        &::after {
          display: block;
        }
      }
    }
  }
}

.portal-login__password {
  position: relative;
}

.portal-login__password-show {
  display: none;
}

.portal-login__password-show-btn,
.portal-login__password-hide-btn {
  align-items: center;
  display: flex;
  inset-block-start: 50%;
  inset-inline-end: rem(20px);
  position: absolute;
  transform: translateY(-50%);

  @include active-state {
    svg {
      fill: var(--azure);
    }
  }

  svg {
    block-size: rem(20px);
    display: block;
    fill: var(--arapawa);
    inline-size: rem(20px);
    transition: fill 0.2s ease-in-out;
  }
}

.portal-login__password-hide-btn {
  display: none;
}

.portal-login__checkbox-check {
  background-color: var(--white);
  block-size: rem(20px);
  border: solid 1px var(--alto);
  cursor: pointer;
  display: flex;
  inline-size: rem(20px);
  margin-inline-end: rem(10px);
  position: relative;

  &::after {
    background-blend-mode: overlay, normal;
    block-size: rem(14px);
    border: solid var(--arapawa);
    border-radius: rem(1px);
    border-width: rem(0 2px 2px 0);
    content: "";
    display: none;
    inline-size: rem(6px);
    inset-block-start: rem(1px);
    inset-inline-start: rem(6px);
    position: relative;
    transform: rotate(45deg);
  }
}

.portal-login__submit {
  .btn {
    inline-size: 100%;
  }
}

.portal-login__shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: rem(60px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(80px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: 0;
      inset-inline-start: rem(60px);
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-inline-start: rem(80px);
    }
  }

  .shape-3 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }

  .shape-4 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }

  .shape-5 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(40px);
      inset-inline-end: rem(40px);
    }

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
      inset-block-end: rem(60px);
      inset-inline-end: rem(60px);
    }
  }
}

.portal-login__error {
  background-color: var(--white);
  border-color: var(--error-color);
  border-style: solid;
  border-width: rem(1px) rem(1px) rem(1px) rem(5px);
  color: var(--error-color);
  font-size: rem(14px);
  font-weight: normal;
  line-height: 1.5;
  margin-block-start: rem(20px);
  padding-block: rem(13px);
  padding-inline: rem(25px);

  @include mq(desktop) {
    font-size: rem(16px);
    line-height: 1.57;
  }
}
