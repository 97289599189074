.resource-image-card {
  border-end-start-radius: 3.5rem;
  border-start-end-radius: 3.5rem;
  color: var(--secondary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  text-align: start;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;

  @include active-state {
    box-shadow: rem(0 0 20px 1px) var(--alto);

    .resource-image-card__heading {
      color: var(--azure);
    }
  }

  &[data-background="white"] {
    background-color: var(--white);
    border: solid rem(1px) var(--alto);
  }

  picture,
  img {
    aspect-ratio: 4/3;
    border-start-end-radius: 3.5rem;
    inline-size: 100%;
  }

  .tags {
    margin-block-start: rem(12px);

    @include mq(tablet) {
      margin-block-start: rem(20px);
    }
  }
}

.grid--flexible-cols {
  .resource-image-card {
    &.city-card {
      &::after {
        content: none;
      }
    }

    &.city-card--active {
      border: solid 2px var(--azure);

      &::after {
        background-color: var(--azure) !important;
        block-size: 5.0625rem;
        border-end-end-radius: 3.5rem;
        content: "";
        inline-size: 5.0625rem;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
      }
    }
  }
}

.featured-cards-row__image {
  img {
    aspect-ratio: 4/3;
    block-size: auto;
    inline-size: 100%;
  }
}

.resource-image-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: rem(20px 50px 34px 30px);

  @include mq(desktop) {
    padding: rem(40px 40px 38px);
  }

  .tags {
    color: var(--azure);
    font-weight: bold;

    li {
      &::after {
        background-color: var(--azure);
      }
    }
  }
}

.resource-image-card__label {
  font-size: rem(14px);
  line-height: 1.57;
  margin-block-end: rem(6px);

  @include mq(tablet) {
    margin-block-end: rem(9px);
  }
}

.resource-image-card__heading {
  font-size: rem(18px);
  line-height: 1.33;
  margin-block-end: rem(5px);
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.resource-image-card__copy {
  flex-grow: 1;
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.resource-image-card__shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(79px);
      border-end-end-radius: 3.5rem;
      inline-size: rem(79px);
    }

    @include mq(wide) {
      block-size: rem(79px);
      border-end-end-radius: 3.5rem;
      inline-size: rem(79px);
    }

    &.green {
      background-color: var(--crusta);
    }
  }
}
