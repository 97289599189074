.rtf-quote {
  background-color: var(--dark-gray);
  border-radius: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: rem(20px);
  padding-block-end: 120px;
  padding-block-start: 40px;
  padding-inline: rem(40px);
  position: relative;

  @include mq(desktop) {
    margin-block: rem(40px);
    padding: rem(80px);
    padding-inline-end: rem(137px);
  }

  &::after,
  &::before {
    block-size: 5.0625rem;
    content: "";
    inline-size: 5.0625rem;
    position: absolute;
  }

  &::before {
    background-color: var(--crusta);
    border-start-start-radius: 3.5rem;
    inset-block-end: 5.0625rem;
    inset-inline-end: 0;
  }

  &::after {
    background-color: var(--azure);
    inset-block-end: 0;
    inset-inline-end: 0;
  }
}

.rtf-quote__blockquote {
  color: var(--white);
  font-size: rem(21px);
  font-style: italic;
  line-height: 1.29;

  @include mq(desktop) {
    font-size: rem(20px);
  }
}

.rtf-quote__author {
  color: var(--white);
  font-weight: 500;
  margin-block-end: 0 !important;
  margin-block-start: rem(20px);
}

.rtf-quote__position {
  color: var(--white);
  font-size: rem(14px);
  line-height: 1.57;
  margin-block-end: 0 !important;
  margin-block-start: rem(5px);
}
