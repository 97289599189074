.modal--profile {
  border-radius: 6px;

  .modal__inner {
    max-inline-size: 700px;
    padding: 33px 38px 26px 35px;
  }

  .modal__content {
    display: grid;
    gap: 31px;
    grid-template-columns: 1fr 1fr;
    inline-size: 100%;
  }

  .thank-modal__text {
    align-items: center;
    display: flex;
    flex-direction: column;

    h2 {
      color: var(--azure);
    }
  }

  .modal__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal__right {
    img {
      border-start-end-radius: 3.5rem;
    }
  }

  .modal__close {
    inset-block-start: unset;
    inset-inline-end: unset;
    padding: 0.6875rem 1.875rem;
    position: relative;
  }

  .wrapper__cta {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .modal__name {
    h3 {
      font-size: 22px;
    }

    p {
      font-size: 16px;
      line-height: 2;
    }
  }
}

.modal__characteristics {
  border: solid 1px #dddfe2;
  border-block-start: 0;
  border-radius: 0 0 6px 6px;
  padding: 22px 18px;

  p {
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    margin-inline-start: 20px;

    li {
      font-size: 16px;
      position: relative;

      &::after {
        background-color: var(--azure);
        block-size: 7px;
        border-radius: 50%;
        content: "";
        inline-size: 7px;
        inset-block-start: 50%;
        inset-inline-start: -20px;
        position: absolute;
        transform: translateY(100%);
        transform: translateY(-50%);
      }
    }
  }
}
