/* stylelint-disable plugin/stylelint-bem-namics */

.locations__block {
  padding-block: 100px;
}

.locations__search-results {
  border: solid 1px var(--alto);
  border-radius: 13px 0 0 13px;
  border-radius: 12px;
  margin-block-start: 10px;
  max-block-size: 635px;
  min-block-size: 635px;
  overflow: auto;
  padding: 40px 60px 40px 40px;
  position: relative;

  .locations__search-card:not(:first-child) {
    margin-block-start: 22px;
  }
}

.locations__map {
  display: flex;
  inline-size: 100%;
  min-block-size: 100%;
}

.locations__search-card {
  cursor: pointer;
  position: relative;
}

.locations__search-card {
  h6 {
    font-size: 20px;
    margin: 0;
  }

  &:hover,
  &.active {
    transition: color 0.2s ease-in-out;

    h6 {
      color: var(--azure);
    }

    .locations-search-card__number {
      span {
        background-color: var(--azure);
      }
    }
  }
}

.location__row {
  display: flex;
  gap: 80px;
  position: relative;
}

.location__results-wrapper {
  flex-basis: 66%;
  position: relative;

  select {
    border-radius: 6px;
  }
}

.location__results-icon {
  block-size: 27px;
  fill: var(--dark-gray);
  inline-size: 27px;
  inset-block-start: 7px;
  inset-inline-end: 19px;
  position: absolute;
}

.locations-search-card__details {
  display: flex;
  gap: 30px;
}

.locations-search-card__number {
  span {
    background-color: var(--black);
    block-size: 27px;
    border-radius: 50%;
    color: var(--white);
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    inline-size: 27px;
    line-height: 27px;
    text-align: center;
  }
}

.locations-search-card__distance {
  color: var(--black);
}

.locations-search-card__city {
  border-block-start: 1px solid #979797;
  margin-block-start: 30px;
  padding: 12px 0 12px 59px;
}

.locations-search-card__open-hours {
  border-block-end: 1px solid #979797;
  border-block-start: 1px solid #979797;
  padding: 12px 0 12px 59px;
}

.info-window {
  h5 {
    color: var(--azure);
    font-size: 18px;
    font-weight: bold;
    margin-block-end: 10px;
  }
}

.search__input-wrapper,
.input-wrapper {
  position: relative;
}

.appointment-form {
  .locations__search-results {
    max-block-size: 217px;
    min-block-size: 217px;
  }

  .location__results-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;

    div:not(.locations__search-card),
    input,
    .Form__Element {
      margin: 0;
      margin-block-start: 0 !important;
    }
  }
}

.wrapper-cta {
  display: flex;
  margin-block-start: 20px;

  .btn {
    inline-size: 100%;
  }
}

.hours-picker {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);

  div {
    background-color: #f2f2f2;
    border: 1px solid #979797;
    border-radius: 6px;
    cursor: pointer;
    padding-block: 5px;
    text-align: center;

    &.active {
      background-color: var(--back-blue);
      font-weight: bold;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.see-locations__cta {
  margin-block-end: 20px;
  text-align: end;

  a {
    color: var(--arapawa);
    font-weight: bold;
  }
}

.search__select-a-date {
  color: var(--azure);
  font-size: 13px;
}
