.homepage-masthead {
  padding-block: var(--module-spacing);

  @include mq(desktop) {
    padding-block: 0;
  }

  .two-column-content-media {
    border-block-end: 1px solid #d3d3d3;
    position: relative;
  }

  .two-column-content-media__media-inner {
    img {
      border-end-start-radius: 0;
    }
  }

  &[data-background="white"] {
    .homepage-masthead__link {
      background-color: var(--gallery);
    }
  }
}

.homepage-masthead__panels {
  max-inline-size: rem(627px);
}

.homepage-masthead__panel {
  display: none;

  @include mq($until: desktop) {
    &:first-child {
      display: block;
    }
  }
}

.homepage-masthead__panel--active {
  @include mq(desktop) {
    display: block;
  }
}

.homepage-masthead__heading-container,
.homepage-masthead__copy-container,
.homepage-masthead__buttons {
  overflow: hidden;
}

.homepage-masthead__copy.rtf {
  line-height: 1.56;
}

.homepage-masthead__buttons {
  margin: rem(25px -5px -5px);
  overflow: hidden;
  padding: rem(5px);
}

.homepage-masthead__mobile-links {
  @include mq(desktop) {
    display: none;
  }

  ol {
    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  li {
    margin-block-start: rem(10px);

    @include mq(tablet, desktop) {
      inline-size: calc(50% - #{rem(5px)});

      &:nth-child(even) {
        margin-inline-start: rem(10px);
      }
    }
  }
}

.homepage-masthead__link {
  align-items: center;
  background-color: var(--white);
  color: var(--primary-text-color);
  display: flex;
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.38;
  padding: rem(8px 15px 8px 10px);
  position: relative;
  text-decoration: none;

  img {
    block-size: rem(40px);
    inline-size: rem(40px);
    margin-inline-end: rem(20px);
    object-fit: contain;
  }
}

.homepage-masthead__tabs {
  inline-size: 100%;
  inset-block-start: 100%;
  inset-inline-start: 0;
  position: relative;

  @include mq($until: desktop) {
    display: none;
  }
}

.homepage-masthead__tabs-inner {
  align-items: flex-start;
  block-size: rem(195px);
  border-block-start: solid rem(1px) var(--alto);
  display: flex;
  flex-wrap: nowrap;

  @include mq(wide) {
    block-size: rem(215px);
  }

  /* stylelint-disable-next-line plugin/stylelint-bem-namics */
  .epi-editContainer {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    inline-size: 100%;

    /* stylelint-disable-next-line plugin/stylelint-bem-namics */
    [data-contentgroup] {
      flex: 1 1 0;
    }
  }
}

.homepage-masthead__tab {
  color: var(--tundora);
  flex: 1 1 0;
  font-size: rem(20px);
  font-weight: 500;
  line-height: 1.2;
  outline-offset: rem(2px);
  padding-block-end: 0;
  padding-block-start: rem(34px);
  padding-inline-end: rem(50px);
  padding-inline-start: 0;
  position: relative;
  text-align: start;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  @include mq(wide) {
    font-size: rem(20px);
    line-height: 1.42;
    padding-inline-end: rem(100px);
  }

  &::after {
    background-color: var(--primary-text-color);
    block-size: rem(1px);
    content: "";
    inline-size: 100%;
    inset-block-start: rem(-1px);
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
  }

  &[aria-selected="true"] {
    color: var(--primary-text-color);
    opacity: 1;

    &::after {
      opacity: 1;
    }

    img {
      filter: grayscale(0);
    }
  }

  img {
    block-size: rem(50px);
    display: block;
    filter: grayscale(1);
    inline-size: rem(50px);
    margin-block-end: rem(10px);
    object-fit: contain;
    opacity: 0.8;
    transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }

  span {
    display: block;
  }
}

.homepage-masthead__scroll-budy {
  display: none;

  @include mq(desktop) {
    align-items: center;
    background-color: var(--cyan);
    block-size: 100%;
    display: flex;
    flex-direction: column;
    inline-size: rem(80px);
    inset-block-start: 0;
    inset-inline-end: 0;
    justify-content: center;
    position: absolute;
    text-transform: uppercase;
  }

  @include mq(wide) {
    inline-size: rem(160px);
  }

  svg {
    block-size: rem(35px);
    inline-size: rem(20px);
  }

  span {
    color: var(--arapawa);
    display: block;
    font-size: rem(12px);
    font-weight: 500;
    letter-spacing: rem(2px);
    margin-block-end: rem(15px);
    margin-block-start: rem(31px);
    text-align: center;
    transform: rotate(90deg);
  }
}

.homepage-masthead__shapes-container {
  position: relative;

  .shape-1 {
    background-color: var(--crusta);
    block-size: rem(40px);
    inline-size: rem(40px);
    inset-block-end: rem(-40px);
    inset-inline-start: rem(60px);

    @include mq(desktop) {
      background-color: var(--cyan);
      inset-block-end: 0;
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(50px);
      inline-size: rem(50px);
    }
  }
}

.homepage-masthead__image-shapes-container {
  @include mq($until: desktop) {
    display: none;
  }

  .shape-1 {
    background-color: var(--azure);
    block-size: rem(50px);
    inline-size: rem(50px);
    inset-block-end: rem(130px);
    inset-inline-end: rem(-50px);

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(240px);
      inset-inline-end: rem(-80px);
    }
  }

  .shape-2 {
    background-color: var(--arapawa);
    block-size: rem(50px);
    inline-size: rem(50px);
    inset-block-end: rem(80px);
    inset-inline-end: 0;

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(160px);
    }
  }

  .shape-3 {
    background-color: var(--crusta);
    block-size: rem(20px);
    inline-size: rem(20px);
    inset-block-end: rem(80px);
    inset-inline-end: rem(80px);

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: rem(160px);
      inset-inline-end: rem(160px);
    }
  }

  .shape-4 {
    background-color: var(--royal-blue);
    block-size: rem(80px);
    inline-size: rem(80px);
    inset-block-end: 0;
    inset-inline-end: 0;

    @include mq(wide) {
      block-size: rem(160px);
      inline-size: rem(160px);
    }
  }

  .shape-5 {
    background-color: var(--arapawa);
    block-size: rem(80px);
    inline-size: rem(80px);
    inset-block-end: 0;
    inset-inline-end: rem(-80px);

    @include mq(wide) {
      block-size: rem(160px);
      inline-size: rem(160px);
      inset-inline-end: rem(-160px);
    }
  }
}
