.image-card-grid {
  background: var(--back-blue);
  margin: 0;
  padding-block: 100px;
}

.image-card__corner-rounded {
  background: var(--azure);
  block-size: 69px;
  border-radius: 0 0 55px;
  inline-size: 69px;
  position: absolute;
}

.resource-image-card:nth-child(4n + 1) > .image-card__corner-rounded {
  background: #038c5a;
}
