.rtf {
  a:not(.btn) {
    color: var(--azure);
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }
  }

  .btn {
    margin-block: rem(20px);

    @include mq(desktop) {
      margin-block: rem(40px);
    }
  }

  p {
    margin-block-end: 1em;
  }

  > * {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }

  ol,
  ul {
    list-style: outside;
    margin-block: rem(20px);
    margin-inline-start: rem(20px);
    text-align: start;

    @include mq(desktop) {
      margin-block: rem(40px);
      margin-inline-start: rem(40px);
    }

    @include mq(wide) {
      margin-inline-start: rem(60px);
    }
  }

  ol {
    list-style: decimal;
  }

  li {
    margin-block: rem(20px);
    padding-inline-start: rem(20px);
  }

  /* stylelint-disable */
  table:not(.product-comparison-table__table) {
    block-size: auto !important;
    display: block;
    inline-size: 100%;
    margin-block: rem(20px);
    max-inline-size: 0;
    min-inline-size: 100%;
    overflow-x: auto;
    white-space: nowrap;

    @include mq(desktop) {
      margin-block: rem(40px);
    }

    thead {
      display: table;
      inline-size: 100%;
    }

    tbody {
      display: table;
      inline-size: 100%;
    }

    th {
      background-color: var(--arapawa);
      color: var(--white);
      font-size: rem(16px);
      max-inline-size: rem(175px);
      min-inline-size: rem(150px);
      padding-block: rem(10px);
      padding-inline: rem(10px);
      vertical-align: middle;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    tr {
      block-size: rem(60px);
    }

    tr:nth-of-type(even) {
      background-color: var(--white);
    }

    tr:nth-of-type(odd) {
      background-color: var(--alto);
    }

    td {
      color: var(--arapawa);
      inline-size: auto !important;
      font-size: rem(16px);
      max-inline-size: rem(175px);
      min-block-size: rem(60px);
      min-inline-size: rem(100px);
      padding-block: rem(10px);
      text-align: center;
      vertical-align: middle;
      white-space: normal;
    }

    thead th,
    thead td {
      background-color: var(--arapawa);
      color: var(--white);
      font-size: rem(16px);
      max-inline-size: rem(175px);
      min-inline-size: rem(150px);
      padding-block: rem(10px);
      padding-inline: rem(10px);
      text-align: center;
      vertical-align: middle;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    thead + tbody tr:nth-of-type(odd) {
      background-color: var(--white);
    }

    thead + tbody tr:nth-of-type(even) {
      background-color: var(--alto);
    }
  }
}
