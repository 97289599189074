.simple-masthead {
  background-color: var(--azure);
  color: var(--white);
  text-align: center;

  &[data-background="cyan"] {
    background-color: var(--back-blue);
    color: var(--dark-gray);

    .simple-masthead__heading {
      color: var(--dark-gray);
    }
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-block-size: rem(272px);
    padding-block-end: rem(100px);
    padding-block-start: rem(85px);
    position: relative;

    @include mq(desktop) {
      justify-content: center;
      min-block-size: rem(330px);
      padding-block: 0;
    }

    @include mq(wide) {
      min-block-size: rem(400px);
    }
  }
}

.simple-masthead__heading {
  color: var(--white);
  margin-block-start: 25px;
  max-inline-size: rem(778px);
  padding-block-start: 25px;
}

.error-page__subheading {
  font-size: 1.5rem;
  font-weight: bold;
}

.simple-masthead__subheading {
  margin-block-end: 25px;
  max-inline-size: rem(778px);
  padding-block-end: 25px;
}

.simple-masthead__shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(20px);
      inline-size: rem(20px);
      inset-block-end: rem(30px);
      inset-inline-end: rem(30px);
    }

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: rem(80px);
      inset-inline-end: rem(40px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-end: 0;
      inset-inline-end: rem(50px);
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-inline-end: rem(80px);
    }
  }

  .shape-3 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(50px);
      inline-size: rem(50px);
      inset-block-end: rem(-50px);
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(-80px);
    }
  }
}
