.simple-content-block {
  padding-block: var(--module-spacing);

  &[data-background="gray"] {
    @include mq($until: desktop) {
      padding-block: var(--module-spacing);
    }
  }
}

.simple-content-block__inner-content {
  @include mq(desktop) {
    display: flex;
    gap: rem(70px);
    justify-content: space-between;
  }
}

.simple-content-block__heading {

  @include mq(desktop) {
    inline-size: 40%;
  }

  & * {
    font-size: rem(32px);
    line-height: 1.13;
    @include mq(desktop) {
      font-size: rem(45px);
      line-height: 1.09;
    }
  }
}

.simple-content-block__copy {
  flex-shrink: 0;
  position: relative;

  @include mq($until: desktop) {
    margin-block-start: rem(2a0px);
  }

  @include mq(desktop) {
    inline-size: 56.56%;
  }
}
