.profiles-image__container {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  display: none;
  inset: 0;
  justify-content: center;
  padding-block-end: 50px;
  position: fixed;
  z-index: 100;
}

.profiles-image__container--active {
  display: flex;
}

.profiles-image__image {
  position: relative;
}

.profiles-image__close {
  align-items: center;
  block-size: 50px;
  border: 1px solid var(--azure);
  border-radius: 50%;
  display: flex;
  inline-size: 50px;
  justify-content: center;

  svg {
    block-size: 30px;
    fill: var(--azure);
    inline-size: 30px;
  }
}

.profiles-image__inner-container {
  background-color: var(--white);
  border: solid 1px #dddcdc;
  box-shadow: 0 2px 11px 0 rgb(181 178 178 / 12%),
    0 4px 13px 0 rgb(203 203 203 / 22%);
  padding: 30px;
}

.profile-logo__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-end: 30px;
}
