.logo-carousel {
  block-size: rem(80px);
  overflow: hidden;
  position: relative;

  @include mq(tablet) {
    block-size: rem(180px);
  }

  &.initialized {
    img {
      position: absolute;
    }
  }

  &:not(.initialized) {
    display: flex;
    justify-content: center;
  }

  img {
    block-size: rem(80px);
    inline-size: rem(80px);
    object-fit: contain;
    padding: rem(14px);

    @include mq(tablet) {
      block-size: rem(180px);
      inline-size: rem(180px);
      padding: rem(30px);
    }
  }
}
