.featured-content-tabs-row {
  padding-block: var(--module-spacing);
  position: relative;

  &[data-background="gray"] {
    .resource-image-card {
      border: 0;

      &[data-background="gray"] {
        border: solid rem(1px) var(--alto);
      }
    }
  }

  &[data-background="cyan"] {
    background-color: var(--back-blue);
  }
}

.featured-content-tabs-row__header {
  @include mq(tablet) {
    margin-block-end: rem(35px);
  }
}

.featured-content-tabs-row__heading,
.featured-content-tabs-row__copy {
  margin: 0 auto;
  max-inline-size: rem(778px);
  text-align: center;
}

.featured-content-tabs-row__copy {
  margin-block-start: rem(10px);

  @include mq(desktop) {
    margin-block-start: rem(20px);
  }
}

.featured-content-tabs-row__tabs {
  margin-block-end: rem(10px);
  overflow: auto;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  padding-block-end: rem(10px);
  padding-block-start: rem(5px);
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  @include mq(tablet) {
    margin-block-end: rem(30px);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .container {
    display: flex;

    @include mq(tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.featured-content-tabs-row__tab {
  border-block-end: solid 0.2rem rgba(var(--arapawa-rgb), 1);
  color: var(--arapawa);
  flex-shrink: 0;
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.38;
  outline-offset: rem(4px);
  padding: rem(18px);
  position: relative;
  text-align: center;
  transition: border-color 0.2s ease-in-out;

  @include mq($until: tablet) {
    flex-grow: 1;
    min-inline-size: 45%;
  }

  @include mq(tablet) {
    inline-size: rem(200px);
    min-inline-size: rem(200px);

    & + .featured-content-tabs-row__tab {
      margin-inline-start: rem(40px);
    }
  }

  @include active-state {
    border-color: var(--azure);
  }

  &::after {
    background-color: var(--azure);
    block-size: rem(3px);
    content: "";
    inline-size: 100%;
    inset-block-end: rem(-3px);
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
  }

  &[aria-selected="true"] {
    &::after {
      opacity: 1;
    }
  }
}

.featured-content-tabs-row__panel {
  &:not(.featured-content-tabs-row__panel--active) {
    display: none;
  }

  .icon-card {
    @include mq(tabletLandscape) {
      margin-block: rem(60px);
    }
  }

  .content-with-media-row {
    margin-block: 0;
    position: relative;

    @include mq(desktop) {
      margin: 0;
      padding-block: 0;
    }
  }
}

.featured-content-tabs-row__shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(30px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(120px);
      inline-size: rem(120px);
      inset-block-start: rem(40px);
    }
  }
}
