:root {
  // Color palette
  --cyan: #1fd4ff;
  --dodger-blue: #40a0ff;
  --azure: #049dd9;
  --royal-blue: #00a1e5;
  --cobalt: #0047ad;
  --arapawa: #262626;
  --crusta: #038c5a;
  --white: #fff;
  --gallery: #efefef;
  --mercury: #e5e5e5;
  --alto: #d3d3d3;
  --tundora: #4d4d4d;
  --gray: #b3b3b3;
  --black: #000;
  --back-blue: #e3f4fc;
  --dark-gray: #262620;
  --neutral-green: #f4ffe9;

  // Text colors
  --primary-text-color: var(--arapawa);
  --secondary-text-color: var(--tundora);
  --highlight-text-color: var(--royal-blue);

  // UI
  --error-color: #d91e43;
  --error-background-color: #fef1f3;

  // RGB Colors
  --white-rgb: #{hex-to-rgb(#fff)};
  --arapawa-rgb: #{hex-to-rgb(#262626)};
}
