.driver-release-notes-view-more {
  color: var(--azure);
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    border-block-end: 0;
    font-size: rem(18px);
    font-weight: 500;
    line-height: 1.56;
  }

  @include active-state {
    color: var(--arapawa);
  }
}
