.icon-card {
  display: block;
  text-decoration: none;

  &[href] {
    @include active-state {
      .icon-card__heading {
        color: var(--azure);
      }
    }
  }
}

.icon-card__inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.icon-card__image {
  block-size: rem(60px);
  inline-size: rem(60px);

  @include mq(tablet) {
    block-size: rem(80px);
    inline-size: rem(80px);
  }
}

.icon-card__heading {
  font-size: rem(16px);
  line-height: 1.38;
  margin-block-end: 0;
  text-align: center;
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(18px);
    line-height: 1.4;
  }

  .icon-card__image + & {
    margin-block-start: rem(10px);
  }
}

.icon-card__description {
  color: var(--secondary-text-color);
  font-size: rem(14px);
  line-height: 1.43;
  margin-block-start: rem(5px);
  text-align: center;

  @include mq(desktop) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.icon-card__percentage {
  color: var(--azure);
  font-size: rem(40px);
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 0.7;
  margin-block-start: rem(10px);
  text-align: center;

  @include mq(desktop) {
    font-size: rem(40px);
  }
}

.icon-card__percentage--before {
  color: var(--crusta);
  font-size: rem(30px);
  text-decoration: line-through;
}

.icon-card__percentage-container {
  align-items: flex-start;
  display: flex;
  gap: 10px;
}
