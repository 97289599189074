/* stylelint-disable plugin/stylelint-bem-namics */
.footer {
  border-block-start: solid rem(1px) var(--alto);

  .Form__Element:first-of-type {
    margin-block-start: 0 !important;
  }
}

.footer__top {
  padding-block: rem(40px);

  @include mq(desktop) {
    padding-block: rem(60px);
  }

  @include mq(wide) {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.footer__top-right {
  @include mq(desktop) {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
  }
}

.footer__logo {
  display: flex;
  flex-shrink: 0;
  inline-size: rem(260px);
  margin-block-end: rem(40px);
  margin-inline: auto;
  object-fit: contain;

  @include mq(desktop) {
    inline-size: rem(260px);
  }

  @include mq(wide) {
    inline-size: rem(260px);
    margin-inline-end: rem(80px);
    margin-inline-start: 0;
  }

  @include mq(full) {
    inline-size: rem(260px);
    margin-inline-end: rem(100px);
  }

  img {
    inline-size: 100%;
  }
}

.footer__menu {
  inline-size: 100%;

  @include mq(desktop) {
    > ul {
      display: flex;
      inline-size: 100%;
      justify-content: center;
    }
  }
}

.footer__menu-category {
  @include mq($until: desktop) {
    border-block-start: solid rem(1px) var(--alto);

    &:last-child {
      border-block-end: solid rem(1px) var(--alto);
    }
  }

  @include mq(desktop) {
    flex: 1 1 0;
    padding-inline-end: rem(50px);
  }

  @include mq(wide) {
    max-inline-size: rem(230px);
  }
}

.footer__menu-label {
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: left;
  position: relative;
  text-align: start;
  text-decoration: none;

  @include mq($until: desktop) {
    align-items: center;
    justify-content: space-between;
    padding-block: rem(20px);
  }

  @include mq(desktop) {
    margin-block-end: rem(15px);

    &[href] {
      @include active-state {
        border-block-end: solid 0.2rem rgba(var(--arapawa-rgb), 1);
        color: var(--azure);
      }
    }
  }
}

.footer__menu-label-icon {
  @include mq($until: desktop) {
    block-size: rem(15px);
    inline-size: rem(15px);
    position: relative;

    &::after {
      background-color: var(--primary-text-color);
      block-size: rem(15px);
      content: "";
      inline-size: rem(2px);
      inset-block-start: 0;
      inset-inline-start: rem(6.5px);
      position: absolute;
      transition: transform 0.3s ease-in-out;

      .footer__menu-category--expanded & {
        transform: rotate(90deg);
      }
    }

    &::before {
      background-color: var(--primary-text-color);
      block-size: rem(2px);
      content: "";
      inline-size: rem(15px);
      inset-block-start: rem(6.5px);
      inset-inline-start: 0;
      position: absolute;
    }
  }
}

.footer__menu-subcategory {
  @include mq($until: desktop) {
    block-size: 0;
    overflow: hidden;
    transition: block-size 0.3s ease-in-out;
  }

  @include mq(desktop) {
    block-size: auto !important;
  }

  ul {
    @include mq($until: desktop) {
      padding-block-end: rem(20px);
    }
  }

  li {
    align-items: center;
    display: flex;

    & + li {
      margin-block-start: rem(20px);
    }
  }

  a {
    color: var(--secondary-text-color);
    font-size: rem(16px);
    line-height: 1.5;
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }
  }
}

.footer__menu-badge {
  background-color: var(--arapawa);
  block-size: rem(30px);
  border-radius: rem(15px);
  color: var(--white);
  font-size: rem(14px);
  line-height: 1.86;
  margin-inline-start: rem(10px);
  padding: rem(2px 10px);
}

.footer__menu-form {
  flex-shrink: 0;

  @include mq(desktop) {
    inline-size: rem(230px);
  }

  .EPiServerForms {
    background-color: transparent;
    padding: 0;
  }

  .Form__Element {
    & + .Form__Element {
      margin-block-start: rem(10px) !important;
    }
  }

  .FormTextbox__Input {
    @include placeholder {
      color: var(--primary-text-color);
      font-weight: 500;
    }
  }

  .FormSelection {
    select {
      color: var(--primary-text-color);
      font-weight: 500;
    }
  }

  .FormSubmitButton {
    block-size: rem(40px);
    font-size: rem(16px);
    inline-size: 100%;
    padding: rem(8px 30px);
  }
}

.footer__bottom {
  background-color: var(--arapawa);
  color: var(--white);
  padding-block: rem(40px);
  text-align: center;

  @include mq(desktop) {
    padding-block: rem(15px);
  }

  .container {
    @include mq(desktop) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}

.footer__social-links {
  align-items: center;
  display: flex;
  justify-content: center;

  li {
    & + li {
      margin-inline-start: rem(30px);
    }
  }

  a {
    display: flex;

    &.focus-visible {
      outline: solid rem(1px) var(--white);
      outline-offset: rem(4px);

      &:-moz-focusring {
        outline: solid rem(1px) var(--white);
        outline-offset: rem(4px);
      }
    }

    img {
      block-size: rem(20px);
      inline-size: rem(20px);
      object-fit: contain;
    }
  }
}

.footer__bottom-right {
  @include mq(desktop) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.footer__subfooter-logo {
  display: flex;
  margin-block-start: rem(35px);
  margin-inline: auto;
  max-block-size: rem(50px);
  max-inline-size: rem(130px);

  @include mq(desktop) {
    margin-block-start: 0;
    margin-inline-start: rem(30px);
  }

  img {
    object-fit: contain;
  }
}

.footer__copyright {
  font-size: rem(14px);
  line-height: 1.86;
  margin-block-start: rem(30px);

  @include mq(desktop) {
    margin-block-start: 0;
    margin-inline-start: rem(30px);
  }
}

.footer__utility-links {
  display: flex;
  justify-content: center;
  margin-block-start: rem(16px);

  @include mq(desktop) {
    margin-block-start: 0;
    margin-inline-start: rem(30px);
  }

  li {
    & + li {
      margin-inline-start: rem(20px);
    }
  }

  a {
    color: var(--white);
    font-size: rem(14px);
    line-height: 1.57;
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }

    &.focus-visible {
      outline: solid rem(1px) var(--white);
      outline-offset: rem(4px);

      &:-moz-focusring {
        outline: solid rem(1px) var(--white);
        outline-offset: rem(4px);
      }
    }
  }
}
