.facet {
  @include mq(desktop) {
    border-block-start: 1px solid var(--alto);

    &:last-child {
      border-block-end: 1px solid var(--alto);
    }
  }

  &:not(:first-of-type) {
    border-block-start: 1px solid var(--alto);
  }
}

.facet__title-btn {
  align-items: center;
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--azure);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: rem(16px);
  font-weight: 500;
  inline-size: 100%;
  justify-content: space-between;
  line-height: 1.5;
  padding-block: rem(20px);
  padding-inline: rem(20px);

  @include active-state {
    color: var(--azure);

    .facet__title-icon svg {
      fill: var(--azure);
    }
  }
}

.facet__title-icon {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  svg {
    block-size: rem(14px);
    fill: var(--arapawa);
    inline-size: rem(14px);
  }
}

.facet__list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-inline: rem(20px);
}

.facet__list-inner {
  margin-block-end: rem(20px);
}

.facet__option {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;

  & + .facet__option {
    margin-block-start: rem(20px);

    @include mq(desktop, wide) {
      margin-block-start: rem(20px);
    }
  }
}

.facet__input {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-inline-end: rem(10px);
  @include active-state {
    & .label {
      text-decoration: underline;
    }
  }

  input {
    block-size: 0;
    cursor: pointer;
    inline-size: 0;
    opacity: 0;
    position: absolute;

    &:checked {
      & + .checkmark {
        border: solid rem(1px) var(--azure);

        &::after {
          display: block;
          opacity: 1;
        }
      }

      & ~ .label {
        color: var(--azure);
      }
    }
  }

  .label {
    font-size: rem(16px);
    line-height: 1.5;
  }

  .checkmark {
    background-color: var(--white);
    block-size: rem(20px);
    border: solid rem(1px) var(--alto);
    display: flex;
    inline-size: rem(20px);
    margin-inline-end: rem(10px);

    &::after {
      background-blend-mode: overlay, normal;
      block-size: rem(14px);
      border: solid var(--azure);
      border-radius: rem(1px);
      border-width: rem(0 2px 2px 0);
      content: "";
      display: none;
      inline-size: rem(6px);
      inset-block-start: rem(1px);
      inset-inline-start: rem(6px);
      position: relative;
      transform: rotate(45deg);
    }
  }
}

.facet__count {
  font-size: rem(16px);
  line-height: 1.5;
}

.facet--contenttypes {
  align-items: center;
  display: flex;
  gap: 11px;
}
