.facet-listing {
  block-size: 100%;
  display: flex;
  flex-flow: column;
  inline-size: 100%;
  overflow: hidden;
}

.facet-listing__subheading {
  color: var(--azure);
  display: none;

  @include mq(desktop) {
    align-items: center;
    block-size: rem(40px);
    display: flex;
    font-size: rem(18px);
    font-weight: 500;
    line-height: 1.56;
    margin-block-end: rem(20px);
  }
}

.facet-listing__button {
  background-color: var(--white);
  background-size: 20px;
  block-size: rem(50px);
  border: rem(1px) solid var(--alto);
  color: var(--tundora);
  display: block;
  font-size: rem(16px);
  inline-size: 100%;
  line-height: 1.38;
  padding-inline: rem(20px);
  position: relative;
  text-align: start;

  @include mq(desktop) {
    display: none;
  }

  svg {
    fill: var(--arapawa);
    inline-size: rem(14px);
    inset-block-start: 50%;
    inset-inline-end: rem(20px);
    max-block-size: 100%;
    position: absolute;
    stroke: var(--arapawa);
    stroke-width: 1px;
    transform: translateY(-50%) rotate(-90deg);
    transition: transform 0.3s ease-in-out;
  }
}

.facet-listing__button--expanded {
  border-block-end: 0;

  svg {
    transform: translateY(-50%) rotate(-180deg);
  }
}

.facet-listing__links {
  margin-block-start: rem(20px);

  @include mq(desktop) {
    margin-block-start: rem(40px);
  }

  .event-links__link {
    align-items: center;
    display: flex;
    font-size: rem(18px);
    inline-size: 100%;
    margin-block-end: rem(30px);
    text-decoration: none;

    @include mq(desktop) {
      margin-inline-end: rem(42px);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .event-links__link:last-child {
    @include mq(desktop) {
      margin-block-end: rem(50px);
    }
  }
}

.facet-listing__list-categories {
  block-size: 0;
  visibility: hidden;

  @include mq(desktop) {
    inline-size: rem(367px);
  }
}

.facet-listing__list-categories--expanded {
  block-size: 100% !important;
  border: rem(1px) solid var(--alto);
  border-block-start: 0;
  display: flex;
  flex-flow: column;
  inline-size: 100%;
  visibility: visible;

  @include mq(desktop) {
    border: 0;
  }
}

/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable plugin/stylelint-bem-namics */
.search-results-listing__sort.FormSelection select {
  background-position-x: calc(100% - 20px);
}
