.hamburger-menu {
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  @include mq(desktop) {
    display: none;

    &.hamburger-menu--is-open {
      display: none;
    }
  }

  @include mq(desktop) {
    display: none;
  }

  &.hamburger-menu--is-open {
    block-size: 100%;
    display: block;
    padding-block-end: rem(100px);
  }

  .header__cta {
    display: inline-block;
    inline-size: 100%;
    margin-block: rem(20px);
  }

  .utility-navigation {
    background-color: transparent;
    color: var(--arapawa);
    display: block;
    inline-size: 100%;
    margin-block: rem(2px);
  }

  .utility-navigation__items {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
  }

  .utility-navigation__cta {
    color: var(--arapawa);

    svg {
      fill: var(--arapawa);
    }
  }

  .utility-navigation__partner-program {
    display: block;
  }

  .megamenu__item-link,
  .megamenu__link-list-link {
    color: var(--arapawa);
    text-decoration: none;
  }

  .megamenu__item-link {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-block: rem(11px);
    padding-inline: rem(40px);

    @include active-state {
      background-color: var(--arapawa);
      color: var(--white);

      svg {
        fill: var(--white);
      }
    }

    svg {
      block-size: rem(12px);
      fill: var(--arapawa);
      inline-size: rem(7px);
      pointer-events: none;
    }

    &[aria-selected="true"] {
      background-color: var(--arapawa);
      color: var(--white);

      svg {
        fill: var(--white);
      }
    }
  }

  .megamenu__link-list-link {
    display: block;

    @include active-state {
      color: var(--azure);
    }
  }

  .megamenu__link-list {
    border-block-start: rem(1px) solid var(--alto);
    padding-inline: rem(40px);
  }

  .megamenu__link-list-item {
    margin-block: rem(30px);
  }

  .megamenu__item {
    color: var(--arapawa);
    margin-block-end: rem(20px);
    text-decoration: none;
  }

  .megamenu-link-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-block-start: rem(40px);
    }

    &.megamenu-link-card--no-heading {
      margin-block-start: 0;
    }
  }

  .megamenu-link-card__heading {
    font-size: rem(18px);
    font-style: italic;
    inline-size: 100%;
    line-height: 1.22;
    min-block-size: rem(32px);
    padding-block: rem(10px);
    padding-inline: var(--container-padding);
  }

  .megamenu-link-card__spacer {
    block-size: 0;
  }

  .megamenu-link-card__links-list {
    display: flex;
    flex-direction: column;
    inline-size: 100%;
  }

  .megamenu-link-card__link-item-label {
    color: var(--arapawa);
    display: inline-block;
    inline-size: 100%;
    padding-inline: var(--container-padding);
    text-decoration: none;
  }

  .megamenu-link-card__link-item-description {
    padding-inline: var(--container-padding);
  }

  .megamenu-link-card__link-item:not(.megamenu-link-card__link-item--no-description) {
    margin-block: rem(12px);
  }

  .megamenu-link-card__link-item.megamenu-link-card__link-item--no-description {
    border-block-end: rem(1px) solid var(--alto);
    inline-size: 100%;

    & .megamenu-link-card__link-item-label {
      padding-block: rem(10px);
    }
  }
}

.hamburger-menu__primary-link {
  align-items: center;
  background-color: var(--white);
  border-block-end: rem(1px) solid var(--alto);
  color: var((--arapawa));
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin-inline: calc(var(--container-padding) * -1);
  padding-block: rem(11px);
  padding-inline: var(--container-padding);
  text-decoration: none;

  svg {
    block-size: rem(12px);
    fill: var(--arapawa);
    inline-size: rem(7px);
    pointer-events: none;
  }
}

.hamburger-menu__megamenu-panel-back,
.hamburger-menu__megamenu-cards-back {
  block-size: rem(40px);
  border-block-end: rem(1px) solid var(--alto);
  color: var(--arapawa);
  inline-size: 100%;
  padding-inline: var(--container-padding);
  text-align: start;

  svg {
    block-size: rem(12px);
    fill: var(--arapawa);
    inline-size: rem(7px);
    pointer-events: none;
  }
}

.hamburger-menu__megamenu-title {
  border-block-end: rem(1px) solid var(--alto);
  color: var(--arapawa);
  font-size: rem(18px);
  font-weight: 500;
  line-height: 1.33;
  padding-block: rem(20px);
  padding-inline: var(--container-padding);
}

.hamburger-menu__megamenu-panel-back,
.hamburger-menu__megamenu-link-list,
.hamburger-menu__megamenu-title {
  .hamburger-menu__megamenu-item-cards-panel--is-open & {
    display: none;
  }
}

.hamburger-menu__megamenu-title--link {
  display: block;
  text-decoration: none;

  @include active-state {
    color: var(--azure);
  }
}

.hamburger-menu__megamenu-items {
  inline-size: 100%;
}

.hamburger-menu__megamenu-item {
  border-block-end: rem(1px) solid var(--alto);
  inline-size: 100%;
  padding-inline: var(--container-padding);

  .hamburger-menu__megamenu-item-cards-panel--is-open
  &:not(.hamburger-menu__megamenu-item-cards-panel--is-open) {
    display: none;
  }
}

.hamburger-menu__megamenu-item-link {
  align-items: center;
  color: var(--arapawa);
  display: flex;
  font-size: rem(16px);
  font-weight: 500;
  inline-size: 100%;
  justify-content: space-between;
  line-height: 1.38;
  padding-block: rem(10px);
  text-decoration: none;

  svg {
    block-size: rem(12px);
    fill: var(--arapawa);
    inline-size: rem(7px);
    pointer-events: none;
  }
}

.hamburger-menu__megamenu-link-list-item {
  border-block-end: rem(1px) solid var(--alto);
  padding-inline: var(--container-padding);
}

.hamburger-menu__megamenu-link-list-link {
  color: var(--arapawa);
  display: inline-block;
  padding-block: rem(10px);
  text-decoration: none;
}

.hamburger-menu__megamenu-item-cards-panel {
  background-color: var(--white);
  display: none;
  inline-size: 100%;
  inset-block-start: 0;
  position: relative;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;

  &.hamburger-menu__megamenu-item-cards-panel--is-open {
    block-size: 100%;
    display: block;
    transform: translateX(0);
  }
}

.hamburger-menu__megamenu-panel {
  background-color: var(--white);
  block-size: 100%;
  display: none;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding-block-end: rem(100px);
  position: absolute;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  z-index: z("default");

  &.hamburger-menu__megamenu-panel--is-open {
    display: block;
    overflow-y: scroll;
    transform: translateX(0);
  }
}

.hamburger-menu__megamenu-item-cards {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  padding-block: rem(20px);

  & > * {
    flex-basis: 100%;
  }

  .resource-image-card {
    display: none;
  }
}

.hamburger-menu__primary-nav {
  .hamburger-menu__megamenu-panel--is-open & {
    display: none;
  }
}
