.content-detail-hero {
  padding-block-end: rem(80px);
  padding-block-start: rem(20px);
  position: relative;

  @include mq(desktop) {
    background-color: var(--white);
    border-radius: 100px 0 0;
    padding: rem(60px 139px 60px 80px);
  }

  &.content-detail-hero--no-padding {
    padding-block-end: 0;
  }
}

.content-detail-hero__info {
  align-items: center;
  display: flex;
  margin-block-end: rem(10px);

  @include mq(desktop) {
    margin-block-end: rem(16px);
  }
}

.content-detail-hero__date {
  align-items: center;
  display: flex;
  font-size: rem(14px);
  line-height: 1.57;

  &::after {
    background-color: var(--secondary-text-color);
    block-size: rem(10px);
    content: "";
    display: block;
    inline-size: rem(1px);
    margin-inline: 10px;
  }
}

.content-detail-hero__label {
  font-size: rem(14px);
  line-height: 1.57;
}

.content-detail-hero__subheading {
  color: var(--primary-text-color);
  font-weight: 500;

  @include mq(desktop) {
    &:not(:last-child) {
      margin-block-end: rem(20px);
    }
  }

  &:not(:last-child) {
    margin-block-end: rem(10px);
  }
}

.content-detail-hero__author {
  align-items: center;
  display: flex;

  img {
    block-size: rem(60px);
    border-radius: 50%;
    flex-shrink: 0;
    inline-size: rem(60px);
    margin-inline-end: rem(10px);

    @include mq(desktop) {
      block-size: rem(80px);
      inline-size: rem(80px);
      margin-inline-end: rem(20px);
    }
  }
}

.content-detail-hero__author-name {
  color: var(--primary-text-color);
  font-weight: 500;
  margin-block-end: rem(5px);
}

.content-detail-hero__author-position {
  font-size: rem(14px);
  line-height: 1.57;
}
