.simple-card {
  background-color: var(--white);
  border: solid rem(1px) var(--alto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline-offset: rem(4px);
  padding: rem(30px 55px 30px 30px);
  position: relative;
  text-align: start;
  text-decoration: none;

  @include mq(desktop) {
    padding: rem(40px 80px 40px 40px);
  }

  @include active-state {
    box-shadow: rem(0 0 20px 1px) var(--alto);

    .simple-card__icon {
      transform: translateX(10px);
    }

    .simple-card__heading {
      color: var(--azure);
    }
  }

  .simple-card__icon {
    block-size: rem(15px);
    fill: var(--azure);
    inline-size: rem(15px);
    inset-block-end: rem(30px);
    inset-inline-end: rem(30px);
    position: absolute;
    transition: transform 0.2s ease-in-out;

    @include mq(desktop) {
      block-size: rem(20px);
      inline-size: rem(20px);
      inset-block-end: rem(40px);
      inset-inline-end: rem(40px);
    }
  }
}

.simple-card__heading {
  font-size: rem(18px);
  line-height: 1.33;
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
    margin-block-end: rem(5px);
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.simple-card__description {
  color: var(--secondary-text-color);
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}
