/* stylelint-disable plugin/stylelint-bem-namics */
.content-detail {
  margin-block-end: var(--module-spacing);

  .container--with-sidebar {
    @include mq($until: desktop) {
      flex-direction: column-reverse;
    }
  }

  .container__aside {
    @include mq(desktop) {
      padding-block-start: rem(60px);
    }

    .resource-image-card__content {
      padding: rem(30px);
    }

    > * {
      /* stylelint-disable-next-line selector-max-compound-selectors */
      & + * {
        margin-block-start: rem(40px);
      }
    }
  }

  .content-blocks {
    @include mq(desktop) {
      padding-inline-start: rem(80px);
    }
  }
}

.content-detail__hero-image {
  block-size: rem(360px);
  inline-size: 100%;
  position: relative;

  @include mq(tabletLandscape) {
    block-size: rem(480px);
  }

  @include mq(desktop) {
    & + .container--with-sidebar {
      .content-detail-hero {
        margin-block-start: rem(-150px) !important;
        min-block-size: rem(150px);
      }
    }
  }

  &::after,
  &::before {
    block-size: 5.0625rem;
    content: "";
    inline-size: 5.0625rem;
    position: absolute;
  }

  &::before {
    background-color: var(--crusta);
    border-end-end-radius: 3.5rem;
    inset-block-start: 0;
    inset-inline-start: 0;
  }

  &::after {
    background-color: var(--azure);
    border-start-start-radius: 3.5rem;
    inset-block-end: 0;
    inset-inline-end: 0;
  }
}

.content-detail__gated-form {
  .EPiServerForms {
    padding: rem(20px 30px 30px);
  }

  .FormSubmitButton {
    inline-size: 100%;
  }

  .Form__Element {
    & + .Form__Element {
      margin-block-start: rem(20px) !important;
    }
  }

  .FormSubmitButton {
    font-size: rem(16px);
    line-height: 1.5;
    padding: rem(8px 30px);
  }

  .FormRichText {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: rem(24px);
      line-height: 1.42;
      margin-block-end: rem(10px);
    }
  }
}
