.partner-logo-banner {
  padding-block-start: var(--module-spacing);
  position: relative;

  .logo-carousel {
    margin-block-start: rem(24px);

    @include mq(desktop) {
      margin-block-start: rem(20px);
    }
  }

  &.partner-logo-banner--full {
    padding-block-end: rem(120px);

    @include mq(wide) {
      padding-block-end: rem(140px);
    }
  }
}

.partner-logo-banner__heading {
  margin-inline: auto;
  max-inline-size: rem(778px);
  text-align: center;
}

.partner-logo-banner__copy {
  margin-inline: auto;
  max-inline-size: rem(778px);
  text-align: center;
}

.partner-logo-banner__buttons {
  inline-size: 100%;
  margin-block: rem(-10px);
  margin-block-start: rem(14px);

  @include mq($until: tablet) {
    margin-inline: rem(-10px);
  }

  @include mq(tablet) {
    display: flex;
    justify-content: center;
    margin-block-start: rem(10px);
  }

  .btn {
    margin: rem(10px);
  }
}

.partner-logo-banner__top-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: 0;
      inset-inline-end: rem(80px);
    }

    @include mq(wide) {
      inset-inline-end: rem(120px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(40px);
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(120px);
      inline-size: rem(120px);
    }
  }
}

.partner-logo-banner__bottom-shapes-container {
  .shape-1 {
    background-color: var(--cyan);
    block-size: rem(20px);
    inline-size: rem(20px);
    inset-block-end: 0;
    inset-inline-start: 0;

    @include mq(desktop) {
      block-size: rem(50px);
      inline-size: rem(50px);
      inset-inline-start: rem(80px);
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-inline-start: rem(100px);
    }
  }

  .shape-2 {
    background-color: var(--arapawa);
    block-size: rem(60px);
    inline-size: rem(60px);
    inset-block-end: rem(20px);
    inset-inline-start: rem(20px);

    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(50px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
      inset-block-end: rem(60px);
    }
  }
}
