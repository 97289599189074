/* stylelint-disable plugin/stylelint-bem-namics */
.contact-us-form {
  padding-block: var(--module-spacing);
  position: relative;

  .container {
    position: relative;
  }

  &[data-background="gray"] {
    .EPiServerForms {
      background-color: var(--gallery);
    }
  }

  &[data-background="white"] {
    .EPiServerForms {
      background-color: var(--white);
    }
  }
}

.contact-us-form__form {

  @include mq(desktop) {
    flex-shrink: 0;
    margin-inline: auto;
    max-inline-size: rem(790px);
  }

  @include mq(wide) {
    max-inline-size: rem(1050px);
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: rem(24px);
    line-height: 1.17;

    @include mq(desktop) {
      font-size: rem(29px);
      line-height: 1.14;
    }

    @include mq(wide) {
      font-size: rem(45px);
      line-height: 1.09;
    }
  }

  .EPiServerForms {
    padding: 0;
  }

  .Form__Element {
    margin-block-start: rem(10px) !important;

    @include mq(desktop) {
      margin-block-start: rem(20px) !important;
    }
  }

  .FormStep {
    margin-block-start: rem(20px) !important;

    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .Form__Title {
    margin: auto;
    text-align: center;

    @include mq(desktop) {
      max-inline-size: rem(500px);
    }

    @include mq(wide) {
      max-inline-size: rem(780px);
    }
  }

  .FormTextbox,
  .FormSelection {
    @include mq(tablet) {
      inline-size: calc(50% - #{rem(10px)});
    }

    input,
    select {
      block-size: rem(50px);
      padding: rem(12px 53px 10px 20px);
    }
  }

  .FormSubmitButton {
    margin-block-start: rem(20px) !important;
  }

  .FormChoice,
  .FormParagraphText {
    margin-block-start: rem(20px) !important;

    @include mq(tablet) {
      inline-size: 100%;
    }
  }
}

.contact-us-form__top-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: 0;
      inset-inline-start: rem(60px);
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-inline-start: rem(80px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }

  .shape-3 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: rem(60px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(80px);
    }
  }
}

.contact-us-form__bottom-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(40px);
      inset-inline-end: rem(40px);
    }

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
      inset-block-end: rem(60px);
      inset-inline-end: rem(60px);
    }
  }
}
