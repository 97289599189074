.profile-table__container {
  table {
    border-collapse: collapse;
    inline-size: 100%;
  }

  table,
  th,
  td {
    border: 1px solid var(--black);
  }

  td {
    padding: 10px;
  }

  tbody {
    td {
      padding: 0 10px;
    }
  }
}

.profile-table__header {
  background-color: var(--crusta);
  color: var(--white);
  font-size: 16px;
  text-align: center;
}

.profile-table__check-cell {
  background-color: var(--back-blue);
  inset: 0;
  position: absolute;
  transition: all 200ms;

  svg {
    inline-size: 25px;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  circle {
    fill: var(--azure);
  }
}

.profile-table__check-cell--checked {
  background-color: var(--white);
}

.profile-table__row {
  transition: all 200ms;

  td {
    position: relative;
  }

  &:hover {
    background-color: var(--alto);

    .profile-table__check-cell {
      background-color: var(--alto);
    }
  }
}

.profile-table__diagonal {
  background: linear-gradient(to top right,
  #fff calc(50% - 1px),
  var(--black),
  var(--white) calc(50% + 1px));
  font-size: 15px;
  position: relative;
}

.profile-table__diagonal--first {
  inset-block-end: 2px;
  inset-inline-start: 2px;
  position: absolute;
}

.profile-table__diagonal--second {
  inset-block-start: 2px;
  inset-inline-end: 2px;
  position: absolute;
}
