.link {
  align-items: center;
  color: var(--azure);
  display: flex;
  font-weight: 500;
  text-decoration: none;

  @include active-state {
    text-decoration: underline;
  }

  svg {
    block-size: rem(20px);
    fill: var(--azure);
    inline-size: rem(20px);
    transition: transform 0.2s ease-in-out;
  }
}

.link--icon-right {
  @include active-state {
    svg {
      transform: translateX(rem(10px));
    }
  }

  svg {
    margin-inline-start: rem(10px);
  }
}

.link--icon-left {
  @include active-state {
    text-decoration: underline;
  }

  svg {
    margin-inline-end: rem(10px);
  }
}
