.spinner-wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  display: none;
  flex: 1;
  inset: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

.spinner {
  animation: spinner-c7wet2 1s infinite linear;
  background: radial-gradient(farthest-side, #049dd9 94%, #0000) top/9px 9px
    no-repeat,
    conic-gradient(#0000 30%, #049dd9);
  block-size: 56px;
  border-radius: 50%;
  inline-size: 56px;
  mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
