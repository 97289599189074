.search-results-listing__header {
  color: var(--white);
  display: flex;
  flex-flow: column;

  & .search-bar {
    margin-block-start: rem(-60px);

    @include mq(desktop) {
      margin-block-start: rem(-80px);
    }
  }
}

.search-results-listing__form {
  display: block;
  inline-size: 100%;
  position: relative;
}

.search-results-listing__submit {
  appearance: none;
  background-color: transparent;
  block-size: rem(20px);
  border: 0;
  cursor: pointer;
  inline-size: rem(20px);
  inset-block-start: 50%;
  inset-inline-end: rem(28px);
  padding: 0;
  position: absolute;
  transform: translateY(-50%);

  &:disabled {
    svg {
      block-size: 100%;
      fill: var(--alto);
      inline-size: 100%;
    }
  }

  svg {
    block-size: 100%;
    fill: var(--arapawa);
    inline-size: 100%;
  }
}

.search-results-listing__submit--disabled {
  color: var(--alto);
  cursor: not-allowed;
}

.search-results-listing__sort select {
  block-size: rem(50px);
  font-size: rem(16px);
  inline-size: 100%;
  line-height: 1.38;

  @include mq(desktop) {
    block-size: rem(40px);
    inline-size: unset;
    min-inline-size: rem(210px);
  }
}

.search-results-listing__sort {
  inline-size: 100%;

  @include mq(desktop) {
    inline-size: unset;
  }
}

.search-results-listing__info-and-sort {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-end: rem(40px);

  @include mq(desktop) {
    margin-block-end: rem(20px);
  }
}

.search-results-listing__results-info {
  display: none;

  @include mq(desktop) {
    color: var(--arapawa);
    display: block;
    font-weight: 500;
    line-height: 1.56;
  }
}

.search-results-listing__results-info-mobile {
  color: var(--arapawa);
  display: block;
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.56;
  margin-block-end: rem(20px);

  @include mq(desktop) {
    display: none;
  }
}

.search-results-listing__facets-and-results {
  margin-block-end: var(--module-spacing);
  margin-block-start: rem(40px);

  @include mq(desktop) {
    margin-block-start: rem(60px);
  }
}

.search-results-listing__no-results {
  inline-size: 100%;
  text-align: center;
}
