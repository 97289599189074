.content-blocks {
  margin-block: rem(20px);

  @include mq(desktop) {
    margin-block: rem(40px);
  }

  @include mq(wide) {
    margin-block: rem(60px);
  }

  > * {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: rem(20px);

    @include mq(desktop) {
      margin-block-start: rem(40px);
    }
  }

  i,
  em {
    color: var(--secondary-text-color);
    font-style: italic;
  }

  p > a:not(.btn) {
    display: inline;
  }

  iframe {
    display: block;
    inline-size: 100%;
    margin-block: rem(20px);
    margin-inline: auto;
    max-inline-size: 100%;
    min-inline-size: 100%;

    @include mq(desktop) {
      margin-block: rem(40px);
    }

    @include mq(wide) {
      margin-block: rem(80px);
    }

    * {
      inline-size: 100%;
    }
  }

  h1 {
    font-size: rem(32px);
    line-height: 1.13;

    @include mq(desktop) {
      font-size: rem(42px);
      line-height: 1.1;
    }

    @include mq(wide) {
      font-size: rem(45px);
      line-height: 1.09;
    }
  }
}
