/* stylelint-disable */
.product-comparison-table {
  margin-block: var(--module-spacing);
  margin-inline: auto;

  table.product-comparison-table__table {
    inline-size: 100%;
    table-layout: fixed;

    @include mq($until: desktop) {
      max-inline-size: 100% !important;
    }
  }

  thead {
    display: none;
    font-size: rem(14px);

    @include mq(desktop) {
      display: table-header-group;
      font-size: rem(16px);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      @include mq(desktop) {
        font-size: rem(29px);
      }
    }

    tr {
      width: auto !important;
      height: auto !important;

      th,
      td {
        border-inline-end: 1px solid var(--alto);
        width: auto !important;
        height: auto !important;

        & > *:not(img) {
          text-align: left;
          padding-inline: rem(30px);
        }
        & :nth-child(2),
        & :first-child:not(img) {
          padding-block-start: rem(20px);
        }
        & :last-child:not(img) {
          padding-block-end: rem(20px);
        }
      }
    }
    tr:nth-child(odd) {
      background-color: var(--white);
    }
    tr:nth-child(even) {
      background-color: var(--gallery);
    }
  }

  tbody {
    tr {
      height: auto !important;
      width: auto !important;
    }

    th,
    td {
      border-inline-end: 1px solid var(--alto);
      font-size: rem(14px);
      padding-block: rem(20px);
      padding-inline: rem(30px);
      text-align: center;
      vertical-align: middle;

      @include mq(desktop) {
        text-align: left;
        font-size: rem(16px);
      }

      ul {
        margin-inline-start: rem(25px);
      }

      li {
        margin-block: 0;
        padding-inline-start: rem(10px);
      }
      img {
        display: block;
        margin: auto;
      }
    }
    tr:nth-child(odd) {
      background-color: var(--gallery);
    }

    tr:nth-child(even) {
      background-color: var(--white);
    }

    tr:last-of-type {
      th {
        display: none;

        @include mq(desktop) {
          display: table-cell;
        }
      }
    }

    tr > th,
    tr > td {
      border-inline: 0;
      border-block-start: 1px solid var(--alto);
      width: auto !important;
      height: auto !important;
      display: block;

      @include mq(desktop) {
        border-inline-end: 1px solid var(--alto);
        display: table-cell;
      }
    }

    tr > th:first-child,
    tr > td:first-child {
      color: var(--arapawa);
      font-weight: bold;
      text-align: center;
      background-color: var(--gallery);
      width: auto !important;
      height: auto !important;

      @include mq(desktop) {
        text-align: left;
        background-color: unset;
      }
    }

    tr > th:not(first-child),
    tr > td:not(first-child) {
      background-color: var(--white);

      @include mq(desktop) {
        background-color: unset;
      }
    }

    .btn {
      inline-size: 100% !important;
      margin: 0;

      &:not(:last-child) {
        margin-block-end: rem(20px);
      }
    }
  }
}

.product-comparison-table__heading {
  margin: rem(0 auto 20px);
  max-inline-size: rem(778px);
  text-align: center;

  @include mq(desktop) {
    margin: rem(0 auto 40px);
  }
}

.product-comparison-select {
  margin-block-end: rem(20px);

  @include mq(desktop) {
    display: none;
  }
}
