.rtf-cta-block {
  background-color: var(--gallery);
  margin-block: rem(20px);
  padding-block: rem(40px);
  padding-inline: rem(40px);
  position: relative;

  @include mq(desktop) {
    margin-block: rem(40px);
    padding: rem(80px);
    padding-inline-end: rem(137px);
  }

  &::after {
    background-color: var(--arapawa);
    block-size: rem(20px);
    box-shadow: rem(-20px) 0 var(--arapawa),
      rem(-20px) rem(-20px) var(--arapawa), 0 rem(-20px) var(--arapawa),
      rem(20px) rem(-40px) var(--azure), 0 rem(-60px) var(--cyan);
    content: "";
    display: block;
    inline-size: rem(20px);
    inset-block-start: rem(60px);
    inset-inline-end: rem(20px);
    position: absolute;
    transition: all 0.2s ease-in-out;
    z-index: z("default");

    @include mq(desktop) {
      block-size: rem(40px);
      box-shadow: rem(-40px) 0 var(--arapawa),
        rem(-40px) rem(-40px) var(--arapawa), 0 rem(-40px) var(--arapawa),
        0 rem(-120px) var(--cyan), rem(40px) rem(-80px) var(--azure);
      inline-size: rem(40px);
      inset-block-end: 0;
      inset-block-start: unset;
      inset-inline-end: rem(40px);
    }
  }

  &::before {
    background-color: var(--crusta);
    block-size: rem(10px);
    content: "";
    display: block;
    inline-size: rem(10px);
    inset-block-start: rem(30px);
    inset-inline-end: rem(60px);
    position: absolute;
    transition: all 0.2s ease-in-out;
    z-index: z("default");

    @include mq(desktop) {
      block-size: rem(20px);
      inline-size: rem(20px);
      inset-block-end: rem(80px);
      inset-block-start: unset;
      inset-inline-end: rem(120px);
    }
  }

  .btn {
    margin-block-end: 0 !important;
    margin-block-start: rem(15px);

    @include mq(desktop) {
      margin-block-start: rem(30px);
    }
  }
}

.rtf-cta-block__heading {
  color: var(--arapawa);
  font-size: rem(21px);
  line-height: 1.29;
  margin-block-start: 0 !important;
  margin-inline-end: rem(30px);

  @include mq(desktop) {
    font-size: rem(30px);
  }

  @include mq(wide) {
    font-size: rem(36px);
    line-height: 1.28;
  }
}

.rtf-quote__author {
  color: var(--arapawa);
  font-weight: 500;
  margin-block-start: rem(20px);
}
