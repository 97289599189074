.primary-navigation {
  display: none;
  flex-grow: 1;

  @include mq(desktop) {
    align-self: stretch;
    display: block;
  }

  .megamenu__item-link,
  .megamenu__link-list-link {
    color: var(--arapawa);
    text-decoration: none;
  }

  .megamenu__item-link {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-block: rem(11px);
    padding-inline: rem(40px);

    @include active-state {
      background-color: var(--arapawa);
      color: var(--white);

      svg {
        fill: var(--white);
      }
    }

    svg {
      block-size: rem(12px);
      fill: var(--arapawa);
      inline-size: rem(7px);
      pointer-events: none;
    }

    &[aria-selected="true"] {
      background-color: var(--arapawa);
      color: var(--white);

      svg {
        fill: var(--white);
      }
    }
  }

  .megamenu__link-list-link {
    display: block;

    @include active-state {
      color: var(--azure);
    }
  }

  .megamenu__link-list {
    border-block-start: rem(1px) solid var(--alto);
    padding-inline: rem(40px);
  }

  .megamenu__link-list-item {
    font-weight: normal;
    margin-block: rem(30px);
  }

  .megamenu__item {
    color: var(--arapawa);
    cursor: pointer;
    font-weight: 500;
    padding-block: rem(10px);
    text-decoration: none;
  }

  .megamenu-link-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .megamenu-link-card__heading {
    font-style: italic;
    margin-block-end: rem(30px);
    min-block-size: rem(32px);
  }

  .megamenu-link-card__spacer {
    block-size: rem(62px);
  }

  .megamenu-link-card__links-list {
    display: flex;
    flex-direction: column;
    gap: rem(30px);
  }

  .megamenu-link-card__link-item-label {
    color: var(--arapawa);
    text-decoration: none;

    @include active-state {
      color: var(--azure);
    }
  }
}

.primary-navigation__items {
  background-color: var(--white);
  block-size: 100%;
  display: flex;
  flex-direction: row;
  inline-size: 100%;
  justify-content: flex-end;
}

.primary-navigation__item {
  max-inline-size: 25%;
}

.primary-navigation__megamenu {
  background-color: var(--white);
  border: rem(1px) solid var(--alto);
  display: flex;
  inset-inline: var(--container-padding);
  margin-block-start: 0;
  max-block-size: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  z-index: z("header");

  .primary-navigation__item--is-open & {
    max-block-size: 1000px;
    opacity: 1;
    visibility: visible;
  }
}

.primary-navigation__megamenu-left {
  border-inline-end: rem(1px) solid var(--alto);
  flex: 0 1 25%;
  margin-inline-end: rem(-1px);
  padding-block: rem(50px);
}

.primary-navigation__megamenu-right {
  flex: 1 1 75%;
  padding-block: rem(50px);
  padding-inline: rem(40px);
}

.primary-navigation__megamenu-cards {
  display: flex;
  gap: rem(32px);
  transition: all 100ms ease-in-out;

  & > * {
    flex-basis: calc(33.3% - #{rem(32px)});
  }

  &:not(.primary-navigation__megamenu-cards--default) {
    display: none;
  }
}

.primary-navigation__item {
  @include active-state {
    .primary-navigation__link {
      border-color: var(--arapawa);
    }

    .primary-navigation__megamenu {
      display: flex;
    }
  }
}

.primary-navigation__link {
  align-items: center;
  block-size: 100%;
  border: 0;
  color: var(--arapawa);
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding-block: rem(23px);
  padding-inline: clamp(30px, 0.015vw, 80px);
  position: relative;
  text-align: center;
  text-decoration: none;

  @include mq(desktop) {
    justify-content: space-around;
  }

  @include mq(wide) {
    padding-inline: clamp(20px, 1.75vw, 120px);
  }

  @include active-state {
    &::after {
      background-color: var(--arapawa);
    }
  }

  .primary-navigation__item[aria-current="page"]:not(.primary-navigation__current--no-border)
  &,
  .primary-navigation__item--is-open & {
    &::after {
      background-color: var(--arapawa);
    }
  }

  &::after {
    background-color: transparent;
    block-size: rem(3px);
    content: "";
    inline-size: calc(100% - clamp(30px, 0.015vw, 80px));
    inset-block-end: 0;
    position: absolute;

    @include mq(wide) {
      inline-size: calc(100% - clamp(20px, 1.75vw, 120px));
    }
  }
}

.primary-navigation__megamenu-title {
  color: var(--arapawa);
  font-size: rem(21px);
  font-weight: 500;
  line-height: 1.19;
  margin-block-end: rem(20px);
  padding-inline: rem(40px);

  @include mq(desktop) {
    font-size: rem(24px);
    line-height: 1.17;
  }

  @include mq(wide) {
    font-size: rem(26px);
    line-height: 1.23;
  }
}

.primary-navigation__megamenu-title--link {
  display: block;
  text-decoration: none;

  @include active-state {
    color: var(--azure);
  }
}
