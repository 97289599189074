.recommended-content {
  padding-block: var(--module-spacing);

  @include mq(desktop) {
    padding-block: 38px;
  }
}

.recommended-content__heading-container {
  overflow: hidden;
}

.recommended-content__items {
  inline-size: 100%;
  inset-block-start: 100%;
  inset-inline-start: 0;
  position: relative;
}

.recommended-content__items-inner {
  align-items: flex-start;
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(4, 1fr);
  margin-block-start: rem(23px);

  /* stylelint-disable-next-line plugin/stylelint-bem-namics */
  .epi-editContainer {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    inline-size: 100%;

    /* stylelint-disable-next-line plugin/stylelint-bem-namics */
    [data-contentgroup] {
      flex: 1 1 0;
    }
  }
}

.recommended-content__item {
  align-items: center;
  block-size: 100%;
  border: solid 1px var(--gray);
  border-radius: rem(20px);
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  inline-size: 100%;
  padding: rem(28px) rem(30px);
  position: relative;
  text-align: center;

  img {
    aspect-ratio: 1;
    display: block;
    max-block-size: 100px;
    object-fit: contain;
  }

  div {
    color: var(--arapawa);
    font-family: var(--primary-font);
    font-size: rem(20px);
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-block-start: rem(20px);
  }
  /* stylelint-disable */

  p {
    -webkit-box-orient: vertical;
    color: var(--arapawa);
    display: -webkit-box;
    font-family: var(--primary-font);
    font-size: rem(20px);
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    -webkit-line-clamp: 3;
    line-height: normal;
    margin-block: rem(10px);
    overflow: hidden;
    text-align: center;
  }

  a {
    color: var(--azure);
    font-family: var(--primary-font);
    font-size: rem(20px);
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: none;
  }
}

.pentagon__wrapper {
  align-items: center;
  background-color: var(--azure);
  block-size: 58px;
  clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
  display: flex;
  inline-size: 50px;
  inset-block-start: -1px;
  inset-inline-end: 15px;
  justify-content: center;
  margin-block-start: 0;
  position: absolute;

  svg {
    block-size: 30px;
    inline-size: 30px;
  }
}
