/* stylelint-disable */
#onetrust-consent-sdk #onetrust-policy-title {
  font-size: rem(24px) !important;
  font-weight: 500 !important;
  line-height: 1.42 !important;
  color: var(--arapawa) !important;
}

#onetrust-consent-sdk div {
  &:focus {
    opacity: 1 !important;
  }
}

#onetrust-consent-sdk #onetrust-group-container {
  display: flex !important;
  align-items: center !important;
  width: 100%;
}

#onetrust-banner-sdk {
  font-size: rem(22px);
  color: var(--secondary-text-color);
  box-shadow: none;
  padding-block: rem(30px);
  padding-inline: var(--container-padding);

  &.focus-visible {
    outline: solid rem(1px) var(--azure) !important;
  }
  &:focus {
    outline: none !important;
  }

  p#onetrust-policy-text {
    margin-bottom: 0;
    font-size: rem(18px);
    line-height: 1.56;
    color: var(--secondary-text-color);
    font-weight: normal;

    @include mq(desktop) {
      margin-bottom: rem(20px);
    }
  }

  div.ot-sdk-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(20px);

    &::after {
      content: unset !important;
      clear: unset !important;
      display: unset !important;
    }

    @include mq(desktop) {
      flex-direction: row;
    }
  }

  div#onetrust-button-group {
    display: flex;
    flex-direction: column;
    max-width: rem(240px);
    gap: rem(10px);
    margin-top: 0 !important;

    & > * {
      margin-bottom: 0 !important;
      min-inline-size: rem(240px);
    }

    button {
      border-radius: 0;
      block-size: rem(40px);
      padding: 0;
      font-size: rem(16px);
      font-weight: 500;
      line-height: 1.5;
      border: rem(1px) solid var(--azure);
      margin-top: 0 !important;
      margin-right: 0 !important;

      &:focus {
        outline: none !important;
      }
    }

    button#onetrust-accept-btn-handler {
      background-color: var(--azure);
      color: var(--white);

      @include active-state {
        opacity: 1;
        color: var(--azure) !important;
      }
    }

    button#onetrust-reject-all-handler {
      background-color: transparent;
      color: var(--azure);
      border-color: var(--azure);
      order: 3;

      @include active-state {
        opacity: 1;
        color: var(--white) !important;
      }
    }

    button#onetrust-pc-btn-handler {
      background-color: transparent;
      color: var(--azure);
      border-color: var(--azure);
      order: 2;

      @include active-state {
        opacity: 1;
        color: var(--white) !important;
      }
    }
  }

  div#onetrust-close-btn-container {
    display: none;
  }

  div#onetrust-policy {
    margin: 0 !important;
  }
}

#onetrust-banner-sdk:not(.ot-iab-2).vertical-align-content
  #onetrust-button-group-parent {
  position: relative !important;
  left: unset !important;
  transform: none;
  flex-shrink: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
  width: auto !important;
}

.onetrust-pc-dark-filter {
  background: var(--white) !important;
  opacity: 0.8;
}

#onetrust-pc-sdk {
  box-shadow: 0 3px 20px 0 var(--alto);

  .ot-pc-logo {
    display: none !important;
  }

  .ot-pc-header {
    border: 0 !important;
  }

  .ot-pc-footer-logo {
    display: none !important;
  }

  .ot-pc-footer {
    border-top: 0 !important;
  }

  button {
    letter-spacing: normal !important;
    font-weight: 500 !important;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-category-title {
  color: var(--arapawa) !important;
  font-size: rem(24px);
  line-height: 1.42;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title {
  margin-bottom: rem(20px);
}

#onetrust-consent-sdk #onetrust-pc-sdk .privacy-notice-link {
  text-decoration: none !important;
  color: var(--azure) !important;
  margin-block-start: rem(20px);
  display: inline-block;

  @include active-state {
    text-decoration: underline !important;
  }

  &:focus {
    outline: none !important;
  }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc {
  font-size: rem(18px);
  line-height: 1.56;
  font-weight: normal;
  color: var(--secondary-text-color) !important;
}

#onetrust-pc-sdk.otPcCenter {
  max-width: 90% !important;
  min-width: 90% !important;
  height: calc(100vh - 80px) !important;

  @include mq(desktop) {
    max-width: 60% !important;
    min-width: 60% !important;
  }
}

#onetrust-pc-sdk #ot-pc-content {
  overflow-y: auto !important;
}

#onetrust-banner-sdk :not(.ot-leg-btn-container) > button:focus {
  opacity: 1 !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-btn-container button {
  background-color: var(--azure) !important;
  color: var(--white) !important;
  block-size: rem(50px);
  border-radius: 0;
  order: 3;
  font-size: rem(16px);
  font-weight: normal;

  @include active-state {
    opacity: 1;
    color: var(--azure) !important;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-close-icon {
  background-size: rem(16px);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M3.154 2.499c-.265 0-.504.16-.604.406-.1.246-.04.527.15.712l6.379 6.38-6.38 6.38c-.17.164-.237.407-.178.635.06.228.238.406.466.465.228.06.47-.008.634-.178L10 10.919l6.38 6.38c.163.17.405.238.633.178.228-.06.406-.237.466-.465.06-.228-.009-.47-.179-.634l-6.379-6.38 6.38-6.38c.192-.188.25-.474.145-.722-.104-.247-.35-.405-.619-.397-.17.005-.33.076-.448.197L10 9.076l-6.38-6.38c-.122-.126-.29-.197-.466-.197z' fill='%230e1d77' stroke='%230e1d77' stroke-width='1' transform='translate(-1740 -140) translate(0 110) translate(1740 30)'/%3E%3C/svg%3E");

  @include active-state {
    opacity: 1 !important;
  }

  &:focus {
    outline: none !important;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk button#accept-recommended-btn-handler {
  background-color: var(--azure) !important;
  color: var(--white) !important;
  block-size: rem(50px);
  border-radius: 0;
  border-color: var(--azure) !important;
  order: 3;
  font-size: rem(16px);
  min-inline-size: rem(131px);

  @include active-state {
    opacity: 1;
    color: var(--azure) !important;
  }

  &:focus {
    opacity: 1;
    outline: none !important;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk h4 {
  font-size: rem(18px);
  line-height: 1.56;
  font-weight: 500;
  color: var(--arapawa) !important;
}

#onetrust-pc-sdk .ot-accordion-layout h4 ~ .ot-always-active {
  font-size: rem(18px);
  line-height: 1.22;
  font-weight: normal;
  font-style: italic;
  color: var(--secondary-text-color) !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk p {
  color: var(--secondary-text-color) !important;
  font-size: rem(18px);
  line-height: 1.56;
  font-weight: normal;
}

#onetrust-consent-sdk #onetrust-pc-sdk :focus,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-tgl input:focus + .ot-switch {
  outline: none !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt {
  background-color: var(--white) !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
  background-color: var(--arapawa) !important;
  border-color: var(--arapawa) !important;
  transform: translateX(40px) !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  background-color: var(--white) !important;
  border: 1px solid var(--arapawa) !important;
}
#onetrust-pc-sdk .ot-switch-nob::before {
  width: 30px !important;
  height: 30px !important;
  bottom: 4px !important;
  left: 4px !important;
}

#onetrust-pc-sdk .ot-switch {
  height: 40px !important;
  width: 80px !important;
}

#onetrust-pc-sdk .ot-switch-nob::before {
  background-color: var(--tundora) !important;
}

#onetrust-pc-sdk .ot-switch-nob {
  background-color: var(--gallery) !important;
  border: 1px solid var(--alto) !important;
}

#onetrust-banner-sdk div#onetrust-button-group {
  max-width: none !important;
}

#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group {
  width: 100% !important;
}

.ot-btn-container button {
  letter-spacing: normal !important;
  font-weight: 500 !important;
}

#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
  color: var(--secondary-text-color) !important;
  font-weight: normal !important;
  font-style: italic !important;
  line-height: 1.22 !important;
  font-size: rem(18px) !important;
  letter-spacing: normal !important;
  text-align: right !important;
}

#onetrust-pc-sdk .ot-cat-item {
  p:first-of-type {
    margin-block-start: rem(20px) !important;
  }

  p:last-of-type,
  & > :last-child {
    margin-block-end: rem(40px) !important;
  }
}

#onetrust-pc-sdk .ot-tgl + p.ot-category-desc {
  margin-block-start: rem(10px) !important;
}

#onetrust-pc-sdk .ot-tgl {
  transform: translateY(rem(-5px)) !important;
  block-size: 40px !important;
  inline-size: 80px !important;
}

#onetrust-pc-sdk #ot-pc-content button,
#onetrust-pc-sdk .ot-pc-footer button,
#onetrust-banner-sdk #onetrust-accept-btn-handler {
  background-color: var(--azure);
  position: relative !important;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  z-index: z("default");
  border: 0 !important;
  min-inline-size: rem(200px) !important;

  @include active-state {
    color: var(--azure) !important;
    outline-offset: rem(4px);

    &::after {
      transform: scale(1);
    }
  }

  &.focus-visible {
    outline-offset: rem(4px);
  }

  &::after {
    background-color: var(--white);
    content: "";
    block-size: calc(100% - #{rem(2px)});
    inline-size: calc(100% - #{rem(2px)});
    inset-block-end: rem(1px);
    inset-inline-start: rem(1px);
    position: absolute;
    transform: scale(0);
    transform-origin: 0 100%;
    transition: transform 0.2s ease-in-out;
    z-index: z("below") !important;
  }
}
#onetrust-banner-sdk #onetrust-pc-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  background-color: transparent;
  border: solid rem(1px) var(--azure);
  position: relative !important;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  z-index: z("default");
  min-inline-size: rem(200px) !important;

  @include active-state {
    color: var(--azure) !important;
    outline-offset: rem(4px);

    &::after {
      transform: scale(1);
    }
  }

  &.focus-visible {
    outline-offset: rem(4px);
  }

  &::after {
    background-color: var(--azure);
    block-size: calc(100% + #{rem(2px)});
    inline-size: calc(100% + #{rem(2px)});
    inset-block-end: rem(-1px);
    inset-inline-start: rem(-1px);
    content: "";
    position: absolute;
    transform: scale(0);
    transform-origin: 0 100%;
    transition: transform 0.2s ease-in-out;
    z-index: z("below") !important;
  }
}
/* stylelint-enable */
