/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable plugin/stylelint-bem-namics */

.driver-tab-content__download {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  justify-content: space-between;
  padding-block: rem(20px);
  padding-inline: rem(20px);

  @include mq(tablet) {
    flex-direction: row;
    gap: rem(40px);
    padding-block: rem(40px);
    padding-inline: rem(40px);
  }

  @include mq(desktop) {
    gap: 10%;
    padding-block: rem(40px);
    padding-inline-end: rem(140px);
    padding-inline-start: rem(90px);
  }

  .EPiServerForms {
    background-color: var(--white);
    padding: 0;
  }

  .FormStep {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-block-start: 0 !important;

    @include mq(tablet) {
      flex-direction: row;
    }
  }

  .FormTextbox,
  .FormSelection {
    inline-size: 100%;

    @include mq(desktop) {
      inline-size: calc(50% - 0.625rem);
    }

    input,
    select {
      block-size: rem(50px);
    }
  }

  .driver-download__form-column {
    flex-grow: 1;
  }

  .driver-download__heading {
    font-size: rem(18px);
    line-height: 1.33;

    @include mq(desktop) {
      font-size: rem(24px);
      line-height: 1.42;
    }

    &.driver-download__form-heading {
      margin-block-end: 0;
    }

    & + .EPiServerForms {
      margin-block-start: rem(-10px) !important;
    }
  }

  .driver-download__subheading {
    margin-block-end: rem(10px);

    @include mq(tablet) {
      margin-block-end: rem(20px);
    }

    @include mq(desktop) {
      margin-block-end: rem(30px);
    }

    &.driver-download__form-subheading {
      margin-block-end: 0;
      margin-block-start: rem(5px);

      @include mq(tablet) {
        margin-block-start: rem(10px);
      }

      @include mq(desktop) {
        margin-block-start: rem(15px);
      }
    }

    & + .EPiServerForms {
      margin-block-start: rem(-10px) !important;
    }
  }

  .driver-information {
    flex-shrink: 0;
  }

  .driver-information__heading {
    font-size: rem(18px);
    line-height: 1.33;

    @include mq(desktop) {
      font-size: rem(24px);
      line-height: 1.42;
    }
  }

  .driver-information__details {
    font-size: rem(16px);
    line-height: 1.57;

    @include mq(desktop) {
      font-size: rem(18px);
      line-height: 1.56;
    }
  }

  .driver-information__item {
    column-gap: rem(10px);
    display: flex;
  }

  .driver-information__installation-guide {
    margin-block-start: rem(20px);
  }

  .driver-information__installation-guide-link {
    align-items: center;
    color: var(--azure);
    display: flex;
    font-size: rem(16px);
    gap: rem(10px);
    line-height: 1.38;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    @include mq(desktop) {
      font-size: rem(18px);
      line-height: 1.56;
    }

    @include active-state {
      color: var(--arapawa);

      svg {
        fill: var(--arapawa);
      }
    }

    svg {
      block-size: rem(20px);
      display: inline-block;
      fill: var(--azure);
      inline-size: rem(20px);
      transition: fill 0.2s ease-in-out;
    }
  }

  .driver-information__installation-secondary-label {
    font-size: rem(14px);
    line-height: 1.57;
    margin-inline-start: rem(30px);
  }
}

.driver-tab-content__supported-printers {
  padding-block: rem(40px);

  .driver-supported-printers__display-keys {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    justify-content: center;
    margin-block-end: rem(20px);
    margin-inline: auto;
    padding-inline: rem(20px);

    @include mq(tabletLandscape) {
      align-items: center;
    }

    @include mq(desktop) {
      flex-direction: row;
      gap: rem(60px);
      justify-content: center;
      max-inline-size: rem(800px);
    }

    @include mq(wide) {
      margin-block-start: rem(50px);
      max-inline-size: rem(920px);
    }
  }

  .driver-supported-printers__display-key-item {
    align-items: center;
    display: flex;
    font-size: rem(14px);
    gap: rem(15px);
    line-height: 1.57;

    svg {
      max-block-size: rem(20px);
      max-inline-size: rem(20px);
    }
  }

  .driver-supported-printers__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: minmax(60px, max-content);
    padding-inline: rem(20px);

    @include mq(tabletLandscape) {
      column-gap: rem(20px);
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      padding-inline: rem(60px);
    }

    @include mq(desktop) {
      column-gap: rem(40px);
      grid-template-columns: repeat(3, 1fr);
      padding-inline: rem(90px);
    }

    @include mq(wide) {
      padding-inline: rem(190px);
    }
  }

  .driver-supported-printers__printer {
    padding-block: rem(20px);
  }

  .driver-supported-printers__printer-name {
    font-size: rem(16px);
    margin-inline-end: rem(20px);
  }

  .driver-supported-printers__printer-icons {
    align-items: center;
    max-block-size: rem(11px);

    svg {
      color: var(--tundora);
      display: inline-block;
      fill: var(--tundora);
      max-block-size: rem(14px);
      max-inline-size: rem(14px);
    }

    svg:not(:last-child) {
      margin-inline-end: rem(10px);
    }
  }
}

.driver-tab-content__release-notes {
  display: flex;
  flex-direction: column;
  padding-inline: rem(20px);

  @include mq(tabletLandscape) {
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    padding-inline: rem(50px);
  }

  @include mq(desktop) {
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    padding-inline: rem(100px);
  }

  @include mq(wide) {
    padding-inline: rem(200px);
  }

  .driver-release-note__card {
    padding-block: rem(20px);

    @include mq(desktop) {
      display: flex;
      gap: rem(40px);
      padding-block: rem(60px);
      padding-inline: rem(40px);
    }

    &:not(:first-child) {
      border-block: solid rem(1px) var(--alto);
    }

    &:last-child {
      border-block-end: 0;
    }
  }

  .driver-release-note__heading {
    font-size: rem(18px);
    font-weight: 500;
    line-height: 1.56;

    @include mq(desktop) {
      flex-basis: 25%;
      flex-grow: 0;
    }
  }

  .driver-release-note__heading {
    flex-basis: 25%;
    font-size: rem(18px);
    font-weight: 500;
    line-height: 1.56;
  }
}
