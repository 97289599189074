.content-side-navigation {
  background-color: transparent;
  inline-size: 100%;
  margin-block: 20px;
  padding: 0;

  @include mq(desktop) {
    background-color: var(--gallery);
    inset-block-start: rem(40px);
    margin-block: 0;
    padding-block: rem(40px);
    padding-inline: rem(40px);
    position: sticky;
  }

  &::after {
    display: none;

    @include mq(desktop) {
      background-color: var(--royal-blue);
      block-size: rem(30px);
      box-shadow: rem(-30px) rem(-30px) var(--cyan);
      content: "";
      display: block;
      inline-size: rem(30px);
      inset-block-start: rem(30px);
      inset-inline-end: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      z-index: z("default");
    }
  }

  &::before {
    display: none;

    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(40px);
      content: "";
      display: block;
      inline-size: rem(40px);
      inset-block-end: 0;
      inset-inline-end: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      z-index: z("default");
    }
  }
}

.content-side-navigation__nav {
  display: none;

  @include mq(desktop) {
    display: block;
  }
}

.content-side-navigation__nav-list--secondary {
  li {
    line-height: 1.57;
    margin-block: rem(10px);
    margin-inline-start: rem(20px);
  }
}

.content-side-navigation__page-heading {
  margin-block: rem(20px);

  @include active-state {
    .content-side-navigation__page-heading-link {
      color: var(--arapawa);
    }
  }

  &[aria-current="page"] {
    .content-side-navigation__page-heading-link {
      color: var(--arapawa);
      margin-inline-start: 5px;
    }
  }
}

.content-side-navigation__page-heading * {
  position: relative;
}

.content-side-navigation__page-heading-wrapper {
  align-items: flex-start;
  display: flex;
}

.content-side-navigation__page-heading-link {
  color: var(--azure);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  text-decoration: none;
}

.content-side-navigation__page-heading-marker {
  background-color: var(--arapawa);
  block-size: 10px;
  display: block;
  flex-shrink: 0;
  inline-size: 10px;
  margin-block-start: 0.35em;
  transition: all 0.2s ease-in-out;
}

.content-side-navigation__content-block-link {
  color: var(--azure);
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  @include active-state {
    color: var(--arapawa);
    font-weight: 500;
  }

  &.active {
    color: var(--arapawa);
    font-weight: 500;
  }
}

.content-side-navigation__select {
  appearance: none;
  background-color: var(--white);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTAgNiI+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGZpbGw9IiM0NTRENTEiPgogICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik04LjMxNSA0LjQ1TDQuNDg0IDAgMy4xODggMS4wOTkgNi41NDYgNSAzLjE4OCA4LjkwMSA0LjQ4NCAxMCA4LjMxNSA1LjU1IDguNzg3IDV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTczNSAtODU3KSB0cmFuc2xhdGUoMTUxMCA4NDApIHRyYW5zbGF0ZSgyMjQgMTUpIHJvdGF0ZSg5MCA1Ljk4NyA1KSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: 1px solid var(--alto);
  color: var(--tundora);
  font-family: var(--primary-font);
  font-size: rem(16px);
  inline-size: 100%;
  padding: 20px;

  @include mq(desktop) {
    display: none;
  }
}
