.header-search {
  @include mq(desktop) {
    margin-inline-start: rem(40px);
  }

  @include mq(wide) {
    margin-inline-start: rem(60px);
  }
}

.header-search__form {
  background-color: var(--white);
  display: none;
  inset-block-start: 100%;
  inset-inline: 0;
  position: absolute;
  z-index: z("default");

  @include mq(desktop) {
    inset-inline-end: var(--container-padding);
  }

  .header-search--is-open & {
    display: flex;
  }

  input {
    background-color: var(--white);
    block-size: rem(48px);
    border: rem(1px) solid var(--alto);
    font-size: rem(18px);
    inline-size: 100%;
    line-height: 1.56;
    padding-inline: rem(28px);

    &::placeholder {
      color: #b3b3b0;
    }
  }
}

.header-search__trigger {
  align-items: center;
  appearance: none;
  background-color: transparent;
  display: flex;
  padding: 0;

  &:disabled {
    svg {
      fill: var(--alto);
    }
  }

  svg {
    block-size: rem(20px);
    fill: var(--arapawa);
    inline-size: rem(20px);
  }
}

.header-search__icon--close {
  display: none;

  .header-search--is-open & {
    display: block;
  }
}

.header-search__icon--search {
  display: block;

  .header-search--is-open & {
    display: none;
  }
}

.header-search__actions {
  align-items: center;
  display: flex;
  gap: rem(10px);
  inset-block-start: 50%;
  inset-inline-end: rem(28px);
  padding: 0;
  position: absolute;
  transform: translateY(-50%);
}

.header-search__clear-btn {
  appearance: none;
  background-color: transparent;
  color: var(--azure);
  cursor: pointer;
  display: none;
  font-size: rem(16px);
  text-decoration: none;

  @include active-state {
    text-decoration: underline;
  }
}

.header-search__btnheader-search__search-btn {
  appearance: none;
  background-color: transparent;
  block-size: rem(21px);
  border: 0;
  cursor: pointer;
  inline-size: rem(21px);
  padding: 0;

  &:disabled {
    svg {
      fill: var(--alto);
    }
  }

  svg {
    block-size: 100%;
    fill: var(--arapawa);
    inline-size: 100%;
  }

  &:disabled {
    color: var(--alto);
    cursor: not-allowed;
  }
}

.header-search__form {
  border-radius: 0 0 13px 13px;
  box-shadow: 0 0 20px 1px var(--alto);
  flex-direction: column;
  z-index: -1;
}

.header-search__input-container {
  border: 1px solid var(--alto);
  padding: 10px;
  position: relative;
}

.header-search__lists {
  border: 1px solid var(--alto);
  border-block-start: 0;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: row;
  padding: 20px 32px 33px;
}

.header-search__searched-terms {
  display: flex;
  flex-direction: column;
  inline-size: 60%;
  padding-inline-end: 20px;
  position: relative;

  h6 {
    font-size: rem(18px);
    font-weight: bold;
    line-height: 1.56;
  }
}

.header-search__popular-resources {
  border-inline-start: solid 2px var(--alto);
  max-inline-size: 40%;
  padding-inline-start: 20px;

  h6 {
    font-size: rem(18px);
    font-weight: bold;
    line-height: 1.56;
  }

  a {
    font-weight: bold;
  }
}

.header-search__term-list {
  flex: 1;
  font-size: rem(16px);

  a {
    -webkit-box-orient: vertical;
    color: var(--azure);
    display: box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-decoration: none;
  }

  em {
    font-weight: bold;
  }

  p {
    color: var(--azure);

    a {
      color: var(--black);
    }
  }
}

.header-search__btn {
  margin-block-start: rem(8px);
  position: relative;

  &.search-btn__hidden {
    display: none;
  }
}

.header-search__autocomplete {
  background-color: var(--white);
  border: 0.0625rem solid var(--alto);
  border-radius: 13px;
  margin-block-start: rem(10px);
  overflow: hidden;
  padding: rem(15px);
  z-index: 1000;

  div {
    background-color: var(--white);
    padding: 0 rem(15px);
  }

  .selected {
    background-color: var(--azure);
    color: var(--white);
  }
}

.header-search-icon__input-search {
  block-size: rem(20px);
  inline-size: rem(20px);
  inset-block-start: 50%;
  inset-inline-end: rem(40px);
  position: absolute;
  transform: translateY(-50%);
}
