/* stylelint-disable plugin/stylelint-bem-namics */
.form-masthead {
  padding-block-start: var(--module-spacing);

  @include mq(desktop) {
    margin-block-end: rem(60px);
    padding-block-end: rem(86px);

    &[data-background="gray"] {
      margin-block-end: 0;
      padding-block-end: calc(#{rem(86px)} + #{rem(60px)});
    }
  }

  .container {
    @include mq(desktop) {
      align-items: center;
      display: flex;
      gap: var(--card-spacing);
      position: relative;

      &::after {
        background-color: var(--alto);
        block-size: rem(1px);
        content: "";
        inline-size: calc(100% - calc(var(--container-padding) * 2));
        inset-block-end: calc(#{rem(86px)} * -1);
        inset-inline-start: var(--container-padding);
        position: absolute;
      }
    }
  }

  &[data-background="gray"] {
    .EPiServerForms {
      background-color: var(--white);
    }
  }
}

.form-masthead__icons {
  margin-block-start: rem(30px);
  margin-inline-end: rem(10px);
  margin-inline-start: rem(20px);

  @include mq(desktop) {
    margin-block-start: rem(40px);
    margin-inline: 0;
  }

  .icon-card {
    & + .icon-card {
      margin-block-start: rem(30px);

      @include mq(desktop) {
        margin-block-start: rem(40px);
      }
    }
  }

  .icon-card__image {
    block-size: rem(60px);
    inline-size: rem(60px);
  }

  .icon-card__inner {
    align-items: center;
    flex-direction: row;
  }

  .icon-card__heading {
    margin-block-start: 0;
    margin-inline-start: rem(30px);
    text-align: start;

    @include mq(desktop) {
      margin-inline-start: rem(40px);
    }
  }
}

.form-masthead__content {
  @include mq(desktop) {
    flex-grow: 1;
  }
}

.form-masthead__form {
  @include mq($until: desktop) {
    margin-block-start: rem(40px);
    margin-inline: calc(var(--container-padding) * -1);
  }

  @include mq(desktop) {
    flex-shrink: 0;
    inline-size: calc(50% - calc(var(--card-spacing) / 2));
  }

  .EPiServerForms {
    padding: rem(40px 20px);

    @include mq(desktop) {
      padding: rem(60px);
    }
  }

  .FormStep {
    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .Form__Element {
    margin-block-start: rem(20px) !important;
  }

  .Form__Title {
    font-size: rem(24px);
    line-height: 1.17;
    text-align: center;

    @include mq(desktop) {
      font-size: rem(32px);
      line-height: 1.13;
    }
  }

  .FormTextbox,
  .FormSelection {
    @include mq(tablet) {
      inline-size: calc(50% - #{rem(10px)});
    }

    input,
    select {
      block-size: rem(50px);
      padding: rem(12px 53px 10px 20px);
    }
  }
}

.form-masthead__copy.rtf {
  line-height: 1.56;
}
