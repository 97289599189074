/* stylelint-disable plugin/stylelint-bem-namics */

.dashboard-kiosk {
  --height: 40px;
  block-size: 100vh;
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding-block-start: rem(var(--height));
  position: relative;

  &::before,
  &::after {
    background-color: var(--dark-gray);
    block-size: var(--height);
    content: "";
    inline-size: 100%;
    position: absolute;
  }

  &::before {
    background-color: var(--dark-gray);
    inset-block-start: 0;
  }

  &::after {
    background-color: #f2f2f2;
    inset-block-end: 0;
  }

  header {
    align-items: center;
    border-block-end: 1px solid #979797;
    display: flex;
    justify-content: space-between;
    margin-block: rem(25px);
    padding-block-end: rem(25px);

    h1 {
      font-size: 42px;
      margin-block-end: 0;
    }
  }

  nav {
    background-color: var(--back-blue);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-block-start: rem(35px);
    padding-inline: rem(25px);
    position: relative;

    > img {
      margin-block-end: 35px;
    }

    .btn--outline {
      background-color: var(--white);
    }
  }

  .dashboard-kiosk__picture {
    align-items: center;
    background-color: var(--dark-gray);
    block-size: var(--height);
    inline-size: 100%;
    inset-block-end: 0;
    inset-inline-start: 0;
    justify-content: center;
    position: absolute;
    z-index: 1;

    img {
      block-size: 24px;
      inline-size: 132px;
    }
  }

  > section {
    display: flex;
    flex-direction: column;
    margin-block-end: var(--height);
    overflow: auto;
    padding-inline: rem(42px);
  }

  .btn--appointment {
    align-items: center;
    block-size: 47px;
    display: flex;
    gap: 5px;
    inline-size: 282px;
    justify-content: center;

    .location__results-icon {
      inset-block-start: unset;
      inset-inline-end: unset;
      position: relative;
    }

    svg {
      fill: var(--white);
    }

    &:hover {
      svg {
        fill: var(--azure);
      }
    }
  }

  .btn--add-appointment {
    display: none;
  }

  .your-appointments {
    padding-inline: 0;

    .your-appointments__card {
      min-inline-size: 350px;
    }

    .btn--record {
      display: none;
    }
  }

  .your-appointments__grid {
    margin-block-start: 20px;
    overflow: auto;
    padding-block-end: 20px;
  }

  .recommended-content {
    .container {
      max-inline-size: 100%;
      padding-inline: 0;
    }
  }

  .your-appointments__location-details {
    inline-size: 75%;
  }

  .your-appointments__content {
    position: relative;
  }

  .check-in-status {
    color: var(--azure);
    display: block;
    font-size: rem(16px);
  }

  .your-appointments__ctas {
    align-items: center;
    inset-block-end: 15px;
    inset-inline-end: 25px;
    justify-content: space-between;
    margin-block-start: 15px;

    .btn--cancel {
      display: none;
    }
  }

  .check-in {
    display: block;
  }

  .your-appointments__header {
    margin-block-start: 0;
  }
}

.check-in-icon {
  block-size: 47px;
  display: none;
  fill: var(--azure);
  inline-size: 47px;
  stroke: var(--azure);

  &.checked-in {
    display: block;
  }

  .your-appointments__header h2,
  .recommended-content__heading {
    font-size: 30px;
    margin-block-end: 0;
  }

  .recommended-content__items-inner {
    gap: 25px;
  }

  .your-appointments__date-info {
    padding-block: 2px;
  }
}

.login-kiosk {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}

.dashboard-kiosk__login-form {
  box-shadow: 0 2px 11px 0 rgba(181, 178, 178, 0.12),
    0 4px 13px 0 rgba(203, 203, 203, 0.22);
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-inline-size: 800px;
  padding-block: 65px;
  padding-inline: 150px;

  h1 {
    font-size: 30px;
  }

  .Form__Element {
    margin-block-start: 0 !important;

    input {
      block-size: 50px;
    }
  }
}

.btn--record {
  align-items: center;
  block-size: 47px;
  display: flex;
  fill: var(--white);
  inline-size: 47px;
  justify-content: center;
  padding: 10px;

  svg {
    block-size: 100%;
    inline-size: 100%;
  }

  &:hover {
    svg {
      fill: var(--azure);
      stroke: var(--azure);
    }
  }
}

.btn__wrapper {
  display: flex;
  gap: 16px;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.9);
  display: none;
  inset: 0;
  position: fixed;
  z-index: 1;

  &.open {
    display: block;
  }
}

.record__container {
  background-color: var(--white);
  border: solid 1px #979797;
  display: flex;
  flex-direction: column;
  inline-size: 436px;
  inset-block: 48px 33px;
  inset-inline-end: 15px;
  overflow: auto;
  padding-block: 8px;
  padding-inline: 30px;
  position: absolute;

  form {
    margin-block-start: 65px;

    select {
      border-radius: 4px;
      padding: rem(0 0 0 15px);
    }
  }

  .Form__Element {
    margin-block-start: 0 !important;

    label {
      color: var(--arapawa);
      font-size: rem(12px);
    }
  }
}

.appointments__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block: 20px 70px;
  overflow: auto;
}

.appointment__item {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .status-date__wrapper {
    align-items: center;
    border-block-end: solid 1px #979797;
    border-block-start: solid 1px #979797;
    display: flex;
    gap: 20px;
    padding-block: 15px;
  }

  .status {
    background-color: var(--color);
    border-radius: 6px;
    color: var(--white);
    min-inline-size: 128px;
    padding-block: 10px;
    padding-inline: 20px;
  }

  .date {
    font-size: rem(18px);
    font-weight: bold;
    line-height: 1.56;
  }

  h3 {
    color: var(--azure);
    font-size: rem(20px);
    font-weight: bold;
    margin-block-end: 0;
  }

  .branch {
    font-size: rem(14px);
    font-weight: bold;
    line-height: 2;
  }

  address {
    font-size: rem(12px);
    line-height: 1.58;
  }
}

.appointments__status-code {
  background: #f2f2f2;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  gap: 5px;
  inset-block-end: 15px;
  inset-inline: 0;
  line-height: 2.8;
  margin-inline: 5px;
  padding-inline: 20px;
  position: absolute;

  .circle {
    background-color: var(--color);
    block-size: 10px;
    border-radius: 50%;
    display: inline-block;
    inline-size: 10px;
  }
}

.appointments__status-code-item {
  align-items: center;
  display: flex;
  gap: 5px;
}
