.search-result-card {
  background-color: transparent;
  border-block-start: solid rem(1px) var(--alto);
  display: flex;
  flex-flow: column;
  inline-size: 100%;
  padding-block: rem(20px);
  position: relative;

  @include mq(desktop) {
    padding-block: rem(40px);
  }

  &:last-child {
    border-block-end: solid rem(1px) var(--alto);
  }
}

.search-result-card__heading {
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(24px);
    font-weight: 500;
    line-height: 1.5;
  }

  a,
  a > * {
    color: var(--azure);
    display: flex;
    font-size: rem(18px);
    font-weight: 500;
    gap: rem(23px);
    line-height: 1.33;
    text-decoration: none;

    @include mq(desktop) {
      font-size: rem(24px);
      line-height: 1.42;
    }

    @include active-state {
      color: var(--azure);
    }
  }
}

.search-result-card__description {
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.search-result-card__tags {
  margin-block-start: rem(20px);

  @include mq(desktop) {
    margin-block-start: rem(45px);
  }
}

.search-result-card__conent-type {
  font-size: rem(14px);
  font-style: italic;
  margin-block-end: rem(10px);
}
