.featured-resource-cards {
  position: relative;

  &[data-background="white"] {
    .resource-image-card {
      border: solid rem(1px) var(--alto);

      &[data-background="gray"] {
        border: 0;
      }
    }

    &[data-module] {
      padding-block: var(--module-spacing);
    }

    &:not([data-module]) {
      margin-block: var(--module-spacing);
    }
  }

  &[data-background="gray"] {
    padding-block: var(--module-spacing);

    .resource-image-card {
      background-color: var(--white);
      border: 0;

      &[data-background="gray"] {
        background-color: var(--gallery);
        border: solid rem(1px) var(--alto);
      }
    }
  }

  &[data-background="cyan"] {
    background-color: var(--back-blue);
    padding-block: var(--module-spacing);

    .resource-image-card {
      position: relative;

      &::after {
        block-size: 5.0625rem;
        border-end-end-radius: 3.5rem;
        content: "";
        inline-size: 5.0625rem;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
      }

      &:nth-child(even) {
        &::after {
          background-color: var(--azure);
        }
      }

      &:nth-child(odd) {
        &::after {
          background-color: var(--crusta);
        }
      }
    }
  }
}

.featured-resource-cards__error-page-heading {
  color: var(--azure);
  padding-block-end: 3rem;
}

.featured-resource-cards__heading {
  margin: rem(0 auto 20px);
  max-inline-size: rem(778px);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(40px);
  }
}

.featured-resource-cards__top-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(70px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(160px);
      inline-size: rem(160px);
      inset-block-start: rem(110px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-start: rem(40px);
      inset-inline-start: rem(80px);
    }

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: rem(70px);
      inset-inline-start: rem(160px);
    }
  }
}

.featured-resource-cards__bottom-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: rem(120px);
      inset-inline-end: rem(40px);
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-end: rem(160px);
      inset-inline-end: rem(60px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: rem(80px);
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-end: rem(100px);
    }
  }

  .shape-3 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: 0;
      inset-inline-end: rem(40px);
    }

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
      inset-inline-end: rem(60px);
    }
  }
}

.featured-resource-cards-heading--text-left {
  margin-inline-start: 0;
  text-align: start;
}
