.media-masthead {
  padding-block: var(--module-spacing);
  position: relative;

  @include mq(desktop) {
    padding-block-end: 0;
    padding-block-start: 0;
  }

  &.media-masthead--with-logos {
    padding-block-end: 0;
  }

  .two-column-content-media__media {

    @include mq(wide) {
      inline-size: 50%;
    }

    img {
      border-end-start-radius: 0;
    }
  }

  .logo-carousel {
    @include mq(tablet) {
      block-size: rem(160px);

      img {
        block-size: rem(160px);
        inline-size: rem(160px);
      }
    }
  }
}

.media-masthead__subheading {
  color: #0e1d77;
  font-weight: 500;
  margin-block-end: rem(5px);

  @include mq(desktop) {
    margin-block-end: rem(10px);
  }
}

.media-masthead__buttons {
  margin-block-start: rem(10px);

  @include mq(desktop) {
    margin-block-start: rem(30px);
  }
}

.media-masthead--shapes-1 {
  &:not(.media-masthead--with-logos) {
    .media-masthead__shapes-container {
      .shape-1 {
        @include mq(desktop) {
          background-color: var(--azure);
          block-size: rem(80px);
          inline-size: rem(80px);
          inset-block-end: 0;
          inset-inline-start: rem(80px);
        }

        @include mq(wide) {
          inset-inline-start: rem(160px);
        }
      }
    }
  }

  .media-masthead--with-logos & {
    .shape-1 {
      @include mq(desktop) {
        background-color: var(--azure);
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-end: 0;
        inset-inline-end: 0;
      }

      @include mq(wide) {
        block-size: rem(180px);
        inline-size: rem(180px);
      }
    }
  }

  .media-masthead__image-shapes-container {
    .shape-1 {
      @include mq(desktop) {
        background-color: var(--arapawa);
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-start: 0;
        inset-inline-end: rem(-80px);
      }

      @include mq(wide) {
        block-size: rem(160px);
        inline-size: rem(160px);
        inset-inline-end: rem(-160px);
      }
    }

    .shape-2 {
      @include mq(desktop) {
        background-color: var(--crusta);
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-block-start: rem(80px);
        inset-inline-end: rem(-50px);
      }

      @include mq(wide) {
        block-size: rem(100px);
        inline-size: rem(100px);
        inset-block-start: rem(160px);
        inset-inline-end: rem(-100px);
      }
    }

    .shape-3 {
      @include mq(desktop) {
        background-color: var(--azure);
        block-size: rem(30px);
        inline-size: rem(30px);
        inset-block-start: rem(130px);
        inset-inline-end: rem(-80px);
      }

      @include mq(wide) {
        block-size: rem(60px);
        inline-size: rem(60px);
        inset-block-start: rem(260px);
        inset-inline-end: rem(-160px);
      }
    }

    .shape-4 {
      background-color: var(--cyan);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: 0;
      inset-inline-end: 0;

      @include mq(desktop) {
        block-size: rem(80px);
        inline-size: rem(80px);
      }
    }
  }

  &:not(.media-masthead--with-logos) {
    .media-masthead__image-shapes-container {
      .shape-5 {
        background-color: var(--royal-blue);
        block-size: rem(40px);
        inline-size: rem(40px);
        inset-block-end: rem(-40px);
        inset-inline-end: rem(40px);

        @include mq(desktop) {
          block-size: rem(80px);
          inline-size: rem(80px);
          inset-block-end: rem(-80px);
          inset-inline-end: rem(80px);
        }
      }
    }
  }
}

.media-masthead--shapes-2 {
  &:not(.media-masthead--with-logos) {
    .media-masthead__image-shapes-container {
      .shape-6 {
        @include mq(desktop) {
          background-color: var(--arapawa);
          block-size: rem(80px);
          inline-size: rem(80px);
          inset-block-end: rem(-80px);
          inset-inline-end: rem(-80px);
        }
      }
    }
  }

  .media-masthead__image-shapes-container {
    .shape-1 {
      background-color: var(--royal-blue);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: 0;
      inset-inline-start: 0;
    }

    .shape-2 {
      background-color: var(--cyan);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: rem(40px);
      inset-inline-start: rem(40px);
    }

    .shape-3 {
      @include mq(desktop) {
        background-color: var(--azure);
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-block-start: rem(40px);
        inset-inline-start: rem(-50px);
      }

      @include mq(wide) {
        block-size: rem(60px);
        inline-size: rem(60px);
        inset-inline-start: rem(-60px);
      }
    }

    .shape-4 {
      @include mq(desktop) {
        background-color: var(--arapawa);
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-block-start: rem(90px);
        inset-inline-start: rem(-50px);
      }

      @include mq(wide) {
        block-size: rem(60px);
        inline-size: rem(60px);
        inset-block-start: rem(100px);
        inset-inline-start: rem(-60px);
      }
    }

    .shape-5 {
      @include mq(desktop) {
        background-color: var(--crusta);
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-start: rem(90px);
        inset-inline-start: rem(-130px);
      }

      @include mq(wide) {
        block-size: rem(100px);
        inline-size: rem(100px);
        inset-block-start: rem(100px);
        inset-inline-start: rem(-160px);
      }
    }
  }
}

.media-masthead--with-logos {
  .media-masthead__shapes-container {
    .shape-1 {
      background-color: var(--arapawa);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: 0;
      inset-inline-end: 0;

      @include mq(tablet) {
        block-size: rem(160px);
        inline-size: rem(160px);
      }
    }
  }
}

.media-masthead__copy.rtf {
  line-height: 1.56;
}

.media-masthead__heading {
  font-size: rem(45px);
}
