.resource-card {
  @include mq(desktop) {
    min-block-size: rem(350px);
  }

  &:not(.resource-card--view-all) {
    min-block-size: rem(320px);
  }
}

.resource-card__link {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  position: relative;
  text-decoration: none;

  @include active-state {
    .resource-card__read-heading {
      color: var(--azure);
    }

    .resource-card--view-all & {
      .resource-card__inner {
        background-color: var(--arapawa);
      }
    }
  }

  &.focus-visible {
    outline-offset: rem(4px);
  }

  .resource-card--view-all & {
    @include mq($until: tablet) {
      block-size: auto;
      padding-block-end: unset;

      &::after {
        display: none;
      }
    }
  }
}

.resource-card__inner {
  background-color: var(--gallery);
  block-size: 100%;
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding: rem(30px);
  position: relative;

  @include mq(desktop) {
    padding: rem(40px);
  }

  .resource-card--view-all & {
    background-color: var(--azure);
    color: var(--white);
    font-size: rem(16px);
    font-weight: 500;
    line-height: 1.38;
    transition: background-color 0.2s ease-in-out;

    @include mq($until: tablet) {
      flex-direction: row;
      justify-content: center;
      padding: rem(15px 31px 13px);
      position: unset;
    }

    @include mq(tablet) {
      min-block-size: rem(230px);
    }

    @include mq(desktop) {
      font-size: rem(24px);
      line-height: 1.42;
      min-block-size: rem(260px);
    }
  }
}

.resource-card__head {
  flex-grow: 1;
}

.resource-card__label {
  color: var(--tundora);
  font-size: rem(14px);
  line-height: 1.57;
  margin-block-end: rem(3px);

  @include mq(desktop) {
    margin-block-end: rem(5px);
  }
}

.resource-card__read-heading {
  font-size: rem(18px);
  line-height: 1.33;
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.resource-card__tags {
  display: flex;
  flex-wrap: wrap;
  padding-inline-end: rem(45px);

  @include mq(desktop) {
    padding-inline-end: rem(25px);
  }

  li {
    align-items: center;
    color: var(--tundora);
    display: flex;
    font-size: rem(14px);
    line-height: 1.57;

    &::after {
      background-color: var(--tundora);
      block-size: rem(10px);
      content: "";
      display: block;
      inline-size: rem(1px);
      margin-inline: rem(10px);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
