/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable plugin/stylelint-bem-namics */
.referrals {
  background-color: var(--white);
  border: solid 1px #979797;
  border-radius: 4px;
  margin-block: -60px 100px;
  padding-block: 75px 45px;
  padding-inline: 65px;
  position: relative;

  h2 {
    font-size: 30px;
    line-height: 0.93;
  }

  .share-this__title {
    font-weight: normal;
  }
}

.referrals__form-input-wrapper {
  display: flex;
  gap: 20px;
  justify-content: space-between;

  [type="submit"] {
    min-inline-size: 209px;
  }

  .Form__Element {
    flex: 1;
    margin-block-start: rem(0) !important;
  }

  .FormTextbox__Input {
    block-size: 100%;
  }
}

.referrals__checks {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block-start: 45px;
}

.referrals__check {
  align-items: flex-start;
  display: flex;
  gap: 20px;

  input {
    margin-block-start: 9px;
    transform: scale(1.5);
  }
}
