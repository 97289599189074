.btn {
  appearance: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  inline-size: 100%;
  justify-content: center;
  padding: rem(15px 31px 13px);
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  z-index: z("default");

  @include mq(tablet) {
    inline-size: auto;
  }

  @include mq(desktop) {
    inline-size: auto;
    line-height: 1.56;
    padding: rem(11px 30px);
  }

  @include active-state {
    &::after {
      transform: scale(1);
    }
  }

  &.focus-visible {
    outline-offset: rem(4px);
  }

  &::after {
    background-color: var(--white);
    block-size: 100%;
    content: "";
    inline-size: 100%;
    inset-block-end: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: scale(0);
    transform-origin: 0 100%;
    transition: transform 0.2s ease-in-out;
    z-index: z("below");
  }

  &.btn--disabled,
  &:disabled {
    background-color: var(--mercury);
    color: var(--alto);
    font-weight: normal;
    pointer-events: none;
  }
}

.btn--primary {
  background-color: var(--azure);
  border-radius: 5px;
  color: var(--white);

  @include active-state {
    color: var(--azure);
    outline-offset: rem(4px);
  }

  &::after {
    background-color: var(--white);
    block-size: calc(100% - #{rem(2px)});
    border-radius: 5px;
    inline-size: calc(100% - #{rem(2px)});
    inset-block-end: rem(1px);
    inset-inline-start: rem(1px);
  }

  [data-background="gray"] & {
    &:not(.btn--outline) {
      &::after {
        background-color: var(--gallery);
      }
    }
  }
}

.btn--secondary {
  background-color: var(--crusta);
  border-radius: 5px;
  color: var(--arapawa);

  @include active-state {
    color: var(--crusta);
  }

  &.focus-visible {
    outline-color: var(--crusta);
  }

  &::after {
    background-color: var(--arapawa);
    block-size: calc(100% - 2px);
    inline-size: calc(100% - 2px);
    inset-block-end: 1px;
    inset-inline-start: 1px;
  }

  &.btn--outline {
    border: solid 1px var(--white);
    color: var(--white);

    @include active-state {
      color: var(--arapawa);
    }

    &::after {
      background-color: var(--white);
    }
  }
}

.btn--outline {
  background-color: transparent;
  border: solid rem(1px) var(--azure);
  color: var(--azure);

  @include active-state {
    color: var(--white);
  }

  &::after {
    background-color: var(--azure);
    block-size: calc(100% + #{rem(2px)});
    inline-size: calc(100% + #{rem(2px)});
    inset-block-end: rem(-1px);
    inset-inline-start: rem(-1px);
  }
}
