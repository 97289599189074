.utility-navigation {
  background-color: var(--arapawa);
  color: var(--white);
  display: none;

  @include mq(desktop) {
    display: block;
  }
}

.utility-navigation__inner-container {
  display: flex;
  flex-direction: column-reverse;
  gap: rem(20px);

  @include mq(desktop) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.utility-navigation__partner-program {
  background-color: var(--arapawa);
  color: var(--white);
  display: none;
  font-size: rem(16px);
  font-weight: normal;
  line-height: 1.5;
  padding-block: rem(20px);
  padding-inline: rem(20px);

  @include mq(desktop) {
    display: block;
    max-inline-size: 50%;
    padding: 0;
  }

  a {
    color: var(--white);
  }
}

.utility-navigation__items {
  @include mq(desktop) {
    align-items: stretch;
    display: flex;
    gap: rem(30px);
    justify-content: flex-end;
    margin-inline-end: 0;
    margin-inline-start: auto;
    min-block-size: 40px;
    position: relative;
  }

  @include mq(wide) {
    gap: rem(40px);
  }

  & > * {
    @include mq(desktop) {
      align-items: center;
      display: flex;
    }
  }
}

.utility-navigation__cta {
  align-items: center;
  block-size: 100%;
  color: var(--white);
  display: flex;
  gap: rem(10px);
  text-decoration: none;

  @include mq(desktop) {
    font-size: rem(16px);
    line-height: 1.5;
  }

  @include active-state {
    text-decoration: underline;
  }

  svg {
    block-size: rem(15px);
    fill: var(--white);
    inline-size: rem(15px);
  }
}

.utility-navigation__language-selector-select {
  align-items: center;
  display: flex;

  @include mq(desktop) {
    display: none;
  }

  select {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.573 12.936c.755-1.224 1.246-2.905 1.335-4.811h1.81a6.257 6.257 0 0 1-3.145 4.81ZM1.282 8.125h1.81c.089 1.906.58 3.587 1.335 4.81a6.257 6.257 0 0 1-3.145-4.81Zm3.145-6.061C3.672 3.288 3.18 4.969 3.092 6.875h-1.81a6.258 6.258 0 0 1 3.145-4.811Zm3.698 4.811V1.378c1.333.548 2.391 2.8 2.535 5.497H8.125Zm0 6.747V8.125h2.535c-.144 2.697-1.202 4.95-2.535 5.497Zm-1.25-5.497v5.497c-1.333-.548-2.391-2.8-2.535-5.497h2.535Zm0-6.747v5.497H4.34c.144-2.697 1.202-4.95 2.535-5.497Zm6.843 5.497h-1.81c-.089-1.906-.58-3.587-1.335-4.811a6.258 6.258 0 0 1 3.145 4.811ZM7.5 0C3.364 0 0 3.364 0 7.5 0 11.635 3.364 15 7.5 15S15 11.635 15 7.5C15 3.364 11.636 0 7.5 0Z' fill='%230e1d77' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position-x: 0;
    background-position-y: calc(50% + 1px);
    background-repeat: no-repeat;
    block-size: auto;
    border: 0;
    color: var(--arapawa);
    inline-size: auto;
    margin: 0;
    padding-block: 0;
    padding-inline-start: rem(20px);
    text-align: start;

    svg {
      fill: var(--arapawa);
    }

    option {
      margin: 0;
      padding-inline-end: 0;
    }
  }

  svg {
    block-size: rem(15px);
    fill: var(--white);
    inline-size: rem(15px);
  }
}

.utility-navigation__language-selector-listbox {
  display: none;

  @include mq(desktop) {
    align-self: stretch;
    display: flex;
    font-size: rem(16px);
    line-height: 1.5;
  }

  label {
    @extend %sr-only;
  }
}

.utility-navigation__language-selector-trigger {
  align-items: center;
  block-size: 100%;
  border: rem(1px) solid transparent;
  color: var(--white);
  cursor: pointer;
  display: inline-flex;
  font-weight: normal;
  justify-content: center;
  margin-inline: rem(-10px);
  padding-inline-end: rem(10px);
  padding-inline-start: rem(35px);
  position: relative;

  @include active-state {
    text-decoration: underline;
  }

  svg {
    block-size: rem(15px);
    fill: var(--white);
    inline-size: rem(15px);
    inset-block-start: 50%;
    inset-inline-start: rem(10px);
    position: absolute;
    transform: translateY(-50%);
  }

  .utility-navigation__language-selector-listbox--is-open & {
    background-color: var(--white);
    border: rem(1px) solid var(--alto);
    border-block-end: 0;
    color: var(--arapawa);

    &::after {
      background-color: var(--white);
      block-size: rem(1px);
      content: "";
      inline-size: 100%;
      inset-block-end: rem(-1px);
      inset-inline-start: 0;
      position: absolute;
      z-index: z("alert");
    }

    svg {
      fill: var(--arapawa);
    }
  }
}

.utility-navigation__language-selector-list-item {
  &:not(:last-child) {
    border-block-end: rem(1px) solid var(--alto);
  }

  &.option-selected {
    @extend %sr-only;
  }
}

.utility-navigation__language-selector-list-item-link {
  background-color: var(--white);
  color: var(--arapawa);
  display: inline-block;
  inline-size: 100%;
  padding-block: rem(10px);
  padding-inline: rem(17px);
  text-decoration: none;

  @include active-state {
    text-decoration: underline;
  }
}

.utility-navigation__language-selector-list {
  background-color: var(--white);
  border: rem(1px) solid var(--alto);
  inset-block-start: calc(100%);
  inset-inline-end: 0;
  margin-inline-end: rem(-10px);
  max-block-size: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  z-index: z("header");

  .utility-navigation__language-selector-listbox--is-open & {
    max-block-size: none;
    overflow-y: auto;
    visibility: visible;
  }
}
/* stylelint-disable selector-max-id  */
/* stylelint-disable selector-max-compound-selectors  */
#smt-lang-selector {
  block-size: 100%;

  .smt-menu {
    block-size: 100%;

    @include mq(desktop) {
      font-size: rem(16px);
      line-height: 1.5;
    }

    &.smt-on {
      .smt-trigger {
        background-color: var(--white);
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.573 12.936c.755-1.224 1.246-2.905 1.335-4.811h1.81a6.257 6.257 0 0 1-3.145 4.81ZM1.282 8.125h1.81c.089 1.906.58 3.587 1.335 4.81a6.257 6.257 0 0 1-3.145-4.81Zm3.145-6.061C3.672 3.288 3.18 4.969 3.092 6.875h-1.81a6.258 6.258 0 0 1 3.145-4.811Zm3.698 4.811V1.378c1.333.548 2.391 2.8 2.535 5.497H8.125Zm0 6.747V8.125h2.535c-.144 2.697-1.202 4.95-2.535 5.497Zm-1.25-5.497v5.497c-1.333-.548-2.391-2.8-2.535-5.497h2.535Zm0-6.747v5.497H4.34c.144-2.697 1.202-4.95 2.535-5.497Zm6.843 5.497h-1.81c-.089-1.906-.58-3.587-1.335-4.811a6.258 6.258 0 0 1 3.145 4.811ZM7.5 0C3.364 0 0 3.364 0 7.5 0 11.635 3.364 15 7.5 15S15 11.635 15 7.5C15 3.364 11.636 0 7.5 0Z' fill='%230e1d77' fill-rule='nonzero'/%3E%3C/svg%3E");
      }

      .smt-lang {
        color: var(--arapawa);
      }
    }

    &:not(.smt-on) .smt-trigger ul {
      block-size: 0;
      visibility: hidden;
    }
  }

  .smt-trigger {
    background-color: var(--arapawa);
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.573 12.936c.755-1.224 1.246-2.905 1.335-4.811h1.81a6.257 6.257 0 0 1-3.145 4.81ZM1.282 8.125h1.81c.089 1.906.58 3.587 1.335 4.81a6.257 6.257 0 0 1-3.145-4.81Zm3.145-6.061C3.672 3.288 3.18 4.969 3.092 6.875h-1.81a6.258 6.258 0 0 1 3.145-4.811Zm3.698 4.811V1.378c1.333.548 2.391 2.8 2.535 5.497H8.125Zm0 6.747V8.125h2.535c-.144 2.697-1.202 4.95-2.535 5.497Zm-1.25-5.497v5.497c-1.333-.548-2.391-2.8-2.535-5.497h2.535Zm0-6.747v5.497H4.34c.144-2.697 1.202-4.95 2.535-5.497Zm6.843 5.497h-1.81c-.089-1.906-.58-3.587-1.335-4.811a6.258 6.258 0 0 1 3.145 4.811ZM7.5 0C3.364 0 0 3.364 0 7.5 0 11.635 3.364 15 7.5 15S15 11.635 15 7.5C15 3.364 11.636 0 7.5 0Z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: rem(10px) center;
    background-repeat: no-repeat;
    background-size: rem(15px);
    block-size: 100%;
    cursor: pointer;
    margin-inline: rem(-10px);
    text-transform: capitalize;

    @include active-state {
      .smt-lang {
        text-decoration: underline;
      }
    }
  }

  .smt-trigger-link {
    align-items: center;
    block-size: 100%;
    display: flex;
    padding-inline-end: rem(10px);
    padding-inline-start: rem(35px);
  }

  .smt-lang {
    color: var(--white);
  }

  .smt-trigger ul {
    background-color: var(--white);
    border: rem(1px) solid var(--alto);
    inline-size: auto !important;
    inset-block-start: calc(100% - #{rem(1px)});
    inset-inline-end: 0;
    margin-inline-end: rem(-10px);
    overflow-y: auto;
    position: absolute;
    z-index: z("header");
  }

  .smt-trigger .smt-item {
    &:not(:last-child) {
      border-block-end: rem(1px) solid var(--alto);
    }

    &.option-selected {
      @extend %sr-only;
    }
  }

  .smt-link {
    background-color: var(--white);
    color: var(--arapawa);
    display: inline-block;
    inline-size: 100%;
    padding-block: rem(10px);
    padding-inline: rem(17px);
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }
  }
}
