.icon-grid {
  padding-block: var(--module-spacing);
  position: relative;

  @include mq(desktop) {
    padding-block-end: rem(120px);
  }

  @include mq(wide) {
    padding-block-end: rem(160px);
  }

  .two-column-content-media__content {
    @include mq($until: desktop) {
      margin: 0 auto;
    }

    @include mq(desktop) {
      inline-size: 47.875%;
    }
  }

  .two-column-content-media__media {
    @include mq($until: desktop) {
      display: none;
    }
  }

  &.icon-grid--with-image {
    @include mq($until: desktop) {
      padding-block-start: rem(160px);
    }

    .icon-card__image {
      object-fit: contain;
      @include mq(desktop, wide) {
        block-size: rem(60px);
        inline-size: rem(60px);
      }
    }
  }

  &:not(.icon-grid--with-image) {
    .two-column-content-media__content {
      inline-size: 100%;
    }

    .icon-card__inner {
      max-inline-size: rem(380px);
    }
  }

  .two-column-content-media {
    @include mq(desktop) {
      flex-direction: row-reverse;
    }
  }

  .two-column-content-media__media-inner {
    &::after,
    &::before {
      block-size: 5.0625rem;
      content: "";
      inline-size: 5.0625rem;
      position: absolute;
    }

    &::before {
      background-color: var(--crusta);
      border-end-end-radius: 3.5rem;
      inset-block-start: 0;
      inset-inline-start: 0;
    }
  }
}

.icon-grid__heading {
  margin-block-end: rem(40px);
  text-align: center;

  @include mq(tablet) {
    margin-block-end: rem(60px);
  }

  @include mq(desktop) {
    margin-block-end: rem(80px);
  }

  .icon-grid--with-image & {
    @include mq(desktop) {
      margin-block-end: rem(40px);
    }

    @include mq(wide) {
      margin-block-end: rem(60px);
    }
  }
}

.icon-grid__buttons {
  inline-size: 100%;
  margin: rem(-10px);
  margin-block-start: rem(40px);

  @include mq(tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block-start: rem(60px);
  }

  .btn {
    margin: rem(10px);
  }
}

.icon-grid__image-shapes-container {
  .shape-1 {
    background-color: var(--crusta);
    block-size: rem(80px);
    inline-size: rem(80px);
    inset-block-start: 0;
    inset-inline-end: 0;

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
    }
  }

  .shape-2 {
    background-color: var(--azure);
    block-size: rem(60px);
    inline-size: rem(60px);
    inset-block-start: rem(80px);
    inset-inline-start: 0;

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(160px);
    }
  }

  .shape-3 {
    background-color: var(--azure);
    block-size: rem(50px);
    inline-size: rem(50px);
    inset-block-end: rem(70px);
    inset-inline-end: rem(120px);

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-end: rem(100px);
      inset-inline-end: rem(160px);
    }
  }

  .shape-4 {
    background-color: var(--arapawa);
    block-size: rem(120px);
    inline-size: rem(120px);
    inset-block-end: 0;
    inset-inline-end: 0;

    @include mq(wide) {
      block-size: rem(160px);
      inline-size: rem(160px);
    }
  }
}

.icon-grid--shapes-1 {
  .icon-grid__top-shapes-container {
    .shape-1 {
      background-color: var(--cyan);
      block-size: rem(20px);
      inline-size: rem(20px);
      inset-block-start: rem(140px);
      inset-inline-start: 0;

      @include mq(desktop) {
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-start: rem(80px);
      }

      @include mq(wide) {
        block-size: rem(160px);
        inline-size: rem(160px);
        inset-block-start: rem(100px);
      }
    }

    .shape-2 {
      background-color: var(--azure);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(60px);
      inset-inline-start: rem(20px);

      @include mq(desktop) {
        background-color: var(--crusta);
        block-size: rem(40px);
        inline-size: rem(40px);
        inset-block-start: rem(40px);
        inset-inline-start: 0;
      }

      @include mq(wide) {
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-block-start: rem(50px);
      }
    }

    .shape-3 {
      background-color: var(--arapawa);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: 0;
      inset-inline-start: rem(100px);

      @include mq(desktop) {
        block-size: rem(40px);
        inline-size: rem(40px);
        inset-inline-start: rem(40px);
      }

      @include mq(wide) {
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-inline-start: rem(50px);
      }
    }
  }

  .icon-grid__bottom-shapes-container {
    .shape-1 {
      @include mq(desktop) {
        background-color: var(--royal-blue);
        block-size: rem(60px);
        inline-size: rem(60px);
        inset-block-end: rem(120px);
        inset-inline-start: rem(20px);
      }

      @include mq(wide) {
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-end: rem(160px);
        inset-inline-start: rem(80px);
      }
    }

    .shape-2 {
      @include mq(desktop) {
        background-color: var(--cyan);
        block-size: rem(120px);
        inline-size: rem(120px);
        inset-block-end: 0;
        inset-inline-start: rem(80px);
      }

      @include mq(wide) {
        block-size: rem(160px);
        inline-size: rem(160px);
        inset-inline-start: rem(160px);
      }
    }
  }
}

.icon-grid--shapes-2 {
  .icon-grid__top-shapes-container {
    .shape-1 {
      @include mq(desktop) {
        background-color: var(--arapawa);
        block-size: rem(160px);
        inline-size: rem(80px);
        inset-block-start: 0;
        inset-inline-start: 0;
      }

      @include mq(wide) {
        block-size: rem(230px);
        inline-size: rem(160px);
      }
    }
  }

  .icon-grid__bottom-shapes-container {
    .shape-1 {
      @include mq(desktop) {
        background-color: var(--crusta);
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-end: rem(80px);
        inset-inline-end: 0;
      }

      @include mq(wide) {
        block-size: rem(120px);
        inline-size: rem(120px);
        inset-block-end: rem(120px);
      }
    }

    .shape-2 {
      @include mq(desktop) {
        background-color: var(--cyan);
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-end: 0;
        inset-inline-end: rem(80px);
      }

      @include mq(wide) {
        block-size: rem(120px);
        inline-size: rem(120px);
        inset-inline-end: rem(120px);
      }
    }
  }
}

.icon-grid--shapes-3 {
  .icon-grid__top-shapes-container {
    .shape-1 {
      @include mq(desktop) {
        background-color: var(--cyan);
        block-size: rem(30px);
        inline-size: rem(30px);
        inset-block-start: 0;
        inset-inline-start: rem(80px);
      }

      @include mq(wide) {
        block-size: rem(40px);
        inline-size: rem(40px);
        inset-inline-start: rem(120px);
      }
    }

    .shape-2 {
      @include mq(desktop) {
        background-color: var(--azure);
        block-size: rem(80px);
        inline-size: rem(80px);
        inset-block-start: rem(30px);
        inset-inline-start: 0;
      }

      @include mq(wide) {
        block-size: rem(120px);
        inline-size: rem(120px);
        inset-block-start: rem(40px);
      }
    }
  }
}
