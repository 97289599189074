.overview-block {
  margin-block-end: rem(20px);

  @include mq(desktop) {
    margin-block-end: rem(60px);
  }
}

.overview-block__inner {
  border: solid rem(1px) var(--alto);
  padding: rem(40px 40px 34px 30px);
  position: relative;

  @include mq(desktop) {
    padding: rem(60px 139px 90px 80px);
  }

  .rtf {
    ul,
    ol {
      margin-inline-start: rem(20px);
    }
  }

  &::after,
  &::before {
    block-size: 5.0625rem;
    content: "";
    inline-size: 5.0625rem;
    position: absolute;
  }

  &::before {
    background-color: var(--azure);
    border-end-end-radius: 3.5rem;
    inset-block-start: 0;
    inset-inline-start: 0;
  }

  &::after {
    background-color: var(--crusta);
    border-start-start-radius: 3.5rem;
    inset-block-end: 0;
    inset-inline-end: 0;
  }
}

.overview-block__heading {
  font-size: rem(18px);
  line-height: 1.33;
  margin-block-end: rem(20px);

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}
