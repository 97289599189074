html {
  @include rem-baseline;
}

body.hamburger-menu--is-open {
  overflow: hidden;
  position: fixed;
}

.container {
  margin-inline: auto;
  max-inline-size: var(--container-max-width);
  padding-inline: var(--container-padding);

  &.container--with-sidebar {
    column-gap: var(--card-spacing);
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: var(--card-spacing);

    @include mq(desktop) {
      flex-direction: row;
    }

    & .container__aside {
      order: -1;

      @include mq(desktop) {
        align-self: flex-start;
        flex-shrink: 0;
        inline-size: 350px;
        max-inline-size: 350px;
        order: unset;
      }

      .resource-image-card {
        border-radius: unset;
      }

      picture,
      img {
        border-radius: unset;
      }
    }

    & .container__aside--sticky {
      position: relative;

      @include mq(desktop) {
        inset-block-start: rem(20px);
        position: sticky;
      }
    }

    & .container__content {
      flex-grow: 1;

      > * {
        &:first-child {
          margin-block-start: 0;
        }

        &:last-child {
          margin-block-end: 0;
        }
      }
    }
  }

  &.container--support-detail-page {
    margin-block: var(--module-spacing);
  }

  .container {
    padding-inline: 0;
  }
}

.container *:focus:not(.focus-visible),
*:focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: solid rem(1px) var(--azure);

  &:-moz-focusring {
    outline: solid rem(1px) var(--azure);
  }
}

[data-background="gray"] {
  background-color: var(--gallery);
}

[data-background="cyan"] {
  background-color: var(--back-blue);
}

[class^="shape-"] {
  display: block;
  position: absolute;
  z-index: z("default");
}

.grid {
  --item-spacing: calc(var(--card-spacing) / 2);
  column-gap: var(--card-spacing);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: rem(45px);

  > * {
    flex-shrink: 0;
    inline-size: 100%;

    @include mq(tablet) {
      inline-size: calc(50% - var(--item-spacing));
    }
  }

  > .modal {
    inline-size: 100vw !important;
  }

  &.grid--3-cols {
    & > * {
      @include mq(desktop) {
        inline-size: calc(50% - var(--item-spacing));
      }

      @include mq(wide) {
        --item-spacing: calc(var(--card-spacing) / 1.5);
        inline-size: calc(33.33% - var(--item-spacing));
      }
    }
  }

  &.grid--4-cols {
    & > * {
      @include mq(tablet) {
        inline-size: calc(50% - var(--item-spacing));
      }

      @include mq(desktop) {
        --item-spacing: calc(var(--card-spacing) / 1.5);
        inline-size: calc(33.33% - var(--item-spacing));
      }

      @include mq(wide) {
        --item-spacing: calc(var(--card-spacing) / 1.33);
        inline-size: calc(25% - var(--item-spacing));
      }
    }
  }

  &.grid--6-cols {
    & > * {
      inline-size: calc(50% - var(--item-spacing));

      @include mq(tablet) {
        --item-spacing: calc(var(--card-spacing));
        inline-size: calc(25% - var(--item-spacing));
      }

      @include mq(wide) {
        --item-spacing: calc(var(--card-spacing));
        inline-size: calc(16.7% - var(--item-spacing));
      }
    }
  }

  &.grid--flexible-cols {
    @include mq(desktop) {
      flex-wrap: nowrap;

      & > * {
        flex: 1 1 0;
      }
    }

    .resource-image-card {
      position: relative;

      &::after {
        block-size: 5.0625rem;
        border-end-end-radius: 3.5rem;
        content: "";
        inline-size: 5.0625rem;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
      }

      &:nth-child(even) {
        &::after {
          background-color: var(--azure);
        }
      }

      &:nth-child(odd) {
        &::after {
          background-color: var(--crusta);
        }
      }
    }
  }

  &.grid--left {
    justify-content: flex-start;
  }

  &.grid--grow {
    & > * {
      @include mq(desktop) {
        flex-grow: 1;
        max-inline-size: calc(50% - var(--item-spacing));
      }
    }
  }

  &.grid--no-grow {
    & > * {
      @include mq(desktop) {
        flex: 0 1 0;
        flex-basis: 33.3%;
      }
    }
  }
}

.external-link {
  align-items: center;
  display: inline-flex !important;
  gap: rem(5px);
  justify-content: flex-start;
  padding-inline-end: rem(18px);
  position: relative;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='iconify iconify--ri' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='%230e1d77' d='M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: rem(14px) rem(14px);
    block-size: rem(14px);
    content: "";
    display: block;
    inline-size: rem(14px);
    position: relative;
  }
}
