.container__thank-you-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;

  h2 {
    color: var(--azure);
  }

  .thank-subtext {
    padding-block-end: 2rem;
  }
}
