.breadcrumbs {
  border-block-end: solid rem(1px) var(--alto);
  font-size: rem(14px);
  line-height: 1.57;
  padding-block: rem(9px);

  @include mq(tabletLandscape) {
    padding-block: rem(19px);
  }

  li {
    align-items: center;
    display: flex;
  }

  a {
    color: var(--primary-text-color);
    font-size: rem(18px);
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }

    &[aria-current="page"] {
      color: var(--tundora);
      pointer-events: none;
    }
  }

  svg {
    block-size: rem(12px);
    fill: var(--primary-text-color);
    inline-size: rem(7px);
    margin-inline-end: rem(10px);

    @include mq(tabletLandscape) {
      block-size: rem(10px);
      inline-size: rem(6px);
      margin-inline: rem(12px);
    }
  }
}

.breadcrumbs--mobile-only {
  @include mq(tabletLandscape) {
    display: none;
  }
}

.breadcrumbs__desktop {
  display: none;

  @include mq(tabletLandscape) {
    display: flex;
  }

  li {
    &:last-child {
      a {
        color: var(--azure);
        font-weight: bold;
      }
    }
  }
}

.breadcrumbs__mobile {
  align-items: center;
  display: flex;

  @include mq(tabletLandscape) {
    display: none;
  }
}
