.loading {
  block-size: rem(4px);
  inline-size: rem(210px);
  margin-block: rem(40px);
  margin-inline: 0;
  overflow: hidden;
  position: relative;

  &::after {
    animation: loading 1.5s infinite;
    background-color: var(--azure);
    block-size: 100%;
    content: "";
    display: block;
    inline-size: 100%;
    inset-block-start: 0;
    position: absolute;
  }
}

.loading--centered {
  inline-size: rem(210px);
  margin-inline: auto;
}

@keyframes loading {
  0% {
    inset-inline-start: -100%;
  }

  40%,
  60% {
    inset-inline-start: 0;
  }

  100% {
    inset-inline-start: 100%;
  }
}
