.resources-results-listing {
  margin-block-end: var(--module-spacing);
  padding-block-start: var(--module-spacing);
  position: relative;

  &::after {
    --spacing: var(--container-padding) * 2;
    background-color: var(--alto);
    block-size: rem(1px);
    content: "";
    inline-size: calc(100% - #{rem(var(--spacing))});
    inset-block-start: 0;
    inset-inline-start: var(--container-padding);
    margin-inline: auto;
    max-inline-size: var(--container-max-width);
    padding-inline: var(--container-padding);
    position: absolute;
  }

  &[data-header-background="cyan"] {
    padding-block-start: 0;

    > .container {
      background-color: var(--azure);

      .resources-results-listing__label {
        color: var(--white);
      }
    }
  }
}

.resources-results-listing__label {
  margin: rem(0 auto 60px);
  max-inline-size: rem(778px);
  text-align: center;
}

.resources-results-listing__label--left {
  .resources-results-listing__label {
    margin-inline-start: 0;
    padding-block: rem(50px);
    text-align: start;
  }
}
