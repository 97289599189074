.modal-profiler {
  inset-block-end: 22px;
  inset-inline-end: 22px;
  position: fixed;
  z-index: 1000;

  .close-icon {
    display: none;
  }

  .chevron-icon,
  .close-icon,
  .excel-icon {
    padding-block: 10px;
    padding-inline: 10px;
  }
}

.modal-profiler__overlay {
  background-color: rgba(255, 255, 255, 0.9);
  display: none;
  inset: 0;
  position: fixed;
  z-index: -2;
}

.modal-profiler--open {
  .modal-profiler__overlay,
  .modal-profiler__button-wrapper--excel,
  .modal-profiler__button-wrapper--idio {
    display: flex;
  }

  .chevron-icon {
    display: none;
  }

  .close-icon {
    display: block;
  }

  .modal-profiler__profile {
    inset-block-end: 0;
    inset-inline-end: 70px;
    max-block-size: 514px;
    min-inline-size: 485px;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
  }
}

.modal-profiler__button-wrapper {
  align-items: center;
  align-items: center;
  background-color: var(--azure);
  block-size: 56px;
  border-radius: 50%;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.5);
  color: var(--white);
  display: flex;
  inline-size: 56px;
  justify-content: center;

  .modal-profiler__button-icon {
    block-size: 56px;
  }

  svg {
    block-size: 56px;
    fill: var(--white);
    inline-size: 56px;
  }
}

.modal-profiler__profile {
  background: var(--white);
  border-block: solid 1px #979797;
  border-inline: solid 1px #979797;
  inset-inline-end: -120px;
  opacity: 0;
  padding-block: 32px 20px;
  padding-inline: 36px;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0, 1, 0, 1);
  visibility: hidden;
  z-index: -1;

  &::before {
    background-color: var(--azure);
    block-size: 12px;
    content: "";
    inset-block-start: 0;
    inset-inline: 0;
    position: absolute;
  }

  svg {
    block-size: 30px;
    inline-size: 30px;
  }
}

.segment__wrapper,
.engagement__score-wrapper {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.segment__group,
.engagement__score-title {
  align-items: center;
  color: var(--azure);
  display: flex;
  font-weight: bold;
  gap: 10px;
}

.segment__groups {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.segment__item,
.engagement__score {
  background-color: var(--back-blue);
  border-radius: 17px;
  font-weight: bold;
  min-inline-size: 169px;
  padding-block: 0;
  padding-inline: 20px;
  text-align: center;
}

.segment__item--icon {
  background-color: var(--white);
  display: flex;
  justify-content: center;
}

.segment__item--icon-wrapper {
  align-items: center;
  background-color: var(--back-blue);
  block-size: 35px;
  border-radius: 50%;
  display: flex;
  inline-size: 35px;
  justify-content: center;
  padding-block: 5px;
  padding-inline: 5px;

  svg {
    block-size: 15px;
    inline-size: 15px;
  }
}

.engagement__score {
  background-color: var(--azure);
  color: var(--white);
}

.modal-profiler__divider {
  border-block-end: solid 1px #979797;
  margin-block: 25px;
}

.modal-profiler__button-wrapper--excel,
.modal-profiler__button-wrapper--idio {
  display: none;
  margin-block-end: 15px;
}

.segment__content {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-inline-start: 30px;

  svg {
    inline-size: 15px;
  }
}

.segment__wrapper--profile {
  margin-block-start: 10px;
}

.segment__wrapper--profile {
  margin-block-end: 10px;
}

.segment__wrapper--profile-infinity {
  margin-inline-start: calc(10px + 15px);
}
