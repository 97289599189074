.tags {
  display: flex;
  flex-wrap: wrap;
  font-size: rem(14px);
  line-height: 1.57;

  li {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      &::after {
        background-color: var(--secondary-text-color);
        block-size: rem(10px);
        content: "";
        display: block;
        inline-size: rem(1px);
        margin-inline: rem(10px);
      }
    }
  }

  a {
    color: var(--secondary-text-color);
    text-decoration: none;

    @include active-state {
      text-decoration: underline;
    }
  }
}
