.driver-tab {
  inline-size: 100%;
  max-block-size: 0;
  min-inline-size: 100%;
  overflow: hidden;

  &.active {
    border: solid rem(1px) var(--alto);
    max-block-size: none;
  }
}

.driver-tab__nav {
  align-items: center;
  background-color: var(--arapawa);
  display: flex;
  flex-wrap: wrap;
  gap: rem(40px);
  min-block-size: rem(50px);
  padding-inline: rem(60px);

  @include mq(desktop) {
    min-block-size: rem(100px);
  }

  &:not(.driver-tab__nav--mobile) {
    display: none;

    @include mq("desktop") {
      display: flex;
    }
  }

  &.driver-tab__nav--mobile {
    padding-inline: 0;

    @include mq("desktop") {
      display: none;
    }
  }
}

.driver-tab__nav--mobile select {
  background-color: var(--arapawa);
  background-image: url("data:image/svg+xml,%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23ffffff' d='M8.315 4.45L4.484 0 3.188 1.099 6.546 5 3.188 8.901 4.484 10 8.315 5.55 8.787 5z' transform='translate(-1307 -50) translate(711 42) translate(491) translate(104 8) rotate(90 5.987 5)'/%3E%3C/svg%3E%0A");
  block-size: rem(50px);
  border: 0;
  color: var(--white);
}

.driver-tab__nav-item {
  border-block-end: 3px solid transparent;
  color: var(--white);
  min-block-size: rem(25px);
  padding-inline: 0;

  @include active-state {
    border-color: var(--white);
  }

  &.active {
    border-color: var(--white);
  }
}
