.two-column-content-media {
  position: relative;

  @include mq(desktop) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.two-column-content-media--inverted {
      flex-direction: row-reverse;

      .two-column-content-media__media-inner img {
        border-end-end-radius: 3.5rem;
        border-end-start-radius: 0;
      }
    }

    .two-column-content-media__media-inner img {
      border-end-end-radius: 0;
      border-end-start-radius: 3.5rem;
    }
  }
}

.two-column-content-media__content {
  @include mq(desktop) {
    inline-size: 40%;
  }
}

.two-column-content-media__media {
  flex-shrink: 0;
  position: relative;

  @include mq($until: desktop) {
    margin-block-start: rem(20px);
  }

  @include mq(desktop) {
    inline-size: 47.875%;
  }
}

.two-column-content-media__media-inner {
  @include active-state {
    outline-offset: rem(4px);

    .two-column-content-media__video-btn {
      color: var(--azure);

      &::after {
        transform: scale(1);
      }

      svg {
        fill: var(--azure);
      }
    }
  }

  &[data-module="VideoModal"] {
    cursor: pointer;
  }

  img {
    aspect-ratio: 4/3;
    block-size: auto;
    inline-size: 100%;

    &.rounded-corners {
      border-end-end-radius: 0;
      border-end-start-radius: 3.5rem;
    }
  }
}

.two-column-content-media__video-btn {
  background-color: var(--azure);
  block-size: rem(140px);
  color: var(--white);
  font-weight: 500;
  inline-size: rem(140px);
  inset-block-end: 0;
  inset-inline-start: 0;
  padding: rem(10px);
  position: absolute;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  z-index: z("default");

  @include mq(tablet) {
    block-size: rem(152px);
    inline-size: rem(152px);
    padding: rem(20px);
  }

  &.rounded-corners {
    border-end-end-radius: 0;
    border-end-start-radius: 3.5rem;
  }

  .two-column-content-media--inverted & {
    @include mq(desktop) {
      border-end-end-radius: 3.5rem;
      border-end-start-radius: 0;
      inset-inline-end: 0;
      inset-inline-start: unset;
    }
  }

  &::after {
    background-color: var(--white);
    block-size: 100%;
    content: "";
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: scale(0);
    transform-origin: 0 100%;
    transition: transform 0.2s ease-in-out;
    z-index: z("below");

    &.rounded-corners {
      border-end-start-radius: 3.5rem;
    }
  }

  svg {
    block-size: rem(20px);
    fill: var(--white);
    inline-size: rem(20px);
    inset-block-end: rem(10px);
    inset-inline-end: rem(10px);
    position: absolute;
    transition: fill 0.2s ease-in-out;

    @include mq(tablet) {
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-end: rem(20px);
      inset-inline-end: rem(20px);
    }
  }
}

.two-column-content-media__media-inner__shapes-container {
  inset-block-end: 0;
  inset-inline-start: 0;
  position: absolute;

  .shape-1 {
    inset-block-end: 0;
    inset-inline-start: 0;

    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(81px);
      border-end-start-radius: 3.5rem;
      inline-size: rem(81px);
    }

    @include mq(wide) {
      block-size: rem(81px);
      border-end-start-radius: 3.5rem;
      inline-size: rem(81px);
    }

    &.green {
      background-color: var(--crusta);
    }
  }
}

.two-column-content-media__media-inner__shapes-container--inverted {
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;

  .shape-1 {
    inset-block-start: 0;
    inset-inline-start: 0;

    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(81px);
      border-end-end-radius: 3.5rem;
      inline-size: rem(81px);
    }

    @include mq(wide) {
      block-size: rem(81px);
      border-end-end-radius: 3.5rem;
      inline-size: rem(81px);
    }
  }
}

.two-column-content-media__media-inner__shapes-container--full {
  inset-block-start: 0;
  inset-inline-end: 0;
  position: absolute;

  .shape-2 {
    inset-block-start: 0;
    inset-inline-end: 0;

    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(81px);
      border-end-start-radius: 3.5rem;
      inline-size: rem(81px);
    }

    @include mq(wide) {
      block-size: rem(81px);
      border-end-start-radius: 3.5rem;
      inline-size: rem(81px);
    }
  }
}

.two-column-content-media__cta-btn {
  align-items: center;
  background-color: var(--azure);
  block-size: rem(69px);
  border-end-end-radius: 0;
  border-end-start-radius: 3.5rem;
  color: var(--white);
  display: flex;
  font-weight: 500;
  gap: 30px;
  inline-size: rem(311px);
  inset-block-end: 0;
  inset-inline-start: 0;
  justify-content: center;
  position: absolute;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  z-index: z("default");

  @include active-state {
    color: var(--azure);

    svg {
      fill: var(--azure);
    }

    &::after {
      border-radius: 0;
      transform: scale(1);
    }
  }

  @include mq(tablet) {
    block-size: rem(69px);
    inline-size: rem(311px);
    padding: rem(20px) rem(28px) rem(28px) rem(28px);
  }

  .two-column-content-media--inverted & {
    @include mq(desktop) {
      border-end-end-radius: 3.5rem;
      border-end-start-radius: 0;
      inset-inline-end: 0;
      inset-inline-start: unset;
    }
  }

  &::after {
    background-color: var(--white);
    block-size: 100%;
    content: "";
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: scale(0);
    transform-origin: 0 100%;
    transition: transform 0.2s ease-in-out;
    z-index: z("below");
  }

  svg {
    block-size: rem(20px);
    fill: var(--white);
    inline-size: rem(20px);
    inset-block-end: rem(10px);
    inset-inline-end: rem(10px);
    position: absolute;
    transition: fill 0.2s ease-in-out;

    @include mq(tablet) {
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-end: unset;
      inset-inline-end: unset;
      position: relative;
    }
  }

  a {
    color: var(--white);
    text-decoration: none;
  }
}
