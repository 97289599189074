.share-this__title {
  color: var(--primary-text-color);
  font-weight: 500;
  margin-block-end: rem(6px);
}

.share-this__icons {
  display: flex;
}

.share-this__link {
  display: flex;
  outline-offset: rem(4px);
  padding: 0;

  svg {
    block-size: rem(30px);
    inline-size: rem(30px);
  }

  & + .share-this__link {
    margin-inline-start: rem(10px);
  }

  &[data-service="link"] {
    align-items: center;
    background-color: var(--arapawa);
    block-size: rem(30px);
    inline-size: rem(30px);
    justify-content: center;

    svg {
      block-size: rem(14px);
      fill: var(--white);
      inline-size: rem(14px);
    }
  }
}

.share-this__link-ready {
  display: none;
}
