/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable plugin/stylelint-bem-namics */
.EPiServerForms {
  background-color: var(--gallery);
  padding: rem(20px 30px 30px);
}

.FormStep__Title,
.FormStep__Description {
  display: none;
}

.Form__Status {
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.Form__Status__Message {
  @extend .rtf;

  margin: 0 !important;
  padding: 0 !important;
}

.Form__Success__Message,
.Form__Warning__Message,
.Form__Readonly__Message {
  background-color: transparent !important;
  color: var(--secondary-text-color) !important;
  margin-block: rem(0 30px) !important;
}

.Form__Element__Caption {
  @extend %sr-only;
}

.FormTextbox__Input {
  background-color: var(--white);
  block-size: rem(40px);
  border: solid rem(1px) var(--alto);
  border-radius: 6px;
  color: var(--secondary-text-color);
  font: var(--primary-font);
  font-size: rem(16px);
  inline-size: 100%;
  line-height: 1.38;
  outline-offset: rem(4px);
  padding: rem(9px 50px 7px 15px);
  position: relative;

  @include placeholder {
    color: var(--secondary-text-color);
    font-family: var(--primary-font);
  }

  @include mq(desktop) {
    font-size: rem(18px);
    line-height: 1.6;
  }
}

.Form__Element {
  font-family: var(--primary-font);
  margin: 0 !important;
  margin-block-start: rem(30px) !important;
  position: relative;

  &.ValidationFail {
    .FormTextbox__Input,
    select {
      border-color: var(--error-color);
      color: var(--error-color);

      @include placeholder {
        color: var(--error-color);
      }
    }

    &::after {
      background-color: var(--error-color);
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23ffffff' d='M3.154 2.499c-.265 0-.504.16-.604.406-.1.246-.04.527.15.712l6.379 6.38-6.38 6.38c-.17.164-.237.407-.178.635.06.228.238.406.466.465.228.06.47-.008.634-.178L10 10.919l6.38 6.38c.163.17.405.238.633.178.228-.06.406-.237.466-.465.06-.228-.009-.47-.179-.634l-6.379-6.38 6.38-6.38c.192-.188.25-.474.145-.722-.104-.247-.35-.405-.619-.397-.17.005-.33.076-.448.197L10 9.076l-6.38-6.38c-.122-.126-.29-.197-.466-.197z' transform='translate(-1740 -140) translate(0 110) translate(1740 30)'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: rem(10px) rem(10px);
      block-size: rem(20px);
      border-radius: 50%;
      content: "";
      inline-size: rem(20px);
      inset-block-start: rem(15px);
      inset-inline-end: rem(15px);
      position: absolute;
    }
  }
}

.FormSelection {
  select {
    appearance: none;
    background-color: var(--white);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTAgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPHBhdGggZmlsbD0iIzBlMWQ3NyIgZD0iTTguMzE1IDQuNDVMNC40ODQgMCAzLjE4OCAxLjA5OSA2LjU0NiA1IDMuMTg4IDguOTAxIDQuNDg0IDEwIDguMzE1IDUuNTUgOC43ODcgNXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzA3IC01MCkgdHJhbnNsYXRlKDcxMSA0MikgdHJhbnNsYXRlKDQ5MSkgdHJhbnNsYXRlKDEwNCA4KSByb3RhdGUoOTAgNS45ODcgNSkiLz4KPC9zdmc+Cg==");
    background-position-x: calc(100% - 15px);
    background-position-y: calc(50% + 1px);
    background-repeat: no-repeat;
    background-size: rem(14px) rem(14px);
    block-size: rem(40px);
    border: solid rem(1px) var(--alto);
    border-radius: 13px;
    color: var(--secondary-text-color);
    font-family: var(--primary-font);
    font-size: 16px;
    inline-size: 100%;
    line-height: 1.38;
    outline-offset: rem(4px);
    padding: rem(9px 50px 7px 15px);
    text-overflow: ellipsis;

    @include mq(desktop) {
      font-size: 18px;
      line-height: 1.56;
    }
  }
}

.Form__Element__ValidationError {
  color: var(--error-color) !important;
  font-size: rem(14px);
  line-height: 1.57;
  margin-block-start: rem(8px);
}

.FormChoice {
  inline-size: 100%;
}

.FormChoice + .FormChoice {
  margin-block-start: rem(15px) !important;
}

.FormRichText,
.FormChoice,
.FormParagraphText {
  @extend .rtf;

  font-size: rem(14px);
  line-height: 1.5;
}

.FormSubmitButton {
  @extend .btn;
  @extend .btn--primary;
}

.contact-us-form__form {
  .FormTextbox__Input {
    border-radius: unset;
  }

  .FormSelection select {
    border-radius: unset;
  }
}

.FormChoice__Input--Checkbox {
  appearance: none;
  background-color: var(--white);
  block-size: rem(20px);
  border: 1px solid var(--alto);
  inline-size: rem(20px);
  margin: 0;
  margin-inline-end: rem(5px);
  transform: translateY(rem(3px));

  &::after {
    block-size: rem(14px);
    border: solid var(--arapawa);
    border-radius: rem(1px);
    border-width: rem(0 2px 2px 0);
    content: "";
    display: none;
    inline-size: rem(6px);
    inset-block-start: rem(1px);
    inset-inline-start: rem(6px);
    position: relative;
    transform: rotate(45deg);
  }

  &:checked::after {
    display: block;
  }
}

.Form__Element.FormTextbox.FormTextbox--Textarea {
  @include mq(tablet) {
    inline-size: 100%;
  }

  .FormTextbox__Input {
    font-family: var(--primary-font);
    min-block-size: rem(120px);
    padding: rem(9px 50px 7px 20px);
    resize: none;

    @include mq(desktop) {
      min-block-size: rem(150px);
    }
  }
}

.Form__Element a:not(.btn):not(.FormSubmitButton) {
  text-decoration: underline;
}
