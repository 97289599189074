.featured-resource-cards-with-image {
  padding-block: var(--module-spacing);
  position: relative;

  .two-column-content-media__content {
    @include mq(desktop) {
      inline-size: 47.875%;
    }
  }
}

.featured-resource-cards-with-image__heading {
  margin-inline: auto;
  max-inline-size: rem(1000px);
  padding-inline: var(--container-padding);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(60px);
  }
}

.featured-resource-cards-with-image__card {
  display: block;
  padding-block: rem(20px);
  padding-inline-end: rem(60px);
  position: relative;
  text-decoration: none;

  @include mq(desktop) {
    padding-inline-end: rem(100px);
  }

  @include mq(wide) {
    padding-inline-end: rem(139px);
  }

  @include active-state {
    .featured-resource-cards-with-image__card-heading {
      color: var(--azure);
    }

    svg {
      transform: translateX(rem(10px));
    }
  }

  & + .featured-resource-cards-with-image__card {
    border-block-start: solid rem(1px) var(--alto);
  }

  svg {
    block-size: rem(25px);
    fill: var(--azure);
    inline-size: rem(25px);
    inset-block-end: rem(20px);
    inset-inline-end: 0;
    position: absolute;
    transition: transform 0.2s ease-in-out;

    @include mq(desktop) {
      block-size: rem(20px);
      inline-size: rem(20px);
    }
  }
}

.featured-resource-cards-with-image__card-label {
  color: var(--tundora);
  font-size: rem(14px);
  line-height: 1.57;
  margin-block-end: rem(3px);

  @include mq(desktop) {
    margin-block-end: rem(5px);
  }
}

.featured-resource-cards-with-image__card-heading {
  font-size: rem(18px);
  line-height: 1.33;
  margin-block-end: rem(5px);
  transition: color 0.2s ease-in-out;

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.featured-resource-cards-with-image__card-copy {
  color: var(--secondary-text-color);
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.featured-resource-cards-with-image__buttons {
  @include mq(desktop) {
    margin-block-start: rem(20px);
  }
}

.featured-resource-cards-with-image__top-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: 0;
      inset-inline-start: rem(60px);
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-inline-start: rem(80px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: rem(60px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(80px);
    }
  }

  .shape-3 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }
}

.featured-resource-cards-with-image__bottom-shapes-container {
  .shape-1 {
    background-color: var(--cyan);
    block-size: rem(40px);
    inline-size: rem(40px);
    inset-block-end: rem(40px);
    inset-inline-end: rem(20px);

    @include mq(desktop) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-inline-end: rem(40px);
    }

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
      inset-inline-end: rem(60px);
    }
  }

  .shape-2 {
    background-color: var(--azure);
    block-size: rem(20px);
    inline-size: rem(20px);
    inset-block-end: rem(20px);
    inset-inline-end: 0;

    @include mq(desktop) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: 0;
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
    }
  }
}
