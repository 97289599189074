.driver-download-block__header {
  color: var(--white);
  display: flex;
  flex-flow: column;

  & .search-bar {
    margin-block-start: rem(-60px);

    @include mq(desktop) {
      margin-block-start: rem(-80px);
    }
  }
}

.driver-download-block__form {
  display: block;
  inline-size: 100%;
  position: relative;
}

.driver-download-block__submit {
  appearance: none;
  background-color: transparent;
  block-size: rem(20px);
  border: 0;
  cursor: pointer;
  inline-size: rem(20px);
  inset-block-start: 50%;
  inset-inline-end: rem(28px);
  padding: 0;
  position: absolute;
  transform: translateY(-50%);

  &:disabled {
    svg {
      block-size: 100%;
      fill: var(--alto);
      inline-size: 100%;
    }
  }

  svg {
    block-size: 100%;
    fill: var(--arapawa);
    inline-size: 100%;
  }
}

.driver-download-block__submit--disabled {
  color: var(--alto);
  cursor: not-allowed;
}

.driver-download-block__no-results {
  inline-size: 100%;
}

.driver-download-block__result-item {
  position: relative;
}

.driver-download-block__results {
  padding-block: var(--module-spacing);
}

.driver-download-block__results-heading {
  text-align: center;
}

.driver-download-block__results-list {
  margin-block-start: rem(60px);
}
