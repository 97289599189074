.search-bar__bar {
  background-color: var(--gallery);
  display: flex;
  flex-flow: column;
  inline-size: 100%;
  padding-block: rem(30px);
  padding-inline: rem(30px);
  position: relative;

  @include mq(desktop) {
    border: 0;
    padding-block: rem(55px);
    padding-inline: rem(60px);
  }

  label {
    block-size: 1px;
    inline-size: 1px;
    inset-block-start: auto;
    inset-inline-start: -10000px;
    overflow: hidden;
    position: absolute;
  }

  .search-bar__input--clear-enabled {
    padding-inline-end: rem(100px);
    text-overflow: ellipsis;
  }

  input {
    appearance: none;
    background-color: var(--white);
    block-size: rem(50px);
    border: solid rem(1px) var(--alto);
    color: var(--secondary-text-color);
    font-family: var(--primary-font);
    font-size: rem(16px);
    inline-size: 100%;
    line-height: 1.38;
    padding-block: rem(10px);
    padding-inline-end: rem(40px);
    padding-inline-start: rem(20px);
    text-overflow: ellipsis;

    @include mq(desktop) {
      font-size: rem(18px);
      line-height: 1.56;
      padding-block: rem(14px);
      padding-inline-end: rem(35px);
    }
  }
}

.search-bar__form {
  display: block;
  inline-size: 100%;
  position: relative;
}

.search-bar__actions {
  align-items: center;
  display: flex;
  gap: rem(10px);
  inset-block-start: 50%;
  inset-inline-end: rem(20px);
  padding: 0;
  position: absolute;
  transform: translateY(-50%);

  @include mq(desktop) {
    gap: rem(20px);
  }
}

.search-bar__clear {
  appearance: none;
  background-color: transparent;
  color: var(--arapawa);
  cursor: pointer;
  font-size: rem(16px);
}

.search-bar__clear--disabled {
  color: var(--alto);
  cursor: not-allowed;
  display: none;
  pointer-events: none;
}

.search-bar__submit {
  appearance: none;
  background-color: transparent;
  block-size: rem(15px);
  border: 0;
  cursor: pointer;
  inline-size: rem(14px);
  padding: 0;

  &:disabled {
    svg {
      fill: var(--alto);
    }
  }

  svg {
    block-size: 100%;
    display: block;
    fill: var(--arapawa);
    inline-size: 100%;
  }
}

.search-bar__submit--disabled {
  color: var(--alto);
  cursor: not-allowed;
}
