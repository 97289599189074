%scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  /* stylelint-disable-next-line plugin/stylelint-bem-namics */
  &::-webkit-scrollbar {
    display: none;
  }
}
