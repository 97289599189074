.testimonials-row {
  padding-block: var(--module-spacing);
  position: relative;

  @include mq($until: desktop) {
    padding-block-start: rem(80px);
  }

  &[data-background="gray"] {
    .testimonial-card {
      background-color: var(--white);
    }
  }

  &[data-background="cyan"] {
    background-color: var(--back-blue);

    .testimonial-card {
      background-color: var(--white);
      border-radius: 0 70px;

      &::after {
        block-size: 5.0625rem;
        border-start-start-radius: 3.5rem;
        content: "";
        inline-size: 5.0625rem;
        inset-block-end: 0;
        inset-inline-end: 0;
        position: absolute;
      }

      &:nth-child(even) {
        &::after {
          background-color: var(--azure);
        }
      }

      &:nth-child(odd) {
        &::after {
          background-color: var(--crusta);
        }
      }
    }
  }
}
