.modal {
  appearance: none;
  background-color: rgba(var(--white-rgb), 0.8);
  block-size: 100vh;
  border: 0;
  cursor: default;
  inline-size: 100vw;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 0;
  max-block-size: none;
  max-inline-size: none;
  padding: rem(69px 20px);
  z-index: z(modal);

  @include mq(tablet) {
    padding: rem(89px);
  }

  &[open] {
    display: flex;
    margin: auto;
    max-inline-size: none;
  }
}

.modal__inner {
  background-color: #fff;
  box-shadow: rem(0 3px 20px 0) var(--alto);
  inline-size: rem(1044px);
  margin: auto;
  max-inline-size: 100%;
  padding: rem(40px 60px 40px 40px);
  position: relative;

  @include mq(tabletLandscape) {
    padding: rem(60px 100px 60px 60px);
  }

  @include mq(desktop) {
    display: flex;
  }
}

.modal__left-rail {
  margin-block-end: rem(40px);

  @include mq(desktop) {
    flex-shrink: 0;
    inline-size: rem(330px);
    margin-inline-end: rem(40px);
  }

  picture {
    inline-size: 100%;
    margin-block-start: rem(20px);
  }
}

.modal__heading {
  font-size: rem(18px);
  line-height: 1.33;
  margin-block-end: rem(5px);
  max-inline-size: rem(289px);

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

.modal__subheading {
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

.modal__close {
  cursor: pointer;
  display: flex;
  inset-block-start: rem(20px);
  inset-inline-end: rem(20px);
  padding: 0;
  position: absolute;

  @include mq(desktop) {
    inset-block-start: rem(40px);
    inset-inline-end: rem(40px);
  }

  svg {
    block-size: rem(15px);
    fill: var(--arapawa);
    inline-size: rem(15px);
    stroke: var(--arapawa);

    @include mq(desktop) {
      block-size: rem(20px);
      inline-size: rem(20px);
    }
  }
}
