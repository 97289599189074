.featured-content-tabs-with-media-row {
  padding-block: var(--module-spacing);
  position: relative;
}

.featured-content-tabs-with-media-row__heading {
  margin-inline: auto;
  max-inline-size: rem(797px);
  text-align: center;
}

.featured-content-tabs-with-media-row__copy {
  margin-block-end: rem(20px);
  margin-inline: auto;
  max-inline-size: rem(797px);
  text-align: center;

  @include mq(desktop) {
    margin-block-end: rem(40px);
  }
}

.featured-content-tabs-with-media-row__row {
  margin-block-start: rem(20px);

  @include mq($until: desktop) {
    padding-inline: 0;
  }

  @include mq(desktop) {
    align-items: center;
    display: grid;
    gap: 100px;
    grid-template-columns: 7fr 5fr;
    justify-content: space-between;
    margin-block-start: rem(40px);
  }
}

.featured-content-tabs-with-media-row__images {
  border-inline-start: 22px var(--crusta) solid;

  @include mq($until: desktop) {
    padding-inline: var(--container-padding);
  }

  @include mq(desktop) {
    flex: 1;
    flex-shrink: 0;
  }

  img {
    aspect-ratio: 866 /471;
    border-radius: 0 25px 25px 0;
  }
}

.featured-content-tabs-with-media-row__content {
  @include mq($until: desktop) {
    margin-block-start: rem(20px);
  }

  @include mq(desktop) {
    flex: 1;
  }
}

.featured-content-tabs-with-media-row__tabs {
  display: flex;
  margin-block-end: rem(10px);
  overflow: auto;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  padding-block-end: rem(10px);
  padding-block-start: rem(5px);
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  @include mq($until: desktop) {
    padding-inline: var(--container-padding);
  }

  @include mq(tablet) {
    margin-block-end: rem(15px);
  }

  @include mq(desktop) {
    gap: clamp(#{rem(10px)}, 1.5vw, #{rem(40px)});
    overflow: visible;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.featured-content-tabs-with-media-row__tab {
  border-block-end: solid rem(3px) var(--dark-gray);
  color: var(--primary-text-color);
  flex-shrink: 0;
  font-weight: 500;
  line-height: 1.38;
  outline-offset: rem(4px);
  padding: rem(5px 0);
  position: relative;
  text-align: center;
  transition: border-color 0.2s ease-in-out;

  @include mq(desktop) {
    max-inline-size: rem(150px);
  }

  @include active-state {
    border-color: var(--primary-text-color);
  }

  &:hover {
    border-block-end-color: var(--azure);
  }

  & + .featured-content-tabs-with-media-row__tab {
    margin-inline-start: rem(30px);

    @include mq(tablet) {
      margin-inline-start: rem(40px);
    }

    @include mq(desktop) {
      margin-inline-start: 0;
    }
  }

  &::after {
    background-color: var(--azure);
    block-size: rem(3px);
    content: "";
    inline-size: 100%;
    inset-block-end: rem(-3px);
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
  }

  &[aria-selected="true"] {
    &::after {
      opacity: 1;
    }
  }
}

.featured-content-tabs-with-media-row__panels {
  @include mq($until: desktop) {
    padding-inline: var(--container-padding);
  }
}

.featured-content-tabs-with-media-row__panel {
  &:not(.featured-content-tabs-with-media-row__panel--active) {
    display: none;
  }

  .rtf {
    font-size: rem(14px);
    line-height: 1.43;

    @include mq(desktop) {
      font-size: rem(16px);
      line-height: 1.5;
    }
  }

  .btn {
    margin-block-start: rem(20px);

    @include mq(tablet) {
      margin-block-start: rem(30px);
    }
  }
}

.featured-content-tabs-with-media-row__images {
  block-size: 100%;
  position: relative;
  z-index: z(default);
}

.featured-content-tabs-with-media-row__image {
  block-size: 100%;

  picture,
  img {
    block-size: 100%;
    inline-size: 100%;
    position: relative;
  }

  &:not(.featured-content-tabs-with-media-row__image--active) {
    display: none;
  }
}

.featured-content-tabs-with-media-row__top-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(30px);
      inset-inline-start: 0;
    }

    @include mq(wide) {
      block-size: rem(120px);
      inline-size: rem(120px);
      inset-block-start: rem(40px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-start: 0;
      inset-inline-start: rem(80px);
    }

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-inline-start: rem(120px);
    }
  }
}

.featured-content-tabs-with-media-row__bottom-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: rem(30px);
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(120px);
      inline-size: rem(120px);
      inset-block-end: rem(40px);
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-end: 0;
      inset-inline-end: rem(80px);
    }

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-inline-end: rem(120px);
    }
  }
}

.featured-content-tabs-with-media-row__image-shapes-container {
  .shape-1 {
    z-index: z("below");

    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-start: rem(30px);
      inset-inline-end: rem(-40px);
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(48px);
      inset-inline-end: rem(-60px);
    }
  }

  .shape-2 {
    z-index: z("below");
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-start: rem(90px);
      inset-inline-end: rem(-70px);
    }

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-start: rem(128px);
      inset-inline-end: rem(-100px);
    }
  }

  .shape-3 {
    z-index: z("below");
    @include mq(desktop) {
      background-color: var(--royal-blue);
      block-size: rem(30px);
      inline-size: rem(30px);
      inset-block-end: rem(90px);
      inset-inline-end: rem(-25px);
    }

    @include mq(wide) {
      block-size: rem(40px);
      inline-size: rem(40px);
      inset-block-end: rem(170px);
      inset-inline-end: rem(-30px);
    }
  }
}
