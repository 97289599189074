.testimonial-card {
  background-color: var(--gallery);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(84px 40px 84px 30px);
  position: relative;

  @include mq(desktop) {
    padding: rem(90px 70px 20px);
  }

  @include mq(wide) {
    padding: rem(108px 87px 108px 99px);
  }

  &::after {
    block-size: rem(30px);
    content: "";
    display: block;
    inline-size: rem(30px);
    position: absolute;
    z-index: z("default");

    @include mq(desktop) {
      block-size: rem(40px);
      inline-size: rem(40px);
    }
  }
}

.testimonial-card__heading {
  color: var(--primary-text-color);
  font-size: rem(21px);
  font-style: italic;
  font-weight: normal;
  line-height: 1.29;

  @include mq(wide) {
    font-size: rem(20px);
  }
}

.testimonial-card__author {
  align-items: center;
  display: flex;
  margin-block-start: rem(30px);

  @include mq(desktop) {
    margin-block-start: rem(40px);
  }
}

.testimonial-card__image {
  block-size: rem(60px);
  border-radius: 50%;
  inline-size: rem(60px);
  margin-inline-end: rem(10px);

  @include mq(wide) {
    block-size: rem(80px);
    inline-size: rem(80px);
    margin-inline-end: rem(20px);
  }
}

.testimonial-card__name {
  color: var(--primary-text-color);
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.38;

  @include mq(desktop) {
    font-size: rem(18px);
    line-height: 1.56;
  }
}

.testimonial-card__position {
  font-size: rem(14px);
  line-height: 1.57;
}

.testimonial-card__logo {
  block-size: rem(40px);
  inline-size: rem(40px);
  inset-block-end: rem(10px);
  inset-inline-end: rem(20px);
  object-fit: contain;
  position: absolute;

  @include mq(desktop) {
    block-size: rem(80px);
    inline-size: rem(80px);
    inset-inline-end: rem(40px);
  }
}
