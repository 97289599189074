//Typography

body {
  color: var(--secondary-text-color);
  font-family: var(--primary-font);
  font-size: rem(16px);
  line-height: 1.38;

  @include mq(desktop) {
    font-size: rem(18px);
    line-height: 1.78;
  }

  em,
  i,
  a {
    color: var(--highlight-text-color);
  }

  strong,
  b {
    font-weight: 500;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-text-color);
  font-weight: 500;
  margin-block-end: rem(10px);

  @include mq(tabletLandscape) {
    margin-block-end: rem(20px);
  }

  strong,
  b {
    font-weight: 700;
  }
}

h1 {
  font-size: rem(34px);
  line-height: 1.12;

  @include mq(desktop) {
    font-size: rem(50px);
    line-height: 1.08;
  }

  @include mq(wide) {
    font-size: rem(54px);
    line-height: 1.07;
  }
}

h2 {
  font-size: rem(32px);
  line-height: 1.13;

  @include mq(desktop) {
    font-size: rem(42px);
    line-height: 1.1;
  }

  @include mq(wide) {
    font-size: rem(45px);
    line-height: 1.09;
  }
}

h3 {
  font-size: rem(28px);
  line-height: 1.14;

  @include mq(desktop) {
    font-size: rem(35px);
    line-height: 1.11;
  }

  @include mq(wide) {
    font-size: rem(38px);
    line-height: 1.11;
  }
}

h4 {
  font-size: rem(24px);
  line-height: 1.17;

  @include mq(desktop) {
    font-size: rem(29px);
    line-height: 1.14;
  }

  @include mq(wide) {
    font-size: rem(32px);
    line-height: 1.13;
  }
}

h5 {
  font-size: rem(21px);
  line-height: 1.19;

  @include mq(desktop) {
    font-size: rem(24px);
    line-height: 1.17;
  }

  @include mq(wide) {
    font-size: rem(26px);
    line-height: 1.23;
  }
}

h6 {
  font-size: rem(18px);
  line-height: 1.33;

  @include mq(desktop) {
    font-size: rem(20px);
    line-height: 1.2;
  }

  @include mq(wide) {
    font-size: rem(24px);
    line-height: 1.42;
  }
}

small {
  font-size: rem(14px);
  line-height: 1.43;

  @include mq(desktop) {
    font-size: rem(15px);
    line-height: 1.4;
  }

  @include mq(wide) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}
