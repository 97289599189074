.load-more {
  margin-block-start: rem(40px);

  @include mq(desktop) {
    margin-block-start: rem(50px);
  }

  & .btn {
    inline-size: 100%;

    @include mq(desktop) {
      inline-size: auto;
    }
  }
}
