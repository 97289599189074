.your-appointments__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-start: 60px;

  h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.29;
    margin-block-end: 0;
  }
}

.your-appointments__grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(4, 1fr);
  margin-block-start: 75px;
}

.your-appointments__card {
  border: solid 1px #979797;
  border-radius: 16px 0 0;
  overflow: hidden;
}

.your-appointments__content {
  padding-block: 15px;
  padding-inline: 25px;
}

.check-in,
.check-in-status {
  display: none;
}

.your-appointments__cta-wrapper {
  align-items: center;
  display: flex;
  gap: 5px;
}

.your-appointments-card__heading {
  background-color: var(--azure);
  color: var(--white);
  font-size: 24px;
  margin-block: 0;
  padding-block: 25px;
  padding-inline: 20px;
}

.your-appointments__status {
  font-size: 10px;
  letter-spacing: -0.08px;
  line-height: 2.3;
  text-align: end;
}

.your-appointments__date-container {
  display: grid;
  gap: 3px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  overflow-x: auto;
}

.your-appointments__date-info {
  border: solid 1px #979797;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  padding-block: 10px;
  padding-inline: 10px;
  text-align: center;
}

.your-appointments__location {
  border-block-start: 1px solid #979797;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  margin-block-start: 20px;
  padding-block: 20px;
}

.your-appointments__ctas {
  display: flex;
  gap: 7px;
  justify-content: flex-end;
  margin-block-start: 40px;

  .btn--outline {
    padding: rem(10px 15px);
  }
}

.your-appointments__date-info--time {
  background-color: var(--neutral-green);
}

.btn--add-appointment {
  align-items: center;
  display: flex;

  svg {
    fill: var(--white);
    inline-size: 32px;
    margin-inline-start: 10px;
  }

  &:hover {
    svg {
      fill: var(--azure);
    }
  }
}

.btn--printer {
  align-items: center;
  align-self: stretch;
  display: flex;
  padding: 0 16px;

  svg {
    block-size: 20px;
    inline-size: 20px;
    stroke: var(--white);
  }

  &:hover {
    svg {
      stroke: var(--azure);
    }
  }
}

.your-appointments__card--no-appointment {
  .your-appointments-card__heading {
    background-color: #979797;
    color: var(--white);
  }

  .your-appointments__date-info {
    color: var(--white);
  }

  .your-appointments__date-info--time {
    background-color: var(--white);
  }

  .your-appointments__date-container {
    margin-block-start: 22px;
  }
}

.modal--appointment {
  .modal__inner {
    justify-content: center;
    max-inline-size: 590px;
    padding: 3.75rem;
    text-align: center;
  }

  h2 {
    font-size: rem(28px);
  }
}

.modal__appointment-svg {
  fill: var(--azure);
  inline-size: 59px;
  margin-block-end: 20px;
}

.modal__appointment-button {
  &.btn {
    inline-size: 287px;
    margin-block: 0;
  }
}

.your-appointments__cta-wrapper {
  display: flex;
  gap: 10px;

  .btn--record {
    block-size: 54px;
    inline-size: 54px;
  }
}
