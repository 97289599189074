.login-page {
  align-items: center;
  block-size: 100vh;
  display: flex;
  justify-content: center;
}

.login-separator {
  align-items: center;
  display: flex;
  padding: 0 4px;

  &::after,
  &::before {
    background-color: var(--alto);
    block-size: 1px;
    content: "";
    inline-size: 50%;
  }
}

.or-text {
  color: var(--tundora);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0 12px;
}

.btn--facebook {
  align-items: center;
  display: flex;
  gap: 10px;

  span {
    block-size: 38px;
    inline-size: 38px;
  }
}
