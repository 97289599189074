.content-with-media-row {
  margin-block: var(--module-spacing);
  position: relative;

  @include mq(desktop) {
    margin: 0;
    padding-block: var(--module-spacing);
  }

  @include mq(wide) {
    padding-block-start: rem(160px);
  }

  &[data-background="gray"] {
    @include mq($until: desktop) {
      padding-block: var(--module-spacing);
    }
  }

  &[data-background="cyan"] {
    background-color: var(--back-blue);
  }

  .two-column-content-media {
    @include mq($until: desktop) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .two-column-content-media__content {
    margin-block-start: rem(20px);

    @include mq(desktop) {
      padding-block: rem(35px);
    }
  }

  .two-column-content-media__media {
    @include mq($until: desktop) {
      margin-block-start: 0;
    }
  }
}

.content-with-media-row__header {
  @include mq($until: desktop) {
    align-items: center;
    display: flex;
    margin-block-end: rem(10px);

    h2 {
      margin: 0;
    }
  }
}

.content-with-media-row__buttons {
  inline-size: 100%;
  margin: rem(10px -20px -10px);

  @include mq(desktop) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-block-start: rem(10px);
  }

  .btn,
  .link {
    margin: rem(10px 20px);

    @include mq(desktop) {
      margin: rem(20px);
    }
  }
}

.content-with-media-row__top-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--arapawa);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: 0;
      inset-inline-start: rem(50px);

      .content-with-media-row--inverted & {
        block-size: rem(60px);
        inline-size: rem(60px);
        inset-inline-start: 0;
      }
    }

    @include mq(wide) {
      block-size: rem(160px);
      inline-size: rem(160px);
      inset-inline-start: rem(80px);

      .content-with-media-row--inverted & {
        block-size: rem(80px);
        inline-size: rem(80px);
      }
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(50px);
      inline-size: rem(50px);
      inset-block-start: 0;
      inset-inline-end: 0;
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);

      .content-with-media-row--inverted & {
        block-size: rem(60px);
        inline-size: rem(60px);
      }
    }
  }

  .shape-3 {
    @include mq(desktop) {
      background-color: var(--crusta);
      block-size: rem(50px);
      inline-size: rem(50px);
      inset-block-start: rem(80px);
      inset-inline-start: 0;

      .content-with-media-row--inverted & {
        display: none;
      }
    }

    @include mq(wide) {
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-start: rem(160px);
    }
  }
}

.content-with-media-row__bottom-shapes-container {
  .shape-1 {
    @include mq(desktop) {
      background-color: var(--azure);
      block-size: rem(50px);
      inline-size: rem(50px);
      inset-block-end: rem(80px);
      inset-inline-end: 0;

      .content-with-media-row--inverted & {
        background-color: var(--azure);
        block-size: rem(40px);
        inline-size: rem(40px);
        inset-block-end: rem(50px);
        inset-inline-end: rem(50px);
      }
    }

    @include mq(wide) {
      block-size: rem(60px);
      inline-size: rem(60px);
      inset-block-end: rem(100px);

      .content-with-media-row--inverted & {
        block-size: rem(60px);
        inline-size: rem(60px);
        inset-block-end: rem(80px);
        inset-inline-end: rem(80px);
      }
    }
  }

  .shape-2 {
    @include mq(desktop) {
      background-color: var(--cyan);
      block-size: rem(80px);
      inline-size: rem(80px);
      inset-block-end: 0;
      inset-inline-end: rem(50px);

      .content-with-media-row--inverted & {
        background-color: var(--arapawa);
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-inline-end: 0;
      }
    }

    @include mq(wide) {
      block-size: rem(100px);
      inline-size: rem(100px);
      inset-inline-end: rem(60px);

      .content-with-media-row--inverted & {
        block-size: rem(80px);
        inline-size: rem(80px);
      }
    }
  }

  .shape-3 {
    .content-with-media-row--inverted & {
      @include mq(desktop) {
        background-color: var(--cyan);
        block-size: rem(50px);
        inline-size: rem(50px);
        inset-block-end: 0;
        inset-inline-start: 0;
      }

      @include mq(wide) {
        block-size: rem(80px);
        inline-size: rem(80px);
      }
    }
  }
}
