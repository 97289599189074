.video-modal {
  background-color: rgba(var(--white-rgb), 0.8);
  block-size: 100vh;
  inline-size: 100vw;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding: rem(69px 20px);
  position: fixed;
  z-index: z(modal);

  @include mq(tablet) {
    padding: rem(89px);
  }
}

.video-modal__inner {
  inline-size: rem(1194px);
  margin: auto;
  max-inline-size: 100%;
}

.video-modal__inner-height {
  inline-size: 100%;
  margin: auto;
  max-inline-size: 135vh;
}

.video-modal__video-frame {
  background-color: var(--white);
  box-shadow: rem(0 3px 20px 0) var(--alto);
  padding: rem(30px);
  position: relative;

  @include mq(desktop) {
    padding: rem(60px);
  }
}

.video-modal__video-wrapper {
  block-size: 0;
  inline-size: 100%;
  padding-block-end: 56.25%;
  position: relative;

  @media (max-height: 600px) {
    padding-block-end: 48%;
  }

  iframe,
  video,
  .video-js {
    block-size: 100%;
    border: 0;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
  }
}

.video-modal__close {
  display: flex;
  inset-block-start: rem(10px);
  inset-inline-end: rem(10px);
  padding: 0;
  position: absolute;

  @include mq(desktop) {
    inset-block-start: rem(25px);
    inset-inline-end: rem(25px);
  }

  svg {
    block-size: rem(15px);
    fill: var(--arapawa);
    inline-size: rem(15px);
    stroke: var(--arapawa);

    @include mq(desktop) {
      block-size: rem(20px);
      inline-size: rem(20px);
    }
  }
}
